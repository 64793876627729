import React from "react";
import "./LoopFeedbackList.css"
import List from '@material-ui/core/List';
import LoopFeedbackCard from "./LoopFeedbackCard/LoopFeedbackCard";
import { ODP_STATUS_SETTINGS } from "../../utils/constant";

function ODPStatusList(props)  {
    const feedback = props.feedback || [];
    const criteria = props.criteria || [];
    const path = props.path || [];
    const expand = props.expand;

    return (
        <>
            <List className={`feedback-list`}>
                {
                    feedback.map(list=>{
                        return <LoopFeedbackCard feedback={feedback} criteria={criteria} path={path} expand={expand} cardDetails={list || null} key={Math.random()} handleOpen={props.handleOpen} />
                    })
                }
            </List>
        </>
    )

}

export default ODPStatusList;