import React, { useEffect, useState } from "react"; 
import { Analytics } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        textAlign: 'left',
        padding: theme.spacing(1),
        fontFamily: 'Arial',
      },
    formControl: {
        width: "475px",
    },
    required: {
        color: 'red',
    }
  }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const subAreaList = [
    'Address All Issues (Appropriate Resolution)',
    'Advantage Pro',
    'Audience Reach',
    'Auto Renewal',
    'Avail',
    'Broker Portal (RCC)',
    'Client Select Mortgage Advertisement',
    'Connection Plus',
    'Connections Plus and LCM',
    'Dashboard',
    'Digital Lending Markets',
    'Enrollment (RCC)',
    'iOS/Android App (Opcity App)',
    'Listing Intelligence',
    'LiveBuyer',
    'Local Expert',
    'Market Reach',
    'Market VIP and LCM',
    'MVIP',
    'Market VIP Portal',
    'New Home Display Ads',
    'New Home Facebook',
    'New Home Native Ads',
    'Pricing and Payment (RCC)',
    'Ready Connect Concierge',
    'Ready Connect Concierge (App)',
    'Referral Manager website (RCC)',
    'Sales Builder',
    'The Loop',
    'UpNest'
];

const browserList = [
    'Chrome',
    'Edge',
    'Firefox',
    'Native App',
    'Safari',
    'Web',
    'Other'
];

const operatingSystemList = [
    'Android',
    'Desktop - Mac',
    'Desktop - Other',
    'Desktop - Windows',
    'Mobile - Android',
    'Mobile - iOS',
    'iPhone',
    'iPad',
];

const roleList = [
    '--None--',
    'Home Buyer',
    'Settlement Provider',
    'Other',
    'Employee',
    'Home Builder',
    'Landlord/Owner',
    'Lender/Title Officer',
    'Real Estate Agent',
    'Real Estate Broker',
    'MLS/Association',
    'NAR',

];

function FeedbackEntryPage()  {

    const [roleFilter, setRoleFilter] = useState("");
    const [subAreaFilter, setSubAreaFilter] = useState("");
    const [browserFilter, setBrowserFilter] = useState("");
    const [osFilter, setOSFilter] = useState("");
    const [newComment, setNewComment] = useState("");
    const [userName, setUserName] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [currentURL, setCurrentURL] = useState("");
    const [jiraCaseNumber, setJiraCaseNumber] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [caseID, setCaseID] = useState("");
    const [partyID, setPartyID] = useState("");
    const [openBox, setOpenBox] = React.useState(false);
    const classes = useStyles();

    useEffect(() => {
        Analytics.record({ name: 'Feedback Entry Page visit' });
    }, []);

    const handleSave= (event) => {

        event.preventDefault();
        setDisabled(true);
        fetch("https://loopstore.entsys-dev.moveaws.com/sfcase",{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type':'application/json',
            },
            body: JSON.stringify({
                "Type": "Feedback",
                "Area__c": "Product",
                "Sub_Area__c": subAreaFilter,
                "RecordTypeId": "012j0000000PMBY",
                "OwnerID": "005j0000000d961AAA",
                "Status": "Closed",
                "Subject": "Product Feedback Submitted via the Loop",
                "Origin" : "Product Feedback",
                "Browser__c" : browserFilter,
                "JiraCaseNumber__c": jiraCaseNumber,
                "Operating_System__c": osFilter,
                "CurrentURL__c": currentURL,
                "SuppliedPhone": userPhone,
                "SuppliedEmail": userEmail,
                "SuppliedName": userName,
                "Role__c": roleFilter,
                "Description": newComment,
            }), 
        })
        .then(response => response.json())
        .then(
            (result) => {
                setDisabled(false)
                setOpenDialog(true)
                setCaseID(result.response ? result.response.case_id : "")
                console.log(result,"feedback entry created")
            },
            (error) => {
                console.log(error,"error")
            }
        )
        
    };

    const handleCloseDialog = (event) => {
        setOpenDialog(false);
    };

    const handleSearch = () => {
        if (partyID === "") {
            setOpenBox(true);
        }
        else {
            fetch("https://customer-identity-service.entsys-prod.moveaws.com/api/v2/accounts/party?party_id="+partyID+"&client_id=tst&metadata=false",{
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type':'application/json',
                },
            })
            .then(response => response.json())
            .then(
                (result) => {
                    console.log(result,"party id response")
                    if(result.data.message){
                        setOpenBox(true)
                    }
                    if(result.data.name){
                        setUserName(result.data.name)
                        {result.data.emails.map(email => 
                            { if(email.primary){
                            setUserEmail(email.email_address)
                            }}
                        )}
                        {result.data.phones.map(phonenumber => 
                            { if(phonenumber.primary){
                            setUserPhone(phonenumber.phone)
                            }}
                        )}
                    }
                },
            )
            .catch(
                (error) => {
                    console.log(error,"error")
                }
            )
        }
    };

    const handleReset = (event) => {
        setRoleFilter("");
        setSubAreaFilter("");
        setBrowserFilter("");
        setOSFilter("");
        setNewComment("");
        setUserName("");
        setUserPhone("");
        setUserEmail("");
        setCurrentURL("");
        setJiraCaseNumber("");
        setPartyID("");
    };

    const handleCloseBox = (event) => {
        setOpenBox(false);
    };

    return (
        <form onSubmit={handleSave}>

            <h2 className="page-title">Feedback Entry</h2>
            <div className={classes.root}>
                <Grid container spacing={2}>

                    <Grid item xs={6}>

                        <FormHelperText>Salesforce account party id</FormHelperText>

                        <FormControl className={classes.formControl}>
                            <TextField size="small" id="outlined-basic" placeholder="Salesforce account party id" variant="outlined" value={partyID} onChange={(event) => setPartyID(event.target.value)}/>
                        </FormControl>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Button variant="contained" color="primary" onClick={handleSearch}>Search</Button>

                    </Grid>

                    <Grid item xs={6}>

                        <FormHelperText>Role <span className={classes.required}>*</span></FormHelperText>

                        <FormControl fullWidth size="small">
                            <Select
                                variant="outlined"
                                value={roleFilter}
                                onChange={(event) => setRoleFilter(event.target.value)}
                                MenuProps={MenuProps}
                                required
                            >
                                {roleList.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item xs={6}>

                        <FormHelperText>Name <span className={classes.required}>*</span></FormHelperText>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField 
                                id="outlined-basic" 
                                placeholder="Name" 
                                variant="outlined" 
                                size="small"
                                value={userName}
                                onChange={(event) => setUserName(event.target.value)}
                                inputProps={{ maxLength: 50 }}
                                required
                                />
                        </FormControl>

                    </Grid>

                    <Grid item xs={6}>

                        <FormHelperText>Email</FormHelperText>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField 
                                id="outlined-basic" 
                                placeholder="Email" 
                                type="email"
                                variant="outlined" 
                                size="small"
                                value={userEmail}
                                onChange={(event) => setUserEmail(event.target.value)}
                                inputProps={{ maxLength: 50 }}
                            />
                        </FormControl>

                    </Grid>

                    <Grid item xs={6}>

                        <FormHelperText>Phone</FormHelperText>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField 
                                id="outlined-basic" 
                                placeholder="Phone" 
                                variant="outlined" 
                                size="small"
                                value={userPhone}
                                onChange={(event) => setUserPhone(event.target.value)}
                                inputProps={{ maxLength: 15 }}
                                />
                        </FormControl>

                    </Grid>

                    <Grid item xs={6}>

                        <FormHelperText>Associated URL</FormHelperText>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField 
                                id="outlined-basic" 
                                placeholder="Associated URL" 
                                variant="outlined" 
                                size="small"
                                value={currentURL}
                                onChange={(event) => setCurrentURL(event.target.value)}
                                />
                        </FormControl>

                    </Grid>

                    <Grid item xs={6}>

                        <FormHelperText>Operating System</FormHelperText>

                        <FormControl fullWidth size="small">
                            <Select
                                variant="outlined"
                                value={osFilter}
                                onChange={(event) => setOSFilter(event.target.value)}
                                MenuProps={MenuProps}
                            >
                            
                                {operatingSystemList.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item xs={6}>

                        <FormHelperText>Browser</FormHelperText>
                        
                        <FormControl fullWidth size="small">
                            <Select
                                variant="outlined"
                                value={browserFilter}
                                onChange={(event) => setBrowserFilter(event.target.value)}
                                MenuProps={MenuProps}
                            >
                            
                                {browserList.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                    </Grid>
          
                    <Grid item xs={6}>

                        <FormHelperText>Product <span className={classes.required}>*</span></FormHelperText>

                        <FormControl fullWidth size="small">
                            <Select
                                variant="outlined"
                                value={subAreaFilter}
                                onChange={(event) => setSubAreaFilter(event.target.value)}
                                size="small"
                                MenuProps={MenuProps}
                                required
                            >
                        
                                {subAreaList.map((option) => (
                                    <MenuItem  size="small" key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Grid>
  
                    <Grid item xs={6}>

                        <FormHelperText>JIRA #</FormHelperText>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField 
                                id="outlined-basic" 
                                placeholder="Jira#" 
                                variant="outlined" 
                                size="small"
                                value={jiraCaseNumber}
                                onChange={(event) => setJiraCaseNumber(event.target.value)}
                                />
                        </FormControl>

                    </Grid>

                    <Grid item xs={12}>

                        <FormHelperText>Feedback Comments <span className={classes.required}>*</span></FormHelperText>

                        <TextField
                            placeholder="Feedback Comments"
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined" 
                            value={newComment} 
                            onChange={(event) => setNewComment(event.target.value)}
                            required
                        />

                    </Grid>

                    <Grid item xs={12} align="right">

                        <Button disabled={disabled} variant="contained" color="primary" align="right" onClick={handleReset}>Reset</Button>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Button disabled={disabled} variant="contained" type="submit" color="primary" align="right">Save</Button>

                        <Dialog open={disabled} >
                            <CircularProgress/>
                        </Dialog>

                        <Dialog onClose={handleCloseDialog} open={openDialog}>
                            <DialogTitle>
                                {caseID ? "Feedback Submitted Successfully!" : "Could not submit feedback, Please enter valid email address"}
                            </DialogTitle>
                            <DialogContent>
                                {caseID ? "Feedback Case ID:" + caseID : ""}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} variant="contained" color="primary">
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog onClose={handleCloseBox} open={openBox}>
                            <DialogTitle>
                                {partyID ? "The party details could not be found for the given search field(s)" : "Please Enter Party ID"}
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={handleCloseBox} variant="contained" color="primary">
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </Grid>
                </Grid>
            </div>
        </form>
    )

}

export default FeedbackEntryPage;
