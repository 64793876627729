import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import * as queries from '../graphql/queries';
import LoopStoreList from "../components/LoopStoreList/LoopStoreList";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Pagination from '@material-ui/lab/Pagination';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from "moment-timezone";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays, addYears } from 'date-fns';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AuthContext, GApageView } from "../App";

const resultsPerPageList = [
    10,
    25,
    50,
    100,
    250
]

const sourceList = [
    'Google Play Store',
    'ios App Store'
]

const ratingList = [
    '1',
    '2',
    '3',
    '4',
    '5'
]

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      textAlign: 'left',
      padding: theme.spacing(1),
      fontFamily: 'Arial',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    searchbar: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '70%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button: {
        color: '#FFFFFF',
        backgroundColor: '#d92228',
        '&:hover': {
            backgroundColor: '#a51d32',
          },
        fontFamily: 'Arial',
        fontWeight: 'bold'
    }
  }));

const pageTitle = 'The Loop Store'

function TheLoopStorePage()  {
    const { state } = React.useContext(AuthContext);
    const [feedback, setFeedback] = useState([])
    const [label, setLabel] = useState("")
    const [hasResults, setHasResults] = useState(false)
    const [searched, setSearched] = useState(false)
    const [err, setErr] = useState(false)
    const [sourceFilter, setSourceFilter] = useState("");
    const [dateRangeFilter, setDateRangeFilter] = useState([
        {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [numPages, setNumPages] = useState(1);
    const [dateRange, setDateRange] = useState(false);
    const [ratingFilter, setRatingFilter] = useState("");
    const classes = useStyles();
    const showStartDate = moment(dateRangeFilter[0].startDate).format("MMM D, YYYY");
    const showEndDate = moment(dateRangeFilter[0].endDate).format("MMM D, YYYY");

    const getFeedbackFromSearch = async (e) => {
        
        try {
            setFeedback([]);

            var searchFilter = {}

            if (label !== "")  {
                searchFilter['or'] = [
                    { 'review': { match: label }},
                ]
            }
            
            if (sourceFilter !== "")   {
                searchFilter['source'] = { eq: sourceFilter }
            }

            if (ratingFilter !== "")   {
                searchFilter['rating'] = { match: ratingFilter }
            }

            const startDate = dateRangeFilter[0].startDate
            const endDate = addDays(dateRangeFilter[0].endDate, 1); // add a day then use less than to get full day.

            searchFilter['and'] = [
                { 'date': { gt: moment(startDate).format("YYYY-MM-DD")} },
                { 'date': { lt: moment(endDate).format("YYYY-MM-DD")} }
            ]

            // let sort = {
            //     "direction" : "desc",
            //     "field" : "date"
            // }

            const result = await API.graphql(graphqlOperation(queries.searchLoopStoreReviews, { filter: searchFilter, limit: limit, from: (page-1)*limit }));
            console.log(result,"review results");
            setNumPages(result.data.searchLoopStoreReviews.total ? Math.ceil(JSON.parse(result.data.searchLoopStoreReviews.total).value/limit) : "1")
            setHasResults(result.data.searchLoopStoreReviews.items.length > 0)
            setFeedback(result.data.searchLoopStoreReviews.items)
            setSearched(true)
            setErr(false);
            console.log('finished search')
        }
        catch(err) {
            console.log('Caught Error');
            console.log(err);
            setErr(err.errors[0].message);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter')  {
            getFeedbackFromSearch();
        }
    };

    const NoResults = () => {
        return !searched || label === ''
           ? ''
           : <h4 color='grey'>No matching review found for '{label}'</h4>
    }

    const handleResultsPerPageChange = (event) => {
        setLimit(event.target.value);
    };

    const handleSourceChange = (event) => {
        setSourceFilter(event.target.value);
    };

    const handleRatingChange = (event) => {
        setRatingFilter(event.target.value);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleResetClick = (event) => {
        setSourceFilter("");
        setRatingFilter("");
        setLabel("");
    };

    const handleExportClick = (event) => {
        console.log('export');
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const csvData = feedback;
        const fileName = 'feedback'; 
    
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const handleClickOpen = (event) => {
        setDateRange(true);
    };

    const handleClickClose = (event) => {
        setDateRange(false);
    };

    useEffect(() => {
        getFeedbackFromSearch();
    }, [ sourceFilter, page, limit, dateRangeFilter, ratingFilter ] );

    useEffect(() => {
        GApageView('Loop Store', window.location.protocol + "//" + window.location.host + "/theloop/store" , state.user.username)
        getFeedbackFromSearch();
        Analytics.record({
            name: 'The Loop Store Page visit',
            attributes: {
                username: state.user.username
            }
        });
    }, []);

    return (
        <>
            {
                err
                ?   <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        { err }
                    </Alert>
                :  null 
            }

            <h2 className="page-title">{pageTitle}</h2>

            <div className={classes.root}>

                <Grid container spacing={1}>

                    <Grid item xs={2}>

                        <FormControl className={classes.formControl}>

                            <InputLabel>Source</InputLabel>
                            <Select
                                native
                                displayEmpty
                                className={classes.selectEmpty}
                                value={sourceFilter}
                                onChange={handleSourceChange}
                            >
                                <option aria-label="None" value="" />
                                {sourceList.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Select>
                            
                        </FormControl>

                    </Grid>

                    <Grid item xs={2}>

                        <FormControl className={classes.formControl}>

                            <InputLabel>Rating</InputLabel>
                            <Select
                                native
                                displayEmpty
                                className={classes.selectEmpty}
                                value={ratingFilter}
                                onChange={handleRatingChange}
                            >
                                <option aria-label="None" value="" />
                                {ratingList.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Select>

                        </FormControl>

                    </Grid>

                    <Grid item xs={2}>

                        <FormControl className={classes.formControl}>

                            <InputLabel>Results Per Page</InputLabel>
                            <Select
                                native
                                value={limit}
                                onChange={handleResultsPerPageChange}
                            >
                                {resultsPerPageList.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Select>
                            
                        </FormControl>

                    </Grid>

                    <Grid item xs={2}>
                        <p>Start Date: {showStartDate}</p>
                        <p>End Date: {showEndDate}</p>
                    </Grid>

                    <Grid item xs={2}>

                        <Button className={classes.button} variant="contained" color="default" onClick={handleClickOpen}>Edit Date Range</Button>
                        <Dialog classes={{ paper: classes.paper}} onClose={handleClickClose} open={dateRange}>
                            <DialogContent>
                                <Paper>
                                    <DateRangePicker
                                        onChange={item => setDateRangeFilter([item.selection])}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        ranges={dateRangeFilter}
                                        direction="horizontal"
                                        minDate={addYears(new Date(), -2)}
                                        maxDate={new Date()}
                                    />
                                </Paper>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClickClose} color="primary">
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </Grid>

                    <Grid item xs={2}>
                        <Button className={classes.button} onClick={handleResetClick}>Reset</Button>
                        <Button onClick={handleExportClick}><GetAppIcon/></Button>
                    </Grid>

                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}> 
                        <Input className={classes.searchbar}
                            type='text'
                            placeholder='Search for review'
                            icon='search'
                            iconposition='left'
                            name='label'
                            value={label}
                            onChange={(e) => { setLabel(e.target.value); setSearched(false); } }
                            onKeyDown={handleKeyDown}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            }
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <div className={classes.results}>                         
                    {
                        hasResults
                        ? 
                            <>
                                <LoopStoreList feedback={feedback}/>
                                { numPages > 1 ? (
                                    <Pagination count={numPages} onChange={handlePageChange}/> 
                                ) : null
                                }
                            </>
                        : <NoResults />
                    }
                    </div>
                </Grid>

            </div>

        </>
    );

}

export default TheLoopStorePage;