/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "esdatahubhomeapi",
            "endpoint": "https://iuhcxmkp2a.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://bolxvmyxgvafniirsqueaa5ada.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "9f0df617372d4827b0b5ae4b13776791",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:a32ec5cb-0c9e-4a26-86df-8a83be1b8f40",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_sVPLLa8dd",
    "aws_user_pools_web_client_id": "492tgf1sko7dpil9f9hci9a31j",
    "oauth": {
        "domain": "es-datahub-dev.auth.us-west-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000/,https://brimaclap007.local:53591/,https://dev.dhuqro0fmnqji.amplifyapp.com/,https://dev.dj1cngpjvst7w.amplifyapp.com/,https://ladmins-macbook-pro-4.local:53591/,https://localdev.dhuqro0fmnqji.amplifyapp.com/,https://odp.entsys-dev.moveaws.com/",
        "redirectSignOut": "http://localhost:3000/,https://brimaclap007.local:53591/,https://dev.dhuqro0fmnqji.amplifyapp.com/,https://dev.dj1cngpjvst7w.amplifyapp.com/,https://ladmins-macbook-pro-4.local:53591/,https://localdev.dhuqro0fmnqji.amplifyapp.com/,https://odp.entsys-dev.moveaws.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
