import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        fontFamily: 'Arial',
    },
  }));

function GuestPage()  {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h1>Guest Page</h1>
            <p>
                Please click LOG IN in the upper right to access content.
            </p>
        </div>
    )

}

export default GuestPage;