import React, { useEffect, useState } from "react"; 
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';

const defaultConfig = {
    'WelcomeMessage': ""
}

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    paper: { 
       minWidth: "65%" 
    },
    form: {
        minWidth: '25%',
      }
  });

const status = [
    'Pass',
    'Fail'
]

function HomePage()  {

    const [config, setConfig] = useState(defaultConfig);
    const [initialized, setInitialized] = useState(0);
    const [pages, setPages] = useState([])
    const [open, setOpen] = React.useState(false);
    const [editMode, setEditMode] = useState({readOnly: false});
    const [editPage, setEditPage] = useState("");
    const [editObject, setEditObject] = useState("");
    const [editStatus, setEditStatus] = useState("");
    const [editDescription, setEditDescription] = useState("");
    const [editDetail, setEditDetail] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

    const classes = useStyles();

    useEffect(() => {
        Analytics.record({ name: 'Home Page visit' });
        getdata();
        API.graphql({ query: queries.getEsDatahubPageConfig, variables: { id: 'HOME'} })
        .then((data) => {
            setConfig(JSON.parse(data.data.getESDatahubPageConfig.config));
            setInitialized(1);
        })
        .catch((error) => {
            console.log(error);
            setInitialized(1);
        })

    }, []);

    function getdata() {
        API.graphql({ query: queries.listAnnouncementBoardEntrys })
        .then((data) => {
            setPages(data.data.listAnnouncementBoardEntrys.items);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    function truncate(str, n) {
        return (str.length > n) ? str.substr(0, n-1) + ' ...' : str;
    };

    const handleOpen = (page) => {
        setOpen(true);
        if (page.hasOwnProperty('id')) { 
            setEditPage(page.id);
            setEditObject(page.objectName);
            setEditStatus(page.status);
            setEditDescription(page.description);
            setEditDetail(page.detail);
            setEditMode({readOnly: true});
        } 
        else {
            setEditObject("");
            setEditStatus("");
            setEditDescription("");
            setEditDetail("");
            setEditMode({readOnly: false});
        }
    };

    const handleClose = async(opt) => {
        if (opt === 1) {
            if (editMode.readOnly === true)  {
                if (editObject === "") {
                    setErrorMessage("Please enter the Object Name");
                }
                else {
                    try {
                        await API.graphql(graphqlOperation(mutations.updateAnnouncementBoardEntry, { input: { id: editPage, objectName: editObject, status: editStatus, description: editDescription, detail: editDetail } }));
                        setErrorMessage("");
                        setOpen(false);
                        getdata();
                    } catch(err) {
                        setErrorMessage(err.errors[0].message)
                    }
                }
            }
            else {
                if (editObject === "") {
                    setErrorMessage("Please enter the Object Name");
                }
                else {
                    try {
                        await API.graphql(graphqlOperation(mutations.createAnnouncementBoardEntry, { input: { objectName: editObject, status: editStatus, description: editDescription, detail: editDetail } }));
                        setErrorMessage("");
                        setOpen(false);
                        getdata();
                    } catch(err) {
                        setErrorMessage(err.errors[0].message);
                    }
                }
            }
        }
        else {
            setOpen(false);
            setErrorMessage("");
        }
    };

    const handleDelete = (page) => {
        setOpenDeleteModal(true);
        if (page.hasOwnProperty('id')) { 
            setEditPage(page.id);
            setEditObject(page.objectName);
            setEditStatus(page.status);
            setEditDescription(page.description);
            setEditDetail(page.detail);
        } 
    };

    const handleConfirmDelete = async(opt) => {
        if (opt === 1) {
            try {
                await API.graphql(graphqlOperation(mutations.deleteAnnouncementBoardEntry, { input: { id: editPage } }));
                setOpenDeleteModal(false);
                getdata();
            } catch(err) {
                setErrorMessage(err.errors[0].message);
            }
        }
        else {
            setOpenDeleteModal(false);
            setErrorMessage("");
        }
    };

    const handleObjectChange = (event) => {
        setEditObject(event.target.value);
    };

    const handleStatusChange = (event) => {
        setEditStatus(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setEditDescription(event.target.value);
    };

    const handleDetailChange = (event) => {
        setEditDetail(event.target.value);
    };

    return (
        <>
            <h2 className="page-title">ODP</h2>

            {initialized === 0 ? <CircularProgress /> : 
        
                <div className="HomePage">
                    {config.WelcomeMessage ? <div dangerouslySetInnerHTML={{__html: config.WelcomeMessage}} /> : "Error ... can not retrieve WelcomeMessage.  Please contact dl-es-data-support@move.com."}

                    <h3>Announcement Board</h3>

                    <IconButton onClick={() => handleOpen({})}>
                        <AddIcon /> 
                    </IconButton>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Object</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Detail</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pages.map((page) => (
                                    <TableRow key={page.id}>
                                        <TableCell>
                                            {truncate((page.objectName), 25)}
                                        </TableCell>
                                        <TableCell style={{color: page.status === "Pass" ? 'green' : 'red'}}>{page.status}</TableCell>
                                        <TableCell>{truncate((page.description), 25)}</TableCell>
                                        <TableCell>{truncate((page.detail), 25)}</TableCell>
                                        <TableCell>          
                                            <IconButton onClick={() => handleOpen(page)}>
                                                <EditIcon /> 
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>          
                                            <IconButton onClick={() => handleDelete(page)}>
                                                <DeleteIcon /> 
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Dialog classes={{ paper: classes.paper}} open={open} onClose={handleClose}>
                        <DialogTitle>Edit Announcement</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{color: 'red'}}>
                                {errorMessage}
                            </DialogContentText>
                            <List>
                                <ListItem>
                                    <TextField
                                        id="Objects"
                                        label="Objects"
                                        type="text"
                                        value={editObject}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleObjectChange}
                                    />    
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        id="Status"
                                        select
                                        label="Status"
                                        value={editStatus}
                                        className={classes.form}
                                        onChange={handleStatusChange}
                                        >
                                        {status.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        id="Description"
                                        label="Description"
                                        multiline
                                        rows={2}
                                        value={editDescription}
                                        fullWidth
                                        inputProps={{ maxLength: 500 }}
                                        onChange={handleDescriptionChange}
                                    />
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        id="Detail"
                                        label="Detail"
                                        multiline
                                        rows={2}
                                        value={editDetail}
                                        fullWidth   
                                        inputProps={{ maxLength: 500 }}
                                        onChange={handleDetailChange}
                                    />
                                </ListItem>
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleClose(0)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => handleClose(1)} color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog classes={{ paper: classes.paper}} open={openDeleteModal} onClose={handleConfirmDelete}>
                        <DialogTitle>Are you sure you want to delete this Announcement?</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{color: 'red'}}>
                                {errorMessage}
                            </DialogContentText>
                                <List>
                                    <ListItem>Object Name: {editObject}</ListItem>
                                    <ListItem>Status: {editStatus}</ListItem>
                                    <ListItem>Description: {editDescription}</ListItem>
                                    <ListItem>Detail: {editDetail}</ListItem>
                                </List>        
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleConfirmDelete(0)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => handleConfirmDelete(1)} color="primary">
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                    
                </div>          
            
            }
        </>
    )

}

export default HomePage;