import React from "react";
import List from '@material-ui/core/List';
import LoopStoreCard from "./LoopStoreCard/LoopStoreCard";

function ODPStatusList(props)  {
    const feedback = props.feedback || [];

    return (
        <>
            <List className={`feedback-list`}>
                {
                    feedback.map(list=>{
                        return <LoopStoreCard feedback={feedback} cardDetails={list || null} key={Math.random()} handleOpen={props.handleOpen} />
                    })
                }
            </List>
        </>
    )

}

export default ODPStatusList;