import React, { useEffect } from 'react';
import { AuthContext } from "../App";
import Button from '@material-ui/core/Button';
import { NavLink } from "react-router-dom";

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import appconfig from "../app-exports";
import Logo from '../img/rdc-logo-tagline-stacked-white.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    fontFamily: 'Arial',
    fontWeight: 'bold'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    "background-color": "#FFFFFF"
  },
  color: {
    background: '#d92228',
  },
  button: {
    fontFamily: 'Arial',
    fontWeight: 'bold'
}
}));

function CheckPermission(userRoles, allowedGroups) {
  var common = []; // Initialize array to contain common items
  
  for (var i = 0; i < userRoles.length; i++) {
    for (var j = 0; j < allowedGroups.length; j++) {
      if (userRoles[i] === allowedGroups[j]) { // If item is present in both arrays
        common.push(userRoles[i]); // Push to common array
      }
    }
  }
  return common.length? true : false; // Return the common items
}

export const Header = ({children, navLinks}) => {

  const { state, dispatch } = React.useContext(AuthContext);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openSubmenu, setOpenSubmenu] = React.useState({status: false, type: null});
  const userRole = state && state.roles?state.roles:[];
  const matches = useMediaQuery("(min-width:1024px)");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (type, closeSubMenuBox=false) => {
    if(closeSubMenuBox){
      setOpenSubmenu({status: false, type: null});
    }else{
      setOpenSubmenu({status: type===openSubmenu.type?!openSubmenu.status:true, type: type});
    }
  };

  useEffect(() => {
    if(matches){
      setOpen(true);
    }
  }, [matches]);

  return (

    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        }, classes.color)}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <img src={Logo} alt="Logo" width="165" height="45"/>
          <Typography variant="h6" className={classes.title}>
             {appconfig.environment === 'prod' ? '': '***' + appconfig.environment + '*** '}Enterprise Systems - ODP
          </Typography>

          {state.isAuthenticated ? (  
            <Button 
              aria-label="log out"
              aria-controls="menu-appbar"
              color="inherit" 
              className={classes.button}
              onClick={() => dispatch({ type: "SIGNOUT" })}
            > 
              { state.attribs.given_name } | Log Out
            </Button>
          ) : (
            <Button 
              aria-label="log in"
              aria-controls="menu-appbar"
              color="inherit" 
              onClick={() => dispatch({ type: "SIGNIN" })}
              className={classes.button}
            >
              Log In
            </Button>
          )}

        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>

        <Divider />

        <List>
          {navLinks.map((link, index) => (
            link.name === 'divider' ? (  
              <Divider key={index}/>
            ) : (
              <span key={index}>
              {userRole.includes("Admin") || (!link.allowedGroups) || (link.allowedGroups && CheckPermission(userRole, link.allowedGroups))?(
                <span key={index}>
                {link.subMenu ? (
                <span key={index}>
                  <ListItem button component={NavLink} to={link.to} key={link.name} onClick={()=>handleClick(link.name)}>
                    <ListItemIcon><link.icon /></ListItemIcon>
                    <ListItemText primary={link.name} />
                    {openSubmenu.status && openSubmenu.type===link.name ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  {openSubmenu.type===link.name?(
                    <Collapse in={openSubmenu.status} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {link.subMenu.map(subMenu => (
                          userRole.includes("Admin") || (!subMenu.allowedGroups) || (subMenu.allowedGroups && CheckPermission(userRole, subMenu.allowedGroups))?
                            <ListItem button component={NavLink} to={subMenu.to} key={subMenu.name} className={classes.nested}>
                              <ListItemIcon><subMenu.icon /></ListItemIcon>
                              <ListItemText primary={subMenu.name} />
                            </ListItem>
                          :null
                        ))}
                      </List>
                    </Collapse>
                  ):null}
                </span>
              ):(
              <ListItem button component={NavLink} to={link.to} key={link.name} onClick={()=>handleClick(link.name, true)}>
                <ListItemIcon><link.icon /></ListItemIcon>
                <ListItemText primary={link.name} />
              </ListItem>
              )}
                </span>
              ):null}
              </span>
            )
          ))}
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className="app-container"> 
          {children}
        </div>
      </main>      

    </div>

  );
};

export default Header;