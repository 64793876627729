import React, { useState, useEffect } from "react";
import { API, Analytics } from 'aws-amplify';
import * as queries from '../graphql/queries';
// import appconfig from "../app-exports";
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { AuthContext, GApageView } from "../App";
import { ODP_STATUS_SETTINGS } from "../utils/constant";
import moment from "moment";

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

function TheLoopResearchAndInsightsPage()  {
   
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    const { state } = React.useContext(AuthContext);

    useEffect(() => {
        GApageView('User Experience', window.location.protocol + "//" + window.location.host + "/theloop/research", state.user.username)
        Analytics.record({
            name: 'The Loop - User Experience Page visit',
            attributes: {
                username: state.user.username
            }
        });
        API.graphql({ query: queries.listTheloopSurveys })
            .then((data) => {
                console.log(data.data);
                console.log(data.data.listTheloopSurveys);
                setSurveys(data.data.listTheloopSurveys.items);
                setLoading(false);
            })
            .catch(console.error);
        
    }, []);

    const classes = useStyles();

    return (
        <>
            <h2 className="page-title">Loop - User Experience</h2>    
            {/* <div><pre>{JSON.stringify(surveys, null, 2) }</pre></div>   */}
            
            {loading  ? <CircularProgress /> : 
                // <ul>
                //     {surveys.map(survey => <li key={survey.id}>{survey.surveyName}</li>)}
                // </ul>

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Survey</TableCell>
                                <TableCell align="right">Active?</TableCell>
                                <TableCell align="right">Created</TableCell>
                                <TableCell align="right">Last Modified</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {surveys.map((survey) => (
                                <TableRow key={survey.id}>
                                    <TableCell component="th" scope="row">
                                        {survey.surveyName}
                                    </TableCell>
                                    <TableCell align="right">{survey.isActive}</TableCell>
                                    <TableCell align="right">{moment(survey.creationDate).tz('America/Los_Angeles').format(ODP_STATUS_SETTINGS.DATE_FORMAT)}</TableCell>
                                    <TableCell align="right">{moment(survey.lastModified).tz('America/Los_Angeles').format(ODP_STATUS_SETTINGS.DATE_FORMAT)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }           
        </>
    );
}

export default TheLoopResearchAndInsightsPage;