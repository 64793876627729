import { DashboardPage } from '../components/DashboardPage'

const pageID = 'LOOPCUSTOMERSERVICE'
const pageTitle = 'The Loop Customer Service'

function TheLoopCustomerServicePage()  {

    return (
        DashboardPage(pageID, pageTitle)
    );

}

export default TheLoopCustomerServicePage;