/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by publish.py. It will be overwritten.
// Edit environment specific settings into app-exports-{env}.json.

const appconfig = {
    "environment": "dev",
    "site_url": "https://odp.entsys-dev.moveaws.com/",
    "auth_provider": "OKTA-DEV",
    "auth_region": "us-west-2",
    "auth_userPoolId": "us-west-2_sVPLLa8dd",
    "auth_userPoolWebClientId": "492tgf1sko7dpil9f9hci9a31j",
    "auth_domain": "es-datahub-dev.auth.us-west-2.amazoncognito.com",
    "awsAccount": "829427928185",
    "roleArn": "arn:aws:iam::829427928185:role/es-datahub-cognito-dev-IdentityPoolAuthRole-1CMLTGEE6NFTI",
    "identityPoolId": "us-west-2:a32ec5cb-0c9e-4a26-86df-8a83be1b8f40",
    "cognitoLogin": "cognito-idp.us-west-2.amazonaws.com/us-west-2_sVPLLa8dd",
    "dashboardRegion": "us-west-2",
    "quickSightAuthRegion": "us-east-1",
    "loop_websock": "wss://2yywm849o3.execute-api.us-west-2.amazonaws.com/Prod"
};

export default appconfig;
