/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEsDatahubPageConfig = /* GraphQL */ `
  query GetEsDatahubPageConfig($id: ID!) {
    getESDatahubPageConfig(id: $id) {
      id
      config
      createdAt
      updatedAt
    }
  }
`;
export const listEsDatahubPageConfigs = /* GraphQL */ `
  query ListEsDatahubPageConfigs(
    $filter: ModelESDatahubPageConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listESDatahubPageConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        config
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTheloopSurvey = /* GraphQL */ `
  query GetTheloopSurvey($id: ID!) {
    getTheloopSurvey(id: $id) {
      id
      surveyName
      isActive
      creationDate
      lastModified
      createdAt
      updatedAt
    }
  }
`;
export const listTheloopSurveys = /* GraphQL */ `
  query ListTheloopSurveys(
    $filter: ModelTheloopSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTheloopSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        surveyName
        isActive
        creationDate
        lastModified
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOdpAppMonitorEntry = /* GraphQL */ `
  query GetOdpAppMonitorEntry($id: ID!) {
    getOdpAppMonitorEntry(id: $id) {
      class
      id
      key
      status
      message
      messageType
      function
      createdAt
      updatedAt
    }
  }
`;
export const listOdpAppMonitorEntrys = /* GraphQL */ `
  query ListOdpAppMonitorEntrys(
    $filter: ModelodpAppMonitorEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOdpAppMonitorEntrys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        class
        id
        key
        status
        message
        messageType
        function
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOdpAppMonitorEntrySummary = /* GraphQL */ `
  query GetOdpAppMonitorEntrySummary($key: String!) {
    getOdpAppMonitorEntrySummary(key: $key) {
      key
      keyType
      updateFrequency
      frequencyArg
      tags
      active
      currentStatus
      lastMessage
      lastMessageType
      lastFunction
      lastStarted
      lastCompleted
      createdAt
      updatedAt
    }
  }
`;
export const listOdpAppMonitorEntrySummarys = /* GraphQL */ `
  query ListOdpAppMonitorEntrySummarys(
    $key: String
    $filter: ModelodpAppMonitorEntrySummaryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOdpAppMonitorEntrySummarys(
      key: $key
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        keyType
        updateFrequency
        frequencyArg
        tags
        active
        currentStatus
        lastMessage
        lastMessageType
        lastFunction
        lastStarted
        lastCompleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTheLoopFeedback = /* GraphQL */ `
  query GetTheLoopFeedback($recordid: String!) {
    getTheLoopFeedback(recordid: $recordid) {
      recordid
      record_source
      source_id
      case_number
      origin
      origin_description
      account_name
      subject
      description
      priority
      type
      area
      sub_area
      datetime_openened
      status
      owner_name
      browser
      operating_system
      current_url
      user_agent
      is_closed
      Sentiment
      OriginalSentiment
      SentimentScore
      created_date
      last_modified_date
      closed_date
      updatedAt
      createdAt
      account_type
      sub_account_type
      partyid
      jiracasenumber__c
      source
      created_date_ut
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          id
          owner
          username
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          recordid
          theLoopFeedback {
            recordid
            record_source
            source_id
            case_number
            origin
            origin_description
            account_name
            subject
            description
            priority
            type
            area
            sub_area
            datetime_openened
            status
            owner_name
            browser
            operating_system
            current_url
            user_agent
            is_closed
            Sentiment
            OriginalSentiment
            SentimentScore
            created_date
            last_modified_date
            closed_date
            updatedAt
            createdAt
            account_type
            sub_account_type
            partyid
            jiracasenumber__c
            source
            created_date_ut
            role
            case_comments
            research_notes
            star_rating
            label
          }
          unfollow
          createdAt
          updatedAt
        }
        nextToken
      }
      role
      case_comments
      research_notes
      star_rating
      label
    }
  }
`;
export const listTheLoopFeedbacks = /* GraphQL */ `
  query ListTheLoopFeedbacks(
    $recordid: String
    $filter: ModelTheLoopFeedbackFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTheLoopFeedbacks(
      recordid: $recordid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        recordid
        record_source
        source_id
        case_number
        origin
        origin_description
        account_name
        subject
        description
        priority
        type
        area
        sub_area
        datetime_openened
        status
        owner_name
        browser
        operating_system
        current_url
        user_agent
        is_closed
        Sentiment
        OriginalSentiment
        SentimentScore
        created_date
        last_modified_date
        closed_date
        updatedAt
        createdAt
        account_type
        sub_account_type
        partyid
        jiracasenumber__c
        source
        created_date_ut
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        followers {
          items {
            id
            owner
            username
            recordid
            unfollow
            createdAt
            updatedAt
          }
          nextToken
        }
        role
        case_comments
        research_notes
        star_rating
        label
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($username: String!) {
    getUser(username: $username) {
      username
      given_name
      last_name
      email
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $username: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTheLoopFeedbackComment = /* GraphQL */ `
  query GetTheLoopFeedbackComment($id: ID!) {
    getTheLoopFeedbackComment(id: $id) {
      id
      recordid
      content
      owner
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTheLoopFeedbackComments = /* GraphQL */ `
  query ListTheLoopFeedbackComments(
    $filter: ModelTheLoopFeedbackCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTheLoopFeedbackComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordid
        content
        owner
        user {
          username
          given_name
          last_name
          email
          comments {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserFollows = /* GraphQL */ `
  query GetUserFollows($id: ID!) {
    getUserFollows(id: $id) {
      id
      owner
      username
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      recordid
      theLoopFeedback {
        recordid
        record_source
        source_id
        case_number
        origin
        origin_description
        account_name
        subject
        description
        priority
        type
        area
        sub_area
        datetime_openened
        status
        owner_name
        browser
        operating_system
        current_url
        user_agent
        is_closed
        Sentiment
        OriginalSentiment
        SentimentScore
        created_date
        last_modified_date
        closed_date
        updatedAt
        createdAt
        account_type
        sub_account_type
        partyid
        jiracasenumber__c
        source
        created_date_ut
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        followers {
          items {
            id
            owner
            username
            recordid
            unfollow
            createdAt
            updatedAt
          }
          nextToken
        }
        role
      }
      unfollow
      createdAt
      updatedAt
    }
  }
`;
export const listUserFollowss = /* GraphQL */ `
  query ListUserFollowss(
    $filter: ModelUserFollowsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFollowss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        username
        user {
          username
          given_name
          last_name
          email
          comments {
            nextToken
          }
          createdAt
          updatedAt
        }
        recordid
        theLoopFeedback {
          recordid
          record_source
          source_id
          case_number
          origin
          origin_description
          account_name
          subject
          description
          priority
          type
          area
          sub_area
          datetime_openened
          status
          owner_name
          browser
          operating_system
          current_url
          user_agent
          is_closed
          Sentiment
          OriginalSentiment
          SentimentScore
          created_date
          last_modified_date
          closed_date
          updatedAt
          createdAt
          account_type
          sub_account_type
          partyid
          jiracasenumber__c
          source
          created_date_ut
          comments {
            nextToken
          }
          followers {
            nextToken
          }
          role
        }
        unfollow
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnnouncementBoardEntry = /* GraphQL */ `
  query GetAnnouncementBoardEntry($id: ID!) {
    getAnnouncementBoardEntry(id: $id) {
      id
      objectName
      status
      description
      detail
      createdAt
      updatedAt
    }
  }
`;
export const listAnnouncementBoardEntrys = /* GraphQL */ `
  query ListAnnouncementBoardEntrys(
    $filter: ModelAnnouncementBoardEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnouncementBoardEntrys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        objectName
        status
        description
        detail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSavedSearch = /* GraphQL */ `
  query GetSavedSearch($id: ID!) {
    getSavedSearch(id: $id) {
      id
      userName
      searchNameTag
      searchCriteria
      subscribe
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      description
      subject
      role
      origin
      case_number
      createdAt
      updatedAt
      star_rating
      label
    }
  }
`;
export const listSavedSearchs = /* GraphQL */ `
  query ListSavedSearchs(
    $filter: ModelSavedSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSavedSearchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        searchNameTag
        searchCriteria
        subscribe
        source
        account_type
        Sentiment
        area
        sub_area
        browser
        operating_system
        description
        subject
        role
        origin
        case_number
        createdAt
        updatedAt
        star_rating
        label
      }
      nextToken
    }
  }
`;
export const getUserSubscription = /* GraphQL */ `
  query GetUserSubscription($id: ID!) {
    getUserSubscription(id: $id) {
      id
      userName
      createdAt
      updatedAt
    }
  }
`;
export const listUserSubscriptions = /* GraphQL */ `
  query ListUserSubscriptions(
    $filter: ModelUserSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFilterValues = /* GraphQL */ `
  query GetFilterValues($id: ID!) {
    getFilterValues(id: $id) {
      id
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      role
      createdAt
      updatedAt
      star_rating
      label
    }
  }
`;
export const listFilterValuess = /* GraphQL */ `
  query ListFilterValuess(
    $filter: ModelFilterValuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilterValuess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        source
        account_type
        Sentiment
        area
        sub_area
        browser
        operating_system
        role
        star_rating
        label
      }
      nextToken
    }
  }
`;
export const getLoopStoreReview = /* GraphQL */ `
  query GetLoopStoreReview($id: ID!) {
    getLoopStoreReview(id: $id) {
      id
      author
      version
      review
      rating
      title
      comment
      reply
      date
      source
    }
  }
`;
export const listLoopStoreReviews = /* GraphQL */ `
  query ListLoopStoreReviews(
    $filter: ModelLoopStoreReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoopStoreReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        author
        version
        review
        rating
        title
        comment
        reply
        date
        source
      }
      nextToken
    }
  }
`;
export const searchOdpAppMonitorEntrys = /* GraphQL */ `
  query SearchOdpAppMonitorEntrys(
    $filter: SearchableodpAppMonitorEntryFilterInput
    $sort: SearchableodpAppMonitorEntrySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOdpAppMonitorEntrys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        class
        id
        key
        status
        message
        messageType
        function
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchTheLoopFeedbacks = /* GraphQL */ `
  query SearchTheLoopFeedbacks(
    $filter: SearchableTheLoopFeedbackFilterInput
    $sort: SearchableTheLoopFeedbackSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTheLoopFeedbacks(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        recordid
        record_source
        source_id
        case_number
        origin
        origin_description
        account_name
        subject
        description
        priority
        type
        area
        sub_area
        datetime_openened
        status
        owner_name
        browser
        operating_system
        current_url
        user_agent
        is_closed
        Sentiment
        OriginalSentiment
        SentimentScore
        created_date
        last_modified_date
        closed_date
        updatedAt
        createdAt
        account_type
        sub_account_type
        partyid
        jiracasenumber__c
        source
        created_date_ut
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        followers {
          items {
            id
            owner
            username
            recordid
            unfollow
            createdAt
            updatedAt
          }
          nextToken
        }
        role
        star_rating
        label
      }
      nextToken
      total
    }
  }
`;
export const searchLoopStoreReviews = /* GraphQL */ `
  query SearchLoopStoreReviews(
    $filter: SearchableLoopStoreReviewFilterInput
    $sort: SearchableLoopStoreReviewSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchLoopStoreReviews(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        author
        version
        review
        rating
        title
        comment
        reply
        date
        source
      }
      nextToken
      total
    }
  }
`;

export const searchReviews = /* GraphQL */ `
  query SearchLoopStoreReviews(
    $filter: SearchableReviewFilterInput
    $sort: SearchableReviewSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReviews(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        author
        version
        review
        rating
        title
        comment
        reply
        date
        source
      }
      nextToken
      total
    }
  }
`;
export const getArea = /* GraphQL */ `
  query GetArea($area_id: ID!) {
    getArea(area_id: $area_id) {
      id
      area_id
      area
      sub_area{
        items{
          id
          subarea_id
          area_id
          sub_area
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAreas = /* GraphQL */ `
  query ListAreas(
    $filter: ModelAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        area_id
        area
        sub_area{
          items{
            id
            subarea_id
            area_id
            sub_area
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getSubarea = /* GraphQL */ `
  query GetSubarea($subarea_id: ID!) {
    getSubarea(subarea_id: $subarea_id) {
      id
      subarea_id
      area_id
      sub_area
      createdAt
      updatedAt
    }
  }
`;
export const listSubareas = /* GraphQL */ `
  query ListSubareas(
    $filter: ModelSubareaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubareas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subarea_id
        area_id
        sub_area
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getMultipleCaseComments = /* GraphQL */ `
  query GetMultipleCaseComments($id: ID!) {
    getMultipleCaseComments(id: $id) {
      id
      case_comments
      createdAt
      updatedAt
    }
  }
`;
export const listMultipleCaseCommentss = /* GraphQL */ `
  query ListMultipleCaseCommentss(
    $filter: ModelMultipleCaseCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMultipleCaseCommentss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        case_comments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getOperatingSystem = /* GraphQL */ `
  query GetOperatingSystem($id: ID!) {
    getOperatingSystem(id: $id) {
      id
      os_id
      os
      createdAt
      updatedAt
    }
  }
`;
export const listOperatingSystemss = /* GraphQL */ `
  query ListOperatingSystems(
    $filter: ModelOperatingSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOperatingSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        os_id
        os
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getLoopVonageMetadata = /* GraphQL */ `
  query GetLoopVonageMetadata($id: ID!) {
    getLoopVonageMetadata(id: $id) {
      id
      start
      connect_to
      connect_from
      direction
      contentLength
    }
  }
`;
export const listLoopVonageMetadatas = /* GraphQL */ `
  query ListLoopVonageMetadatas(
    $filter: ModelLoopVonageMetadataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoopVonageMetadatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        start
        connect_to
        connect_from
        direction
        contentLength
      }
      nextToken
    }
  }
`;