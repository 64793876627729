export const SURVEY_SETTINGS = {
    LIST_START: 0,
    LIST_ITEMS: 25,
    GOOD: "good",
    BAD: "bad",
    NEUTRAL: "neutral",
    DATE_FORMAT: "Do MMM YYYY hh:mm:ss A"
}

export const ODP_STATUS_SETTINGS = {
    LIST_START: 0,
    LIST_ITEMS: 25,
    GOOD: "good",
    BAD: "bad",
    NEUTRAL: "neutral",
    DATE_FORMAT: "MM/DD/YY HH:mm:ss",
    DATE_FORMAT_TZ: "MM/DD/YY HH:mm:ss z"
}