import React, { useEffect, useState } from 'react'
import { API, Analytics } from 'aws-amplify';
// import { useTable, useExpanded } from 'react-table'
import { ExpandTable, TableStyle, SelectColumnFilter } from '../components/TableUtility'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
      fontFamily: 'Arial',
  },
}));

function ODPValidationPage()  {

    const classes = useStyles();
    const [valStatus, setValStatus] = useState([]);
    const [valAsOf, setValAsOf] = useState()
    const [valForDate, setValForDate] = useState()
    const [forDate, setForDate] = useState(new Date());
    const [selectedRow, setSelectedRow] = React.useState({})
    
    useEffect(() => {
        Analytics.record({ name: 'ODP Validation Page visit' });
        getData();
    }, []);

    async function getData(date = null) {
      var path = '/validation'
      if (date !== null) {
        path = path+`?ForDate=${date.toISOString().slice(0, 10)}`
      }
      console.log(path)
      const valStatus = await API.get('esdatahubhomeapi', path);
      console.log(valStatus.status);
      setValAsOf(valStatus.status.AsOf);
      setValForDate(valStatus.status.ForDate);
      setValStatus(valStatus.status.Results);
    }

    const columns = React.useMemo(
        () => [
          {
            // Make an expander cell
            Header: () => null, // No header
            id: 'expander', // It needs an ID
            Cell: ({ row }) => (
              // Use Cell to render an expander for each row.
              // We can use the getToggleRowExpandedProps prop-getter
              // to build the expander.
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? '👇' : '👉'}
              </span>
            ),
          },
          {
            Header: 'Job',
            columns: [
              {
                Header: 'JobRunId',
                accessor: 'JobRunId',
              },
              {
                Header: 'Project',
                accessor: 'Project',
              }, 
              {
                Header: 'Test',
                accessor: 'TestCase',
              }, 
              {
                Header: 'Table',
                accessor: 'TableName',
              }, 
              {
                Header: 'Status',
                accessor: 'Status',
                Filter: SelectColumnFilter,
                filter: 'includes',
              },            
              {
                Header: 'Count',
                accessor: 'Count',
              },
            ]
          },
        ],
        []
      )

      // Create a function that will render our row sub components
      const renderRowSubComponent = React.useCallback(
        ({ row }) => (
          <>
            <pre
              style={{
                fontSize: '10px',
              }}
            >
              <code>{JSON.stringify({ values: row.original }, null, 2)}</code>

              {/* <br />  
              Category: { row.values.category }<br />
              Description: { row.values.description }<br />

              <br />
              Comments: <br /><br />

              { row.original.comments.items.sort(function(a,b) {
                  if (a.createdAt < b.createdAt) {
                    return 1;
                  }
                  else if (a.createdAt > b.createdAt) {
                    return -1;
                  }
                  else if (a.id < b.id) {
                    return 1;
                  }
                  else {
                    return -1;
                  }
                }
                ).map(c => ( 
                  <span key={c.id}>{c.createdAt} { c.owner }: {c.message}<br /></span> 
                )) 
              } */}

            </pre>
          </>
        ),
        []
      )

    function updateData(date) {
      setValStatus([]);
      setForDate(date);
      getData(date);
    }
    
    return(
        <div className={classes.root}>
            <h3>Select Date:</h3>
            <DatePicker selected={forDate} onChange={date => updateData(date)} />
            <br /><br />
            <p>As Of: {valAsOf} </p>
            <p>For Date: {valForDate} </p>
            <TableStyle>
                <ExpandTable columns={columns} data={valStatus} renderRowSubComponent={renderRowSubComponent} />
            </TableStyle>

            <div>
            {valStatus.length > 0 ? 
              <p></p>
            : <p>Loading ...</p>
            }
            </div>



        </div>
    )
}

export default ODPValidationPage