import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded';
import ThumbsUpDownRoundedIcon from '@material-ui/icons/ThumbsUpDownRounded';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ODP_STATUS_SETTINGS } from "../utils/constant";
import { AuthContext, GApageView } from "../App";
import LoopFeedbackList from "../components/LoopFeedbackList/LoopFeedbackList";
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    form: {
        padding: theme.spacing(2),
    },
    formControl: {
        minWidth: '200px',
    }
  }));

  const localListUserFollowss = /* GraphQL */ `
  query ListUserFollowss(
    $filter: ModelUserFollowsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFollowss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        username
        user {
          username
          given_name
          last_name
          email
          comments {
            nextToken
          }
          createdAt
          updatedAt
        }
        recordid
        theLoopFeedback {
          recordid
          record_source
          source_id
          case_number
          origin
          origin_description
          account_name
          subject
          description
          priority
          type
          area
          sub_area
          datetime_openened
          status
          owner_name
          browser
          operating_system
          current_url
          user_agent
          is_closed
          Sentiment
          OriginalSentiment
          SentimentScore
          created_date
          last_modified_date
          closed_date
          updatedAt
          createdAt
          account_type
          sub_account_type
          partyid
          jiracasenumber__c
          source
          created_date_ut
          comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
          followers {
            nextToken
          }
        }
        unfollow
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


function TheLoopedInPage()  {

    const classes = useStyles();
    const { state } = React.useContext(AuthContext);
    const [initialized, setInitialized] = useState(0);
    const [feedbackList, setFeedbackList] = useState([]);
    const [hasResults, setHasResults] = useState(false)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [numPages, setNumPages] = useState(1);
    const path = 1;

    useEffect(() => {
        // subscribeToComments();
        getdata();
    }, [page, limit]);

    useEffect(() => {
      GApageView('Looped In', window.location.protocol + "//" + window.location.host + "/theloop/loopedin" , state.user.username)
      Analytics.record({
        name: 'The Looped In Page visit',
        attributes: {
            username: state.user.username
        }
    });
    }, []);

    // useEffect(() => {
    //     const subscription = API.graphql(
    //       graphqlOperation(subscriptions.onCreateTheLoopFeedbackComment)
    //     ).subscribe({
    //         next: data => {
    //             const { value: { data: { onCreateTheLoopFeedbackComment } }} = data
    //             const comments = [...commentList, onCreateTheLoopFeedbackComment]
    //             setCommentList(comments)
    //         }
    //     })
    
    //     return () => subscription.unsubscribe()
    // }, [commentList])

    function getdata() {
        API.graphql({query: localListUserFollowss, filter: { owner: { eq: state.user.username }}})
        .then((data) => {
            // console.log(data.data.listUserFollowss);
            setHasResults(data.data.listUserFollowss.items.length > 0)
            var feedbackList = [];
            data.data.listUserFollowss.items.forEach(item => feedbackList.push(item.theLoopFeedback));
            console.log(feedbackList);
            setFeedbackList(feedbackList);
            setInitialized(1);
        })
        .catch((error) => {
            console.log(error);
            setInitialized(1);
        })
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const NoResults = () => {
        return <h4 color='grey'>You are currently not looped in to anything!</h4>
    }

    return (
        <>
 
            {/* <h2 className="page-title">{record.subject}</h2> */}

            {initialized === 0 ? <CircularProgress /> : 

            <>
                <div className="HomePage">
      
                    {/* <List className={`comment-list`}>
                        {
                        feedbackList.map(feedback=>{
                            return <ListItem key={feedback.id}><ListItemText>{feedback.theLoopFeedback.subject}: {feedback.theLoopFeedback.description}</ListItemText></ListItem>
                        })
                        }
                    </List> */}

                        {
                        hasResults
                        ? 
                            <>
                                <LoopFeedbackList feedback={feedbackList} path={path}/>
                                { numPages > 1 ? (
                                    <Pagination count={numPages} onChange={handlePageChange}/> 
                                ) : null
                                }
                            </>
                        : <NoResults />
                        }


                </div>

            </>
            }
        </>
    )

}

export default TheLoopedInPage;