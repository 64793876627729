import React from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    paper: { 
       minWidth: "65%" 
    },
    text: {
        textAlign: 'center',
        fontFamily: 'Arial'
    },
    button: {
        color: '#FFFFFF',
        backgroundColor: '#d92228',
        '&:hover': {
            backgroundColor: '#a51d32',
          },
        fontFamily: 'Arial',
        fontWeight: 'bold'
    }
  });

function ProductHomePage()  {

    const classes = useStyles();

    return (
        <>
            <h2 className="page-title">Product Dashboard</h2>

            <div className="HomePage">

                <Grid container className={classes.text} justify="center" spacing={5}>
                    <Grid item xs={7}>
                        <h2>Welcome To The Product Dashboard</h2>
                    </Grid>
                </Grid>

            </div>
        </>
    )

}

export default ProductHomePage;