import { DashboardPage } from '../components/DashboardPage'

const pageID = 'PRODUCT'
const pageTitle = 'RDC Product Dashboard'

function ProductDashboardPage()  {

    return (
        DashboardPage(pageID, pageTitle)
    );

}


export default ProductDashboardPage;