import { DashboardPage } from '../components/DashboardPage'

const pageID = 'MARKETING'
const pageTitle = 'Marketing Dashboard'

function MarketingPage()  {
    
    return (
        DashboardPage(pageID, pageTitle)
    );

}


export default MarketingPage;