import React, { useState, useRef, useEffect } from "react";
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import appconfig from "../app-exports";
import ODPStatusList from "../components/ODPStatusList/ODPStatusList";
import ODPStatusTable from "../components/ODPStatusTable/ODPStatusTable";
import WifiTetheringRoundedIcon from '@material-ui/icons/WifiTetheringRounded';
import PortableWifiOffRoundedIcon from '@material-ui/icons/PortableWifiOffRounded';
import * as queries from '../graphql/queries';
import Clock from '../components/Clock';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
      fontFamily: 'Arial',
  },
}));

function ODPStatusLivePage()  {

    const classes = useStyles();
    const [activityList, setactivityList] = useState([]);
    const [currentStatus, setCurrentStatus] = useState([]);
    const [currentStatusSort, setCurrentStatusSort] = useState([]);
    const [connectionStatus, setconnectionStatus] = useState(false);
    const ws = useRef(null);

    function GetSortOrder(prop) {
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            } 
            return 0;
        }
    }

    const connect = () => {
        console.log('connect')
        ws.current = new WebSocket(appconfig.loop_websock);
        console.log('ws.current', ws.current);
        ws.current.onopen = function() {
            console.log("Socket is open.");
            setconnectionStatus(true);
        };
      
        ws.current.onmessage = function(e) {
            console.log('connect .. onmessage');
            const newItem = JSON.parse(e.data);
            if (newItem.data.hasOwnProperty('statuskey')) {
                setactivityList([newItem].concat(activityList));
                if (newItem.data['currentStatus'] === 'started' ||  newItem.data['currentStatus'] === 'completed') {
                    const updateItem = { ...currentStatus[newItem.data['statuskey']]};
                    if (newItem.data['currentStatus'] === 'started') {
                        updateItem['lastStarted'] = newItem.data['createdAt'];
                    } else {
                        updateItem['lastCompleted'] = newItem.data['createdAt'];
                    }
                    const newCurStat = { ...currentStatus, updateItem}
                    setCurrentStatusSort(newCurStat);
                }
            }
        };

        ws.current.onerror = function(err) {
            console.error('Socket encountered error: ', err.message, 'Closing socket');
            ws.current.close();
        };
    }

    function pad(n) {
        return n<10 ? '0'+n : n
    }

    function getdata() {

        console.log('get data ...')

        API.graphql({ query: queries.listOdpAppMonitorEntrySummarys })
        .then((data) => {
            console.log(data.data.listOdpAppMonitorEntrySummarys['items']);
            let dict = data.data.listOdpAppMonitorEntrySummarys['items'].reduce((a,x) => ({...a, [x.key]: {
                'active': x.active,
                'createdAt': x.createdAt,
                'currentStatus': x.currentStatus,
                'frequencyArg': x.frequencyArg,
                'keyType': x.keyType,
                'lastCompleted': x.lastCompleted,
                'lastFunction': x.lastFunction,
                'lastMessageType': x.lastMessageType,
                'lastMessage': x.lastMessage,
                'lastStarted': x.lastStarted,
                'tags': x.tags,
                'updateFrequency': x.updateFrequency,
                'updatedAt': x.updatedAt
            }}), {});

            var keys = Object.keys(dict);
            keys.sort();
            // console.log(keys);

            setCurrentStatus(dict);
            setCurrentStatusSort(keys);
        })
        .catch((error) => {
            console.log(error);
        });

        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const sYesterday = yesterday.getFullYear() + "-" + pad(yesterday.getMonth()+1) + "-" + pad(yesterday.getDate()) + "T00:00:00.000000Z"; 
        console.log(sYesterday);

        // let filter = {
        //     createdAt: {
        //         gt: sYesterday
        //     }
        // }
        // console.log(filter)
        // API.graphql({ 
        //     query: queries.listOdpAppMonitorEntrys,
        //     variables: { filter: filter },
        //     limit: 250
        // } )

        let searchFilter = {"createdAt": {"gte": sYesterday}};
        let sort = {
            "direction": "desc",
            "field": "createdAt"
        }
        API.graphql(graphqlOperation(queries.searchOdpAppMonitorEntrys, { 
            filter: searchFilter, 
            sort: sort 
        }))
        .then((data) => {
            // console.log(data.data.listOdpAppMonitorEntrys['items'].sort(GetSortOrder("createdAt")));
            // setactivityList(data.data.listOdpAppMonitorEntrys['items'].sort(GetSortOrder("createdAt")).reverse())
            setactivityList(data.data.searchOdpAppMonitorEntrys.items)
        })
        .catch((error) => {
            console.log('error!')
            console.log(error);
        })
    }

    useEffect(() => {
        console.log('useEffect #1');
        Analytics.record({ name: 'ODP Status Live Page visit' });
        connect();
        getdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log('useEffet #2')
        if(!ws.current) {
            return;
        }
        ws.current.onmessage = function(e) {
            try {
                console.log('useEffect .. onmessage');
                console.log('Message:', e.data);
                const newItem = JSON.parse(e.data);
                if (newItem.data.hasOwnProperty('statuskey')) {
                    let entry = {
                        'createdAt': newItem.data['timestamp'],
                        'function': newItem.data['function'],
                        'id': '',
                        'key': newItem.data['statuskey'],
                        'message': newItem.data['text'],
                        'messageType': newItem.data['message_type'],
                        'status': newItem.data['status'],
                        'updatedAt': newItem.data['timestamp']
                    }
                    setactivityList([entry].concat(activityList));

                    if (entry['status'] === 'starting' ||  entry['status'] === 'completed') {
                        let updateItem = currentStatus[entry['key']];
                        console.log(updateItem);
                        updateItem['currentStatus'] = entry['status'];
                        updateItem['lastMessage'] = entry['message'];
                        updateItem['lastMessageType'] = entry['messageType'];
                        if (entry['status'] === 'starting') {
                            updateItem['lastStarted'] = entry['createdAt'];
                        } else {
                            updateItem['lastCompleted'] = entry['createdAt'];
                        }
                        const newCurStat = { ...currentStatus }
                        newCurStat[entry['key']] = updateItem;
                        setCurrentStatus(newCurStat);
                    }
                }
            } catch(error) {
                console.error(error);
            }
        };
        ws.current.onclose = function(e) {
            console.log('useEffect .. onclose');
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            setconnectionStatus(false);
            setTimeout(function() {
                connect();
            }, 1000);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityList]);

    return (
        <div className={classes.root}>
            <h2 className="page-title">
                ODP Status
                {connectionStatus?
                    <WifiTetheringRoundedIcon className="socket-connected" />
                :
                    <PortableWifiOffRoundedIcon className="socket-disconnected" />
                }
            </h2>

            <Clock />
            <div className={connectionStatus?"connected":"not-connected"}>
                <div style={{
                    "paddingBottom": "50px"
                }}>
                    <h3>Status Table</h3>
                    <ODPStatusTable statusTable={currentStatus} sort={currentStatusSort} key={Math.random()} connectionStatus={connectionStatus?"connected":"not-connected"} />
                </div>
                <div>
                    <h3>Latest Activity</h3>
                    <ODPStatusList statusList={activityList} key={Math.random()} connectionStatus={connectionStatus?"connected":"not-connected"} />
                </div>
            </div>

        </div>
    );
}


export default ODPStatusLivePage;