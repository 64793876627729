import { DashboardPage } from '../components/DashboardPage'

const pageID = 'Sales'
const pageTitle = 'Sales 360 Dashboard'

function SalesDashboardPage()  {

    return (
        DashboardPage(pageID, pageTitle)
    );

}


export default SalesDashboardPage;