import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import LoopFeedbackList from "../components/LoopFeedbackList/LoopFeedbackList";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Pagination from '@material-ui/lab/Pagination';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import moment from "moment-timezone";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays, addYears } from 'date-fns';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AuthContext, GApageView } from "../App";
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import gtag from "ga-gtag";

const resultsPerPageList = [
    10,
    25,
    50,
    100,
    250
]

const sortList = [
    'asc',
    'desc'
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth:"auto"
    },
  },
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      textAlign: 'left',
      padding: theme.spacing(1),
      fontFamily: 'Arial',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    searchbar: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        width: "150px",
    },
    formControls: {
        margin: theme.spacing(1),
        width: '150px',
    },
    formWidth: {
        margin: theme.spacing(1),
        width: "70px",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button: {
        color: '#FFFFFF',
        backgroundColor: '#d92228',
        '&:hover': {
            backgroundColor: '#a51d32',
          },
        fontFamily: 'Arial',
        fontWeight: 'bold'
    },
    button2: {
        color: '#FFFFFF',
        backgroundColor: '#0062cc',
        '&:hover': {
            backgroundColor: '#003d80',
          },
        fontFamily: 'Arial',
        fontWeight: 'bold'
    },
  }));

// const pageID = 'LOOPSEARCH'
const pageTitle = 'The Loop Search'

function TheLoopSearchPage(props)  {
    // const savedFilters = props.location.state || [];
    const [savedFilters, setSavedFilters] = useState(props.location.state || [])
    const { state } = React.useContext(AuthContext);
    const [feedback, setFeedback] = useState([])
    const [label, setLabel] = useState('')
    const [hasResults, setHasResults] = useState(false)
    const [searched, setSearched] = useState(false)
    const [err, setErr] = useState(false)

    const [open, setOpen] = React.useState(false);
    const [dialogData, setDialogData] = React.useState({});
    const [errorMessage, setErrorMessage] = useState();
    const [sourceList, setSourceList] = useState("");
    const [newArea, setNewArea] = useState({});
    const [areaList, setAreaList] = useState([]);
    const [sentimentList, setSentimentList] = useState("");
    const [accountTypeList, setAccountTypeList] = useState("");
    const [roleList, setRoleList] = useState([]);
    const [subAreaList, setSubAreaList] = useState([]);
    const [browserList, setBrowserList] = useState([]);
    const [operatingSystemList, setOSList] = useState([]);
    const [ratingList, setRatingList] = useState([]);
    const [labelList, setLabelList] = useState([]);
    const [sourceFilter, setSourceFilter] = useState("");
    const [searchFilter, setSearchFilter] = useState("");
    const [searchCriteria, setSearchCriteria] = useState("");
    const [sendCriteria, setSendCriteria] = useState("");
    const [areaFilter, setAreaFilter] = useState([]);
    const [sentimentFilter, setSentimentFilter] = useState("");
    const [accountTypeFilter, setAccountTypeFilter] = useState("");
    const [roleFilter, setRoleFilter] = useState([]);
    const [subAreaFilter, setSubAreaFilter] = useState([]);
    const [browserFilter, setBrowserFilter] = useState([]);
    const [osFilter, setOSFilter] = useState([]);
    const [ratingFilter, setRatingFilter] = useState([]);
    const [labelFilter, setLabelFilter] = useState([]);
    const [searchName, setSearchName] = useState("");
    const [dateRangeFilter, setDateRangeFilter] = useState([
        {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [sortValue, setSortValue] = useState('desc');
    const [numPages, setNumPages] = useState(1);
    const [dateRange, setDateRange] = useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [disabled, setDisabled] = useState(false)
    const [subModal, setSubModal] = useState(false)
    const [exportFilter, setExportFilter] = useState("");
    const [openExportDialog, setOpenExportDialog] = React.useState(false);
    const [expand, setExpand] = useState(false);
    const [buttonText, setButtonText] = useState("Expand All");
    const classes = useStyles();

    const showStartDate = moment(dateRangeFilter[0].startDate).format("MMM D, YYYY");
    const showEndDate = moment(dateRangeFilter[0].endDate).format("MMM D, YYYY");

    const path = 0;

    function capitalize(s) {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    }

    const getFeedbackFromSearch = async (e) => {
        
        try {
            setFeedback([]);

            var searchFilter = {}
            var searchCriteria = {}
            let sendCriteria = {}
            let roleArray = [];
            let areaArray = [];
            let subareaArray = [];
            let browserArray = [];
            let osArray = [];
            let ratingArray = [];
            let labelArray = [];
            const startDate = dateRangeFilter[0].startDate
            const endDate = addDays(dateRangeFilter[0].endDate, 1); // add a day then use less than to get full day.
            
            searchFilter['and'] = [
                { 'created_date_ut': { gte: startDate.getTime() / 1000 } },
                { 'created_date_ut': { lt: endDate.getTime() / 1000 } },
            ]
            searchCriteria['and'] = [];

            if (label !== "")  {
                if (/"/.test(label)) {
                    searchFilter['or'] = [
                        { 'description': { matchPhrasePrefix: label }},
                        { 'subject': { matchPhrasePrefix: label }},
                        { 'case_number': { matchPhrasePrefix: label }}
                    ]
                    searchCriteria['or'] = [
                        { 'description': { matchPhrasePrefix: label }},
                        { 'subject': { matchPhrasePrefix: label }},
                        { 'case_number': { matchPhrasePrefix: label }}
                    ]
                    sendCriteria['description'] = label
                    console.log("with quote")
                }
                else {
                    searchFilter['or'] = [
                        { 'description': { match: label }},
                        { 'subject': { match: label }},
                        { 'case_number': { match: label }}
                    ]
                    searchCriteria['or'] = [
                        { 'description': { match: label }},
                        { 'subject': { match: label }},
                        { 'case_number': { match: label }}
                    ]
                    sendCriteria['description'] = label
                    console.log("without quote")
                }
            }
            
            if (sourceFilter !== "")   {
                searchFilter['origin'] = { match: sourceFilter }
                searchCriteria['origin'] = { match: sourceFilter }
                sendCriteria['origin'] = sourceFilter
            }

            if (accountTypeFilter !== "")   {
                searchFilter['account_type'] = { eq: accountTypeFilter }
                searchCriteria['account_type'] = { eq: accountTypeFilter }
                sendCriteria['account_type'] = accountTypeFilter
            }

            if (roleFilter.length !== 0)   {
                for (var i in roleFilter) {
                    roleArray.push({ 'role': { eq: roleFilter[i] } })
                }
                searchFilter['and'].push({'or':roleArray})
                searchCriteria['and'].push({'or':roleArray})
                sendCriteria['role'] = roleFilter
            }

            if (sentimentFilter !== "")   {
                searchFilter['Sentiment'] = { eq: sentimentFilter }
                searchCriteria['Sentiment'] = { eq: sentimentFilter }
                sendCriteria['Sentiment'] = sentimentFilter
            }

            if (areaFilter.length !== 0)   {
                for (var i in areaFilter) {
                    areaArray.push({ 'area': { eq: areaFilter[i] } })
                }
                searchFilter['and'].push({'or':areaArray})
                searchCriteria['and'].push({'or':areaArray})
                sendCriteria['area'] = areaFilter
            }

            if (subAreaFilter.length !== 0)   {
                for (var i in subAreaFilter) {
                    subareaArray.push({ 'sub_area': { eq: subAreaFilter[i] } })
                }
                searchFilter['and'].push({'or':subareaArray})
                searchCriteria['and'].push({'or':subareaArray})
                sendCriteria['sub_area'] = subAreaFilter
            }

            if (browserFilter.length !== 0)   {
                for (var i in browserFilter) {
                    browserArray.push({ 'browser': { eq: browserFilter[i] } })
                }
                searchFilter['and'].push({'or':browserArray})
                searchCriteria['and'].push({'or':browserArray})
                sendCriteria['browser'] = browserFilter
            }

            if (osFilter.length !== 0)   {
                for (var i in osFilter) {
                    osArray.push({ 'operating_system': { eq: osFilter[i] } })
                }
                searchFilter['and'].push({'or':osArray})
                searchCriteria['and'].push({'or':osArray})
                sendCriteria['operating_system'] = osFilter
            }

            if (ratingFilter.length !== 0)   {
                for (var i in ratingFilter) {
                    ratingArray.push({ 'star_rating': { eq: ratingFilter[i] } })
                }
                searchFilter['and'].push({'or':ratingArray})
                searchCriteria['and'].push({'or':ratingArray})
            }

            if (labelFilter.length !== 0)   {
                for (var i in labelFilter) {
                    labelArray.push({ 'label': { eq: labelFilter[i] } })
                }
                searchFilter['and'].push({'or':labelArray})
                searchCriteria['and'].push({'or':labelArray})
                sendCriteria['label'] = labelFilter
            }

            console.log(searchFilter,"searchFilter")

            let sort = {
                "direction": sortValue,
                "field": "created_date_ut"
            }

            const result = await API.graphql(graphqlOperation(queries.searchTheLoopFeedbacks, { filter: searchFilter, sort: sort, limit: limit, from: (page-1)*limit }));

            console.log(result,"results new");
            // console.log('Returned', result.data.searchTheLoopFeedbacks.items.length, 'of Total # Records', result.data.searchTheLoopFeedbacks.total)

            setNumPages(Math.ceil(result.data.searchTheLoopFeedbacks.total/limit))
            setHasResults(result.data.searchTheLoopFeedbacks.items.length > 0)
            setFeedback(result.data.searchTheLoopFeedbacks.items)
            setSearchFilter(JSON.stringify(searchFilter))
            setSearchCriteria(JSON.stringify(searchCriteria))
            setSendCriteria(JSON.stringify(sendCriteria))
            setSearched(true)
            setErr(false);
            setSavedFilters([])
            setExportFilter(searchFilter)
            // console.log('finished search')
        }
        catch(err) {
            console.log('Caught Error');
            console.log(err);
            setErr(err.errors[0].message);
        }
    }

    // const handleClick = (event) => {
    //     event.preventDefault();
    
    //     // let the app manage the persistence & state
    //     getFeedbackFromSearch();
    //   };

    const handleKeyDown = (event) => {
    
        if (event.key === 'Enter')  {
            getFeedbackFromSearch();
        }

    };

    const NoResults = () => {
        return !searched || label === ''
           ? <h4>No matching feedback found for selected filters</h4>
           : <h4>No matching feedback found for '{label}'</h4>
    }

    const handleClose = async(opt) => {
        setOpen(false);
    };

    const handleDialogOpen = (card) => {
        console.log('Open');
        console.log(card);
        setOpen(true);
        if (card.hasOwnProperty('recordid')) { 
            setDialogData(card);
        } 
        else {
            setDialogData({});
        }
    };

    const handleResultsPerPageChange = (event) => {
        setLimit(event.target.value);
    };

    const handleSourceChange = (event) => {
        setSourceFilter(event.target.value);
    };

    const handleAreaChange = (event) => {
        const value = event.target.value;
        setAreaFilter(value)
        var newarray = [];
        for (const data of value) {
            newarray.push(newArea[data])
        }
        setSubAreaList(newarray.flat())
        setSubAreaFilter([])
    };

    const handleAccountTypeChange = (event) => {
        setAccountTypeFilter(event.target.value);
    };

    const handleLabelChange = (event) => {
        const value = event.target.value;
        console.log(value,"label")
        if (value[value.length - 1] === "all") {
            setLabelFilter(labelFilter.length === labelList.length ? [] : labelList);
            return;
          }
        setLabelFilter(value);
    };

    const handleRoleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setRoleFilter(roleFilter.length === roleList.length ? [] : roleList);
            return;
          }
        setRoleFilter(value);
    };

    const handleSentimentChange = (event) => {
        setSentimentFilter(event.target.value);
    };

    const handleSubAreaChange = (event) => {
        const value = event.target.value;
        setSubAreaFilter(value);
    };

    const handleBrowserChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setBrowserFilter(browserFilter.length === browserList.length ? [] : browserList);
            return;
          }
        setBrowserFilter(value);
    };

    const handleOSChange = (event) => {
        const value = event.target.value;
        // console.log(value,"os")
        if (value[value.length - 1] === "all") {
            setOSFilter(osFilter.length === operatingSystemList.length ? [] : operatingSystemList);
            return;
          }
        setOSFilter(value);
    };

    const handleRatingChange = (event) => {
        const value = event.target.value;
        console.log(value,"rating")
        if (value[value.length - 1] === "all") {
            setRatingFilter(ratingFilter.length === ratingList.length ? [] : ratingList);
            return;
          }
        setRatingFilter(value);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    function handleExpand() {
        expand ? setExpand(false) : setExpand(true)
        expand ? setButtonText("Expand More") : setButtonText("Expand Less")
    }

    const handleResetClick = (event) => {
        setSourceFilter("");
        setAreaFilter([]);
        setSentimentFilter("");
        setAccountTypeFilter("");
        setSubAreaList([]);
        setSubAreaFilter([]);
        setBrowserFilter([]);
        setOSFilter([]);
        setRoleFilter([]);
        setRatingFilter([]);
        setLabelFilter([]);
        setSavedFilters([]);
        setLabel("");
    };

    const handleObjectChange = (event) => {
        setSearchName(event.target.value);
    };

    const handleSortResults = (event) => {
        setSortValue(event.target.value);
    };

    const handleExportClick = async (event) => {
        setDisabled(true);
        let finalResult = []
        let result = []
        let sort = {
            "direction": sortValue,
            "field": "created_date_ut"
        }
        let results = await API.graphql(graphqlOperation(queries.searchTheLoopFeedbacks, { filter: exportFilter, sort: sort, limit:1000 }));
        
        if(results.data.searchTheLoopFeedbacks.total!=results.data.searchTheLoopFeedbacks.items.length){
    
            for (let i=0;i<results.data.searchTheLoopFeedbacks.total;i=i+result.data.searchTheLoopFeedbacks.items.length){
                result = await API.graphql(graphqlOperation(queries.searchTheLoopFeedbacks, { filter: exportFilter, sort: sort, from:i, limit:1000}));
                console.log('Starting from ', i ,'Returned', result.data.searchTheLoopFeedbacks.items.length, 'records out of Total ', result.data.searchTheLoopFeedbacks.total, 'records')
                console.log(result.data.searchTheLoopFeedbacks.items, "results")
                finalResult.push(result.data.searchTheLoopFeedbacks.items)
            }

        }
        else{
            console.log(results.data.searchTheLoopFeedbacks.items.length, results.data.searchTheLoopFeedbacks.total,"results are less than thousand #")
            finalResult.push(results.data.searchTheLoopFeedbacks.items)
        }

        console.log(finalResult.flat(),'export');
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const csvData = finalResult.flat();
        const fileName = 'feedback '+moment(dateRangeFilter[0].startDate).format("YYYY-MM-DD")+' to '+moment(dateRangeFilter[0].endDate).format("YYYY-MM-DD"); 
    
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
        setDisabled(false);
        setOpenExportDialog(true)
        gtag('event', 'report_download', {
            user_name: state.user.username
        });
    };

    const handleClickOpen = (event) => {
        setDateRange(true);
    };

    const handleClickClose = (event) => {
        setDateRange(false);
    };

    const handleOpenDialog = (event) => {
        setOpenDialog(true);
    };

    const handleCloseExportDialog = (event) => {
        setOpenExportDialog(false);
    };

    const handleCloseDialog = async(opt) => {
        if (opt === 1) {
            if (searchName === "") {
                setErrorMessage("Please enter the Search Name");
            }
            else {
                setSubModal(true);
                try {
                    await API.graphql(graphqlOperation(mutations.createSavedSearch, { input: { userName: state.user.attributes.email, searchNameTag: searchName, searchCriteria: searchCriteria, subscribe: true, source: sourceFilter, account_type: accountTypeFilter, Sentiment: sentimentFilter, area: areaFilter.toString(), sub_area: subAreaFilter.toString(), browser: browserFilter.toString(), operating_system: osFilter.toString(), role: roleFilter.toString(), description: label, subject: label, case_number: label, origin: sourceFilter, star_rating: ratingFilter.toString(), label: labelFilter.toString() }}))
                    .then((data) => {
                        setSubModal(false);
                        var searchUser = {}
                        searchUser['userName'] = { eq: state.user.attributes.email }
                        API.graphql(graphqlOperation(queries.listUserSubscriptions, { filter: searchUser }))
                        .then((data) => {
                            if(data.data.listUserSubscriptions.items.length===0){
                                API.graphql(graphqlOperation(mutations.createUserSubscription, { input: { userName: state.user.attributes.email }}))
                                .then((data) => {
                                    console.log(data,"added user");
                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    setErrorMessage("");
                    setOpenDialog(false);
                } catch(err) {
                    setErrorMessage(err);
                }
            }
        }
        else {
            setOpenDialog(false);
        }
    };

    useEffect(() => {
        if (savedFilters.length!==0) {
            try {
                console.log(savedFilters,"filtervalues")
                setSourceFilter(savedFilters.savedSource)
                setAccountTypeFilter(savedFilters.savedAccountType)
                setSentimentFilter(savedFilters.savedSentiment)
                if (savedFilters.savedArea !== "" && savedFilters.savedArea !== null) {
                    setAreaFilter(savedFilters.savedArea.split(','))
                }
                if (savedFilters.savedSubArea !== "" && savedFilters.savedSubArea !== null) {
                    setSubAreaFilter(savedFilters.savedSubArea.split(','))
                }
                if (savedFilters.savedBrowser !== "" && savedFilters.savedBrowser !== null) {
                    setBrowserFilter(savedFilters.savedBrowser.split(','))
                }
                if (savedFilters.savedOperatingSystem !== "" && savedFilters.savedOperatingSystem !== null) {
                    setOSFilter(savedFilters.savedOperatingSystem.split(','))
                }
                if (savedFilters.savedRole !== "" && savedFilters.savedRole !== null) {
                    setRoleFilter(savedFilters.savedRole.split(','))
                }
                if (savedFilters.savedLabel !== "" && savedFilters.savedLabel !== null) {
                    setLabelFilter(savedFilters.savedLabel.split(','))
                }
                setLabel(savedFilters.savedDescription)
                setSavedFilters([], () => {
                    getFeedbackFromSearch();
                 });

            } catch(err) {
                console.log(err);
            }
        }
        else {
            getFeedbackFromSearch();
        }
    }, [ sourceFilter, areaFilter, accountTypeFilter, dateRangeFilter, sentimentFilter, page, limit, subAreaFilter, browserFilter, osFilter, roleFilter, label, sortValue, ratingFilter, labelFilter ] );

    useEffect(() => {
    GApageView('Loop Search', window.location.protocol + "//" + window.location.host + "/theloop/search", state.user.username)
        Analytics.record({
            name: 'The Loop Search Page visit',
            attributes: {
                username: state.user.username
            }
        });
        
        API.graphql(graphqlOperation(queries.listFilterValuess))
        .then((data) => {
            // const areaData = JSON.parse(data.data.listFilterValuess.items[0].area);
            // const areaArray = [];
            // for (var key in areaData) {
            //     areaArray.push(key)
            // }
            try {
                setSourceList(data.data.listFilterValuess.items[0].source)
                setAccountTypeList(data.data.listFilterValuess.items[0].account_type)
                setRoleList(data.data.listFilterValuess.items[0].role)
                setSentimentList(data.data.listFilterValuess.items[0].Sentiment)
                // setAreaList(areaArray)
                setBrowserList(data.data.listFilterValuess.items[0].browser)
                setOSList(data.data.listFilterValuess.items[0].operating_system)   
                // setNewArea(areaData) 
                setRatingList(data.data.listFilterValuess.items[0].star_rating)
                setLabelList(data.data.listFilterValuess.items[0].label)
            } catch(err) {
                console.log(err);
            }
        })
        .catch((error) => {
            console.log(error);
        })

        API.graphql(graphqlOperation(queries.listAreas))
        .then((data) => {
            const areaData = data.data.listAreas.items;
            const areaArray = [];
            const areaSubArray = {};
            for (var i in areaData) {
                areaArray.push(areaData[i].area)
                areaSubArray[areaData[i].area]=[];
                for (var j in areaData[i].sub_area.items) {
                    areaSubArray[areaData[i].area].push(areaData[i].sub_area.items[j].sub_area)
                }
            }
            try {
                setAreaList(areaArray) 
                setNewArea(areaSubArray)
                
            } catch(err) {
                console.log(err);
            }
        })
        .catch((error) => {
            console.log(error);
        })
        }, []);

    useEffect(() => {
        setRatingFilter([]);
    },[sourceFilter]);

    return (
        <>
            {
                err
                ?   <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        { err }
                    </Alert>
                :  null 
            }

            <h2 className="page-title">{pageTitle}</h2>

            <Dialog classes={{ paper: classes.paper}} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{dialogData.subject}</DialogTitle>
                <DialogContent>
                    <Typography>
                        <b>Area:</b> {dialogData.area}
                    </Typography>
                    <Typography>
                        <b>Sub-Area:</b> {dialogData.sub_area}
                    </Typography>
                    <Typography gutterBottom>
                        <b>Status:</b> {dialogData.status}
                    </Typography>
                    <DialogContentText>
                        {dialogData.description}
                    </DialogContentText>
                    <DialogContentText style={{color: 'red'}}>
                        {errorMessage}
                    </DialogContentText>
                    {/* <TextField
                        defaultValue={editPage}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Page"
                        type="text"
                        fullWidth
                        onChange={handlePageChange}
                        InputProps={editMode}
                    />
                    <TextField
                        id="filled-multiline-static"
                        label="Configuration"
                        multiline
                        rows={8}
                        defaultValue={editConfig}
                        variant="filled"
                        fullWidth
                        onChange={handleConfigChange}
                    /> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(0)} color="primary">
                        Close
                    </Button>
                    {/* <Button onClick={() => handleClose(1)} color="primary">
                        Submit
                    </Button> */}
                </DialogActions>
            </Dialog>

            <div className={classes.root}>

                <Grid container spacing={1}>

                    { sourceList ?
                        <Grid item xs={2}>

                            <FormControl className={classes.formControl}>

                                <InputLabel>Source</InputLabel>
                                <Select
                                    native
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    value={sourceFilter}
                                    onChange={handleSourceChange}
                                >
                                    <option aria-label="None" value="" />
                                    {sourceList.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Select>
                                
                            </FormControl>
                        </Grid>
                    : "" }

                    { ratingList && (sourceFilter === "Google Playstore" || sourceFilter === "ios Appstore" ) ?
                        <Grid item xs={2}>

                            <FormControl className={classes.formControl}>
                                <InputLabel>Rating</InputLabel>
                                <Select
                                    multiple
                                    value={ratingFilter}
                                    onChange={handleRatingChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem
                                        value="all"
                                        classes={{
                                            root: ratingList.length > 0 && ratingFilter.length === ratingList.length ? classes.selectedAll : ""
                                        }}
                                    >
                                        <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={ratingList.length > 0 && ratingFilter.length === ratingList.length}
                                            indeterminate={
                                                ratingFilter.length > 0 && ratingFilter.length < ratingList.length
                                            }
                                        />
                                        <ListItemText
                                            classes={{ primary: classes.selectAllText }}
                                            primary="Select All"
                                        />
                                    </MenuItem>
                                    {ratingList.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox color="primary" checked={ratingFilter.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                    : "" }

                    { accountTypeList ?
                        <Grid item xs={2}>

                            <FormControl className={classes.formControl}>

                                <InputLabel>Account Type</InputLabel>
                                <Select
                                    native
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    value={accountTypeFilter}
                                    onChange={handleAccountTypeChange}
                                >
                                    <option aria-label="None" value="" />
                                    {accountTypeList.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Select>

                            </FormControl>

                        </Grid>
                    : "" }

                    { labelList ?
                        <Grid item xs={2}>

                            <FormControl className={classes.formControls}>
                                <InputLabel>Label</InputLabel>
                                <Select
                                    multiple
                                    value={labelFilter}
                                    onChange={handleLabelChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem
                                        value="all"
                                        classes={{
                                            root: labelList.length > 0 && labelFilter.length === labelList.length ? classes.selectedAll : ""
                                        }}
                                    >
                                        <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={labelList.length > 0 && labelFilter.length === labelList.length}
                                            indeterminate={
                                                labelFilter.length > 0 && labelFilter.length < labelList.length
                                            }
                                        />
                                        <ListItemText
                                            classes={{ primary: classes.selectAllText }}
                                            primary="Select All"
                                        />
                                    </MenuItem>
                                    {labelList.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox color="primary" checked={labelFilter.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                    : "" }  

                    { roleList ?
                        <Grid item xs={2}>

                            <FormControl className={classes.formControls}>
                                <InputLabel>Role</InputLabel>
                                <Select
                                    multiple
                                    value={roleFilter}
                                    onChange={handleRoleChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem
                                        value="all"
                                        classes={{
                                            root: roleList.length > 0 && roleFilter.length === roleList.length ? classes.selectedAll : ""
                                        }}
                                    >
                                        <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={roleList.length > 0 && roleFilter.length === roleList.length}
                                            indeterminate={
                                                roleFilter.length > 0 && roleFilter.length < roleList.length
                                            }
                                        />
                                        <ListItemText
                                            classes={{ primary: classes.selectAllText }}
                                            primary="Select All"
                                        />
                                    </MenuItem>
                                    {roleList.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox color="primary" checked={roleFilter.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                    : "" }

                    { sentimentList ?
                        <Grid item xs={2}>

                            <FormControl className={classes.formControl}>

                                <InputLabel>Sentiment</InputLabel>
                                <Select
                                    native
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    value={sentimentFilter}
                                    onChange={handleSentimentChange}
                                >
                                    <option aria-label="None" value="" />
                                    {sentimentList.map((option) => (
                                        <option key={option} value={option}>
                                            {capitalize(option)}
                                        </option>
                                    ))}
                                </Select>

                            </FormControl>

                        </Grid>
                    : "" }

                    { areaList ?
                        <Grid item xs={2}>

                            <FormControl className={classes.formControl}>

                                <InputLabel>Area</InputLabel>
                                <Select
                                    multiple
                                    value={areaFilter}
                                    onChange={handleAreaChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >                                
                                    {areaList.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox color="primary" checked={areaFilter.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>

                        </Grid>
                    : "" }

                    { subAreaList ?
                        <Grid item xs={2}>

                            <FormControl className={classes.formControl}>

                                <InputLabel>Sub Area</InputLabel>
                                <Select
                                    multiple
                                    value={subAreaFilter}
                                    onChange={handleSubAreaChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {subAreaList.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox color="primary" checked={subAreaFilter.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                            <FormHelperText>Please select Area then Sub Area</FormHelperText>

                        </Grid>
                    : "" }

                    {   browserList ?
                        <Grid item xs={2}>

                            <FormControl className={classes.formControl}>

                                <InputLabel>Browser</InputLabel>
                                <Select
                                    multiple
                                    value={browserFilter}
                                    onChange={handleBrowserChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem
                                        value="all"
                                        classes={{
                                            root: browserList.length > 0 && browserFilter.length === browserList.length ? classes.selectedAll : ""
                                        }}
                                    >
                                        <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={browserList.length > 0 && browserFilter.length === browserList.length}
                                            indeterminate={
                                                browserFilter.length > 0 && browserFilter.length < browserList.length
                                            }
                                        />
                                        <ListItemText
                                            classes={{ primary: classes.selectAllText }}
                                            primary="Select All"
                                        />
                                    </MenuItem>
                                    {browserList.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox color="primary" checked={browserFilter.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>

                        </Grid>
                    : "" }

                    { operatingSystemList ?
                        <Grid item xs={2}>

                            <FormControl className={classes.formControls}>
                                <InputLabel>Operating System</InputLabel>
                                <Select
                                    multiple
                                    value={osFilter}
                                    onChange={handleOSChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem
                                        value="all"
                                        classes={{
                                            root: operatingSystemList.length > 0 && osFilter.length === operatingSystemList.length ? classes.selectedAll : ""
                                        }}
                                    >
                                        <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={operatingSystemList.length > 0 && osFilter.length === operatingSystemList.length}
                                            indeterminate={
                                                osFilter.length > 0 && osFilter.length < operatingSystemList.length
                                            }
                                        />
                                        <ListItemText
                                            classes={{ primary: classes.selectAllText }}
                                            primary="Select All"
                                        />
                                    </MenuItem>
                                    {operatingSystemList.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox color="primary" checked={osFilter.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                    : "" }

                    <Grid item>

                        <FormControl className={classes.formWidth}>

                            <InputLabel>Results Per Page</InputLabel>
                            <Select
                                native
                                displayEmpty
                                className={classes.selectEmpty}
                                value={limit}
                                onChange={handleResultsPerPageChange}
                            >
                                {resultsPerPageList.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Select>
                            
                        </FormControl>

                    </Grid>

                    <Grid item>

                    <FormControl className={classes.formWidth}>

                        <InputLabel>Sort by Date</InputLabel>
                        <Select
                            native
                            displayEmpty
                            className={classes.selectEmpty}
                            value={sortValue}
                            onChange={handleSortResults}
                        >
                            {sortList.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Select>
                        
                    </FormControl>

                    </Grid>

                </Grid>

                <Grid container spacing={1} align="right">
                    <Grid item xs={4}>

                        { hasResults ?
                            <Tooltip title="Expand Feedback Results">
                                <Button className={classes.button2} variant="contained" color="default" onClick={handleExpand}>{buttonText}</Button>
                            </Tooltip>
                        : " " }
                    </Grid>

                    <Grid item xs={2}>
                        <p>Start Date: {showStartDate}</p>
                        <p>End Date: {showEndDate}</p>
                    </Grid>

                    <Grid item xs={2}>

                        <Button className={classes.button} variant="contained" color="default" onClick={handleClickOpen}>Edit Date Range</Button>
                        <Dialog classes={{ paper: classes.paper}} onClose={handleClickClose} open={dateRange}>
                            <DialogContent>
                                <Paper>
                                    <DateRangePicker
                                        onChange={item => setDateRangeFilter([item.selection])}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        ranges={dateRangeFilter}
                                        direction="horizontal"
                                        minDate={addYears(new Date(), -2)}
                                        maxDate={new Date()}
                                    />
                                </Paper>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClickClose} color="primary">
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </Grid>

                    <Grid item xs={2}>
                        <Button className={classes.button2} onClick={handleOpenDialog}>Save & Subscribe</Button>
                    </Grid>

                    <Grid item xs={1}>
                        <Button className={classes.button2} onClick={handleResetClick}>Reset</Button>
                    </Grid>

                    <Dialog classes={{ paper: classes.paper}} onClose={handleCloseDialog} open={openDialog}>
                        <DialogContent>
                            <List>
                            <ListItem>
                                <TextField
                                    id="SearchName"
                                    label="Enter Search Name"
                                    type="text"
                                    value={searchName}
                                    fullWidth
                                    inputProps={{ maxLength: 100 }}
                                    onChange={handleObjectChange}
                                />   
                            </ListItem>
                            <DialogContentText style={{color: 'red'}}>
                                {errorMessage}
                            </DialogContentText>
                            <ListItem>
                                Keywords: {label}
                            </ListItem>
                            <ListItem>
                                Source: {sourceFilter }
                            </ListItem>
                            <ListItem>
                                Account Type: { accountTypeFilter }
                            </ListItem>
                            <ListItem>
                                Label: {labelFilter}
                            </ListItem>
                            <ListItem>
                                Role: { roleFilter }
                            </ListItem>
                            <ListItem>
                                Sentiment: { sentimentFilter }
                            </ListItem>
                            <ListItem>
                                Area: { areaFilter }
                            </ListItem>
                            <ListItem>
                                Sub Area: {subAreaFilter }
                            </ListItem>
                            <ListItem>
                                Browser: {browserFilter }
                            </ListItem>
                            <ListItem>
                                Operating System: {osFilter }
                            </ListItem>
                            <ListItem>
                                Rating: {ratingFilter}
                            </ListItem>
                        </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog(0)} color="primary">
                                Cancel
                            </Button>
                            <Button disabled={subModal} onClick={() => handleCloseDialog(1)} color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Grid item xs={1}>
                        <Tooltip title="Download Feedback Results">
                            <Button onClick={handleExportClick} disabled={disabled}><GetAppIcon/></Button>
                        </Tooltip>
                    </Grid>

                    <Dialog open={disabled} >
                        <DialogTitle>
                            Downloading feedback report
                        </DialogTitle>
                        <center><CircularProgress/></center>
                    </Dialog>

                    <Dialog onClose={handleCloseExportDialog} open={openExportDialog}>
                        <DialogTitle>
                            Feedback report is downloaded successfully! 
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleCloseExportDialog} variant="contained" color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}> 
                        <Input className={classes.searchbar}
                            type='text'
                            placeholder='Search for feedback'
                            icon='search'
                            iconposition='left'
                            name='label'
                            value={label}
                            onChange={(e) => { setLabel(e.target.value); setSearched(false); } }
                            onKeyDown={handleKeyDown}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                                }
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <div className={classes.results}>                         
                    {
                        hasResults
                        ? 
                            <>
                                <LoopFeedbackList feedback={feedback} criteria={sendCriteria} path={path} expand={expand} handleOpen={handleDialogOpen}/>
                                { numPages > 1 ? (
                                    <Pagination count={numPages} onChange={handlePageChange}/> 
                                ) : null
                                }
                            </>
                        : <NoResults />
                    }
                    </div>
                </Grid>


            </div>


        </>
    );

}

export default TheLoopSearchPage;