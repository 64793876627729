import React, { useEffect, useState } from "react";
import { API, Analytics } from 'aws-amplify';
import * as queries from '../graphql/queries';
import CircularProgress from '@material-ui/core/CircularProgress';

const defaultConfig = {
    'WelcomeMessage': ""
}
const pageTitle = 'ODP Home'

function ODPHomePage()  {

    const [config, setConfig] = useState(defaultConfig);
    const [initialized, setInitialized] = useState(0);

    useEffect(() => {
        Analytics.record({ name: 'ODP Home Page visit' });
        API.graphql({ query: queries.getEsDatahubPageConfig, variables: { id: 'ODPHOME'} })
        .then((data) => {
            setConfig(JSON.parse(data.data.getESDatahubPageConfig.config));
            setInitialized(1);
        })
        .catch((error) => {
            console.log(error);
            setInitialized(1);
        })

    }, []);

    return (
        <>
            <h2 className="page-title">{pageTitle}</h2>

            {initialized === 0 ? <CircularProgress /> : 
        
                <div className="HomePage">
                    {config.WelcomeMessage ? <div dangerouslySetInnerHTML={{__html: config.WelcomeMessage}} /> : "Error ... can not retrieve WelcomeMessage.  Please contact dl-es-data-support@move.com."}
                </div>

                /* <div><pre>{JSON.stringify(config, null, 2) }</pre></div>   */            
            
            }
        </>
    )

}

export default ODPHomePage;