/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEsDatahubPageConfig = /* GraphQL */ `
  mutation CreateEsDatahubPageConfig(
    $input: CreateESDatahubPageConfigInput!
    $condition: ModelESDatahubPageConfigConditionInput
  ) {
    createESDatahubPageConfig(input: $input, condition: $condition) {
      id
      config
      createdAt
      updatedAt
    }
  }
`;
export const updateEsDatahubPageConfig = /* GraphQL */ `
  mutation UpdateEsDatahubPageConfig(
    $input: UpdateESDatahubPageConfigInput!
    $condition: ModelESDatahubPageConfigConditionInput
  ) {
    updateESDatahubPageConfig(input: $input, condition: $condition) {
      id
      config
      createdAt
      updatedAt
    }
  }
`;
export const deleteEsDatahubPageConfig = /* GraphQL */ `
  mutation DeleteEsDatahubPageConfig(
    $input: DeleteESDatahubPageConfigInput!
    $condition: ModelESDatahubPageConfigConditionInput
  ) {
    deleteESDatahubPageConfig(input: $input, condition: $condition) {
      id
      config
      createdAt
      updatedAt
    }
  }
`;
export const createTheloopSurvey = /* GraphQL */ `
  mutation CreateTheloopSurvey(
    $input: CreateTheloopSurveyInput!
    $condition: ModelTheloopSurveyConditionInput
  ) {
    createTheloopSurvey(input: $input, condition: $condition) {
      id
      surveyName
      isActive
      creationDate
      lastModified
      createdAt
      updatedAt
    }
  }
`;
export const updateTheloopSurvey = /* GraphQL */ `
  mutation UpdateTheloopSurvey(
    $input: UpdateTheloopSurveyInput!
    $condition: ModelTheloopSurveyConditionInput
  ) {
    updateTheloopSurvey(input: $input, condition: $condition) {
      id
      surveyName
      isActive
      creationDate
      lastModified
      createdAt
      updatedAt
    }
  }
`;
export const deleteTheloopSurvey = /* GraphQL */ `
  mutation DeleteTheloopSurvey(
    $input: DeleteTheloopSurveyInput!
    $condition: ModelTheloopSurveyConditionInput
  ) {
    deleteTheloopSurvey(input: $input, condition: $condition) {
      id
      surveyName
      isActive
      creationDate
      lastModified
      createdAt
      updatedAt
    }
  }
`;
export const createOdpAppMonitorEntry = /* GraphQL */ `
  mutation CreateOdpAppMonitorEntry(
    $input: CreateOdpAppMonitorEntryInput!
    $condition: ModelodpAppMonitorEntryConditionInput
  ) {
    createOdpAppMonitorEntry(input: $input, condition: $condition) {
      class
      id
      key
      status
      message
      messageType
      function
      createdAt
      updatedAt
    }
  }
`;
export const updateOdpAppMonitorEntry = /* GraphQL */ `
  mutation UpdateOdpAppMonitorEntry(
    $input: UpdateOdpAppMonitorEntryInput!
    $condition: ModelodpAppMonitorEntryConditionInput
  ) {
    updateOdpAppMonitorEntry(input: $input, condition: $condition) {
      class
      id
      key
      status
      message
      messageType
      function
      createdAt
      updatedAt
    }
  }
`;
export const deleteOdpAppMonitorEntry = /* GraphQL */ `
  mutation DeleteOdpAppMonitorEntry(
    $input: DeleteOdpAppMonitorEntryInput!
    $condition: ModelodpAppMonitorEntryConditionInput
  ) {
    deleteOdpAppMonitorEntry(input: $input, condition: $condition) {
      class
      id
      key
      status
      message
      messageType
      function
      createdAt
      updatedAt
    }
  }
`;
export const createOdpAppMonitorEntrySummary = /* GraphQL */ `
  mutation CreateOdpAppMonitorEntrySummary(
    $input: CreateOdpAppMonitorEntrySummaryInput!
    $condition: ModelodpAppMonitorEntrySummaryConditionInput
  ) {
    createOdpAppMonitorEntrySummary(input: $input, condition: $condition) {
      key
      keyType
      updateFrequency
      frequencyArg
      tags
      active
      currentStatus
      lastMessage
      lastMessageType
      lastFunction
      lastStarted
      lastCompleted
      createdAt
      updatedAt
    }
  }
`;
export const updateOdpAppMonitorEntrySummary = /* GraphQL */ `
  mutation UpdateOdpAppMonitorEntrySummary(
    $input: UpdateOdpAppMonitorEntrySummaryInput!
    $condition: ModelodpAppMonitorEntrySummaryConditionInput
  ) {
    updateOdpAppMonitorEntrySummary(input: $input, condition: $condition) {
      key
      keyType
      updateFrequency
      frequencyArg
      tags
      active
      currentStatus
      lastMessage
      lastMessageType
      lastFunction
      lastStarted
      lastCompleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteOdpAppMonitorEntrySummary = /* GraphQL */ `
  mutation DeleteOdpAppMonitorEntrySummary(
    $input: DeleteOdpAppMonitorEntrySummaryInput!
    $condition: ModelodpAppMonitorEntrySummaryConditionInput
  ) {
    deleteOdpAppMonitorEntrySummary(input: $input, condition: $condition) {
      key
      keyType
      updateFrequency
      frequencyArg
      tags
      active
      currentStatus
      lastMessage
      lastMessageType
      lastFunction
      lastStarted
      lastCompleted
      createdAt
      updatedAt
    }
  }
`;
export const createTheLoopFeedback = /* GraphQL */ `
  mutation CreateTheLoopFeedback(
    $input: CreateTheLoopFeedbackInput!
    $condition: ModelTheLoopFeedbackConditionInput
  ) {
    createTheLoopFeedback(input: $input, condition: $condition) {
      recordid
      record_source
      source_id
      case_number
      origin
      origin_description
      account_name
      subject
      description
      priority
      type
      area
      sub_area
      datetime_openened
      status
      owner_name
      browser
      operating_system
      current_url
      user_agent
      is_closed
      Sentiment
      OriginalSentiment
      SentimentScore
      created_date
      last_modified_date
      closed_date
      updatedAt
      createdAt
      account_type
      sub_account_type
      partyid
      jiracasenumber__c
      source
      created_date_ut
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          id
          owner
          username
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          recordid
          theLoopFeedback {
            recordid
            record_source
            source_id
            case_number
            origin
            origin_description
            account_name
            subject
            description
            priority
            type
            area
            sub_area
            datetime_openened
            status
            owner_name
            browser
            operating_system
            current_url
            user_agent
            is_closed
            Sentiment
            OriginalSentiment
            SentimentScore
            created_date
            last_modified_date
            closed_date
            updatedAt
            createdAt
            account_type
            sub_account_type
            partyid
            jiracasenumber__c
            source
            created_date_ut
            role
            case_comments
            research_notes
            star_rating
            label
          }
          unfollow
          createdAt
          updatedAt
        }
        nextToken
      }
      role
      case_comments
      research_notes
      star_rating
      label
    }
  }
`;
export const updateTheLoopFeedback = /* GraphQL */ `
  mutation UpdateTheLoopFeedback(
    $input: UpdateTheLoopFeedbackInput!
    $condition: ModelTheLoopFeedbackConditionInput
  ) {
    updateTheLoopFeedback(input: $input, condition: $condition) {
      recordid
      record_source
      source_id
      case_number
      origin
      origin_description
      account_name
      subject
      description
      priority
      type
      area
      sub_area
      datetime_openened
      status
      owner_name
      browser
      operating_system
      current_url
      user_agent
      is_closed
      Sentiment
      OriginalSentiment
      SentimentScore
      created_date
      last_modified_date
      closed_date
      updatedAt
      createdAt
      account_type
      sub_account_type
      partyid
      jiracasenumber__c
      source
      created_date_ut
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          id
          owner
          username
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          recordid
          theLoopFeedback {
            recordid
            record_source
            source_id
            case_number
            origin
            origin_description
            account_name
            subject
            description
            priority
            type
            area
            sub_area
            datetime_openened
            status
            owner_name
            browser
            operating_system
            current_url
            user_agent
            is_closed
            Sentiment
            OriginalSentiment
            SentimentScore
            created_date
            last_modified_date
            closed_date
            updatedAt
            createdAt
            account_type
            sub_account_type
            partyid
            jiracasenumber__c
            source
            created_date_ut
            role
            case_comments
            research_notes
            star_rating
            label
          }
          unfollow
          createdAt
          updatedAt
        }
        nextToken
      }
      role
      case_comments
      research_notes
      star_rating
      label
    }
  }
`;
export const deleteTheLoopFeedback = /* GraphQL */ `
  mutation DeleteTheLoopFeedback(
    $input: DeleteTheLoopFeedbackInput!
    $condition: ModelTheLoopFeedbackConditionInput
  ) {
    deleteTheLoopFeedback(input: $input, condition: $condition) {
      recordid
      record_source
      source_id
      case_number
      origin
      origin_description
      account_name
      subject
      description
      priority
      type
      area
      sub_area
      datetime_openened
      status
      owner_name
      browser
      operating_system
      current_url
      user_agent
      is_closed
      Sentiment
      OriginalSentiment
      SentimentScore
      created_date
      last_modified_date
      closed_date
      updatedAt
      createdAt
      account_type
      sub_account_type
      partyid
      jiracasenumber__c
      source
      created_date_ut
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          id
          owner
          username
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          recordid
          theLoopFeedback {
            recordid
            record_source
            source_id
            case_number
            origin
            origin_description
            account_name
            subject
            description
            priority
            type
            area
            sub_area
            datetime_openened
            status
            owner_name
            browser
            operating_system
            current_url
            user_agent
            is_closed
            Sentiment
            OriginalSentiment
            SentimentScore
            created_date
            last_modified_date
            closed_date
            updatedAt
            createdAt
            account_type
            sub_account_type
            partyid
            jiracasenumber__c
            source
            created_date_ut
            role
            case_comments
            research_notes
            star_rating
            label
          }
          unfollow
          createdAt
          updatedAt
        }
        nextToken
      }
      role
      case_comments
      research_notes
      star_rating
      label
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      username
      given_name
      last_name
      email
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      username
      given_name
      last_name
      email
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      username
      given_name
      last_name
      email
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTheLoopFeedbackComment = /* GraphQL */ `
  mutation CreateTheLoopFeedbackComment(
    $input: CreateTheLoopFeedbackCommentInput!
    $condition: ModelTheLoopFeedbackCommentConditionInput
  ) {
    createTheLoopFeedbackComment(input: $input, condition: $condition) {
      id
      recordid
      content
      owner
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTheLoopFeedbackComment = /* GraphQL */ `
  mutation UpdateTheLoopFeedbackComment(
    $input: UpdateTheLoopFeedbackCommentInput!
    $condition: ModelTheLoopFeedbackCommentConditionInput
  ) {
    updateTheLoopFeedbackComment(input: $input, condition: $condition) {
      id
      recordid
      content
      owner
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTheLoopFeedbackComment = /* GraphQL */ `
  mutation DeleteTheLoopFeedbackComment(
    $input: DeleteTheLoopFeedbackCommentInput!
    $condition: ModelTheLoopFeedbackCommentConditionInput
  ) {
    deleteTheLoopFeedbackComment(input: $input, condition: $condition) {
      id
      recordid
      content
      owner
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserFollows = /* GraphQL */ `
  mutation CreateUserFollows(
    $input: CreateUserFollowsInput!
    $condition: ModelUserFollowsConditionInput
  ) {
    createUserFollows(input: $input, condition: $condition) {
      id
      owner
      username
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      recordid
      theLoopFeedback {
        recordid
        record_source
        source_id
        case_number
        origin
        origin_description
        account_name
        subject
        description
        priority
        type
        area
        sub_area
        datetime_openened
        status
        owner_name
        browser
        operating_system
        current_url
        user_agent
        is_closed
        Sentiment
        OriginalSentiment
        SentimentScore
        created_date
        last_modified_date
        closed_date
        updatedAt
        createdAt
        account_type
        sub_account_type
        partyid
        jiracasenumber__c
        source
        created_date_ut
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        followers {
          items {
            id
            owner
            username
            recordid
            unfollow
            createdAt
            updatedAt
          }
          nextToken
        }
        role
      }
      unfollow
      createdAt
      updatedAt
    }
  }
`;
export const updateUserFollows = /* GraphQL */ `
  mutation UpdateUserFollows(
    $input: UpdateUserFollowsInput!
    $condition: ModelUserFollowsConditionInput
  ) {
    updateUserFollows(input: $input, condition: $condition) {
      id
      owner
      username
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      recordid
      theLoopFeedback {
        recordid
        record_source
        source_id
        case_number
        origin
        origin_description
        account_name
        subject
        description
        priority
        type
        area
        sub_area
        datetime_openened
        status
        owner_name
        browser
        operating_system
        current_url
        user_agent
        is_closed
        Sentiment
        OriginalSentiment
        SentimentScore
        created_date
        last_modified_date
        closed_date
        updatedAt
        createdAt
        account_type
        sub_account_type
        partyid
        jiracasenumber__c
        source
        created_date_ut
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        followers {
          items {
            id
            owner
            username
            recordid
            unfollow
            createdAt
            updatedAt
          }
          nextToken
        }
        role
      }
      unfollow
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserFollows = /* GraphQL */ `
  mutation DeleteUserFollows(
    $input: DeleteUserFollowsInput!
    $condition: ModelUserFollowsConditionInput
  ) {
    deleteUserFollows(input: $input, condition: $condition) {
      id
      owner
      username
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      recordid
      theLoopFeedback {
        recordid
        record_source
        source_id
        case_number
        origin
        origin_description
        account_name
        subject
        description
        priority
        type
        area
        sub_area
        datetime_openened
        status
        owner_name
        browser
        operating_system
        current_url
        user_agent
        is_closed
        Sentiment
        OriginalSentiment
        SentimentScore
        created_date
        last_modified_date
        closed_date
        updatedAt
        createdAt
        account_type
        sub_account_type
        partyid
        jiracasenumber__c
        source
        created_date_ut
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        followers {
          items {
            id
            owner
            username
            recordid
            unfollow
            createdAt
            updatedAt
          }
          nextToken
        }
        role
      }
      unfollow
      createdAt
      updatedAt
    }
  }
`;
export const createAnnouncementBoardEntry = /* GraphQL */ `
  mutation CreateAnnouncementBoardEntry(
    $input: CreateAnnouncementBoardEntryInput!
    $condition: ModelAnnouncementBoardEntryConditionInput
  ) {
    createAnnouncementBoardEntry(input: $input, condition: $condition) {
      id
      objectName
      status
      description
      detail
      createdAt
      updatedAt
    }
  }
`;
export const updateAnnouncementBoardEntry = /* GraphQL */ `
  mutation UpdateAnnouncementBoardEntry(
    $input: UpdateAnnouncementBoardEntryInput!
    $condition: ModelAnnouncementBoardEntryConditionInput
  ) {
    updateAnnouncementBoardEntry(input: $input, condition: $condition) {
      id
      objectName
      status
      description
      detail
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnnouncementBoardEntry = /* GraphQL */ `
  mutation DeleteAnnouncementBoardEntry(
    $input: DeleteAnnouncementBoardEntryInput!
    $condition: ModelAnnouncementBoardEntryConditionInput
  ) {
    deleteAnnouncementBoardEntry(input: $input, condition: $condition) {
      id
      objectName
      status
      description
      detail
      createdAt
      updatedAt
    }
  }
`;
export const createSavedSearch = /* GraphQL */ `
  mutation CreateSavedSearch(
    $input: CreateSavedSearchInput!
    $condition: ModelSavedSearchConditionInput
  ) {
    createSavedSearch(input: $input, condition: $condition) {
      id
      userName
      searchNameTag
      searchCriteria
      subscribe
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      description
      subject
      role
      origin
      case_number
      createdAt
      updatedAt
      star_rating
      label
    }
  }
`;
export const updateSavedSearch = /* GraphQL */ `
  mutation UpdateSavedSearch(
    $input: UpdateSavedSearchInput!
    $condition: ModelSavedSearchConditionInput
  ) {
    updateSavedSearch(input: $input, condition: $condition) {
      id
      userName
      searchNameTag
      searchCriteria
      subscribe
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      description
      subject
      role
      origin
      case_number
      createdAt
      updatedAt
      star_rating
      label
    }
  }
`;
export const deleteSavedSearch = /* GraphQL */ `
  mutation DeleteSavedSearch(
    $input: DeleteSavedSearchInput!
    $condition: ModelSavedSearchConditionInput
  ) {
    deleteSavedSearch(input: $input, condition: $condition) {
      id
      userName
      searchNameTag
      searchCriteria
      subscribe
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      description
      subject
      role
      origin
      case_number
      createdAt
      updatedAt
      star_rating
      label
    }
  }
`;
export const createUserSubscription = /* GraphQL */ `
  mutation CreateUserSubscription(
    $input: CreateUserSubscriptionInput!
    $condition: ModelUserSubscriptionConditionInput
  ) {
    createUserSubscription(input: $input, condition: $condition) {
      id
      userName
      createdAt
      updatedAt
    }
  }
`;
export const updateUserSubscription = /* GraphQL */ `
  mutation UpdateUserSubscription(
    $input: UpdateUserSubscriptionInput!
    $condition: ModelUserSubscriptionConditionInput
  ) {
    updateUserSubscription(input: $input, condition: $condition) {
      id
      userName
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserSubscription = /* GraphQL */ `
  mutation DeleteUserSubscription(
    $input: DeleteUserSubscriptionInput!
    $condition: ModelUserSubscriptionConditionInput
  ) {
    deleteUserSubscription(input: $input, condition: $condition) {
      id
      userName
      createdAt
      updatedAt
    }
  }
`;
export const createFilterValues = /* GraphQL */ `
  mutation CreateFilterValues(
    $input: CreateFilterValuesInput!
    $condition: ModelFilterValuesConditionInput
  ) {
    createFilterValues(input: $input, condition: $condition) {
      id
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      role
      createdAt
      updatedAt
      star_rating
      label
    }
  }
`;
export const updateFilterValues = /* GraphQL */ `
  mutation UpdateFilterValues(
    $input: UpdateFilterValuesInput!
    $condition: ModelFilterValuesConditionInput
  ) {
    updateFilterValues(input: $input, condition: $condition) {
      id
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      role
      createdAt
      updatedAt
      star_rating
      label
    }
  }
`;
export const deleteFilterValues = /* GraphQL */ `
  mutation DeleteFilterValues(
    $input: DeleteFilterValuesInput!
    $condition: ModelFilterValuesConditionInput
  ) {
    deleteFilterValues(input: $input, condition: $condition) {
      id
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      role
      createdAt
      updatedAt
      star_rating
      label
    }
  }
`;
export const createLoopStoreReview = /* GraphQL */ `
  mutation CreateLoopStoreReview(
    $input: CreateLoopStoreReviewInput!
    $condition: ModelLoopStoreReviewConditionInput
  ) {
    createLoopStoreReview(input: $input, condition: $condition) {
      id
      author
      version
      review
      rating
      title
      comment
      reply
      date
      source
      createdAt
      updatedAt
    }
  }
`;
export const updateLoopStoreReview = /* GraphQL */ `
  mutation UpdateLoopStoreReview(
    $input: UpdateLoopStoreReviewInput!
    $condition: ModelLoopStoreReviewConditionInput
  ) {
    updateLoopStoreReview(input: $input, condition: $condition) {
      id
      author
      version
      review
      rating
      title
      comment
      reply
      date
      source
    }
  }
`;
export const deleteLoopStoreReview = /* GraphQL */ `
  mutation DeleteLoopStoreReview(
    $input: DeleteLoopStoreReviewInput!
    $condition: ModelLoopStoreReviewConditionInput
  ) {
    deleteLoopStoreReview(input: $input, condition: $condition) {
      id
      author
      version
      review
      rating
      title
      comment
      reply
      date
      source
      createdAt
      updatedAt
    }
  }
`;

export const createArea = /* GraphQL */ `
  mutation CreateArea(
    $input: CreateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    createArea(input: $input, condition: $condition) {
      id
      area_id
      area
      sub_area{
        items{
          id
          subarea_id
          area_id
          sub_area
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateArea = /* GraphQL */ `
  mutation UpdateArea(
    $input: UpdateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    updateArea(input: $input, condition: $condition) {
      id
      area_id
      area
      sub_area{
        items{
          id
          subarea_id
          area_id
          sub_area
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteArea = /* GraphQL */ `
  mutation DeleteArea(
    $input: DeleteAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    deleteArea(input: $input, condition: $condition) {
      id
      area_id
      area
      sub_area{
        items{
          id
          subarea_id
          area_id
          sub_area
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const createSubarea = /* GraphQL */ `
  mutation CreateSubarea(
    $input: CreateSubareaInput!
    $condition: ModelSubareaConditionInput
  ) {
    createSubarea(input: $input, condition: $condition) {
      id
      subarea_id
      area_id
      sub_area
      createdAt
      updatedAt
    }
  }
`;
export const updateSubarea = /* GraphQL */ `
  mutation UpdateSubarea(
    $input: UpdateSubareaInput!
    $condition: ModelSubareaConditionInput
  ) {
    updateSubarea(input: $input, condition: $condition) {
      id
      subarea_id
      area_id
      sub_area
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubarea = /* GraphQL */ `
  mutation DeleteSubarea(
    $input: DeleteSubareaInput!
    $condition: ModelSubareaConditionInput
  ) {
    deleteSubarea(input: $input, condition: $condition) {
      id
      subarea_id
      area_id
      sub_area
      createdAt
      updatedAt
    }
  }
`;

export const createMultipleCaseComments = /* GraphQL */ `
  mutation CreateMultipleCaseComments(
    $input: CreateMultipleCaseCommentsInput!
    $condition: ModelMultipleCaseCommentsConditionInput
  ) {
    createMultipleCaseComments(input: $input, condition: $condition) {
      id
      case_comments
      createdAt
      updatedAt
    }
  }
`;
export const updateMultipleCaseComments = /* GraphQL */ `
  mutation UpdateMultipleCaseComments(
    $input: UpdateMultipleCaseCommentsInput!
    $condition: ModelMultipleCaseCommentsConditionInput
  ) {
    updateMultipleCaseComments(input: $input, condition: $condition) {
      id
      case_comments
      createdAt
      updatedAt
    }
  }
`;
export const deleteMultipleCaseComments = /* GraphQL */ `
  mutation DeleteMultipleCaseComments(
    $input: DeleteMultipleCaseCommentsInput!
    $condition: ModelMultipleCaseCommentsConditionInput
  ) {
    deleteMultipleCaseComments(input: $input, condition: $condition) {
      id
      case_comments
      createdAt
      updatedAt
    }
  }
`;

export const createOperatingSystem = /* GraphQL */ `
  mutation CreateOperatingSystem(
    $input: CreateOperatingSystemInput!
    $condition: ModelOperatingSystemConditionInput
  ) {
    createOperatingSystem(input: $input, condition: $condition) {
      id
      os_id
      os
      createdAt
      updatedAt
    }
  }
`;
export const updateOperatingSystem = /* GraphQL */ `
  mutation UpdateOperatingSystem(
    $input: UpdateOperatingSystemInput!
    $condition: ModelOperatingSystemConditionInput
  ) {
    updateOperatingSystem(input: $input, condition: $condition) {
      id
      os_id
      os
      createdAt
      updatedAt
    }
  }
`;
export const deleteOperatingSystem = /* GraphQL */ `
  mutation DeleteOperatingSystem(
    $input: DeleteOperatingSystemInput!
    $condition: ModelOperatingSystemConditionInput
  ) {
    deleteOperatingSystem(input: $input, condition: $condition) {
      id
      os_id
      os
      createdAt
      updatedAt
    }
  }
`;

export const createLoopVonageMetadata = /* GraphQL */ `
  mutation CreateLoopVonageMetadata(
    $input: CreateLoopVonageMetadataInput!
    $condition: ModelLoopVonageMetadataConditionInput
  ) {
    createLoopVonageMetadata(input: $input, condition: $condition) {
      id
      start
      connect_to
      connect_from
      direction
      contentLength
    }
  }
`;
export const updateLoopVonageMetadata = /* GraphQL */ `
  mutation UpdateLoopVonageMetadata(
    $input: UpdateLoopVonageMetadataInput!
    $condition: ModelLoopVonageMetadataConditionInput
  ) {
    updateLoopVonageMetadata(input: $input, condition: $condition) {
      id
      start
      connect_to
      connect_from
      direction
      contentLength
    }
  }
`;
export const deleteLoopVonageMetadata = /* GraphQL */ `
  mutation DeleteLoopVonageMetadata(
    $input: DeleteLoopVonageMetadataInput!
    $condition: ModelLoopVonageMetadataConditionInput
  ) {
    deleteLoopVonageMetadata(input: $input, condition: $condition) {
      id
      start
      connect_to
      connect_from
      direction
      contentLength
    }
  }
`;