import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import moment from "moment-timezone";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays, addYears } from 'date-fns';

import { AuthContext } from "../App";
// import { Line, Bar } from 'react-chartjs-2';

const sourceList = [
    'Feedback',
    'Survey'
]

const areaList = [

    { value: 'Billing', label: 'Billing' },
    { value: 'Chat Experience', label: 'Chat Experience' },
    { value: 'Dashboard', label: 'Dashboard' },
    { value: 'Experience', label: 'Experience' },
    { value: 'Find a Realtor', label: 'Find a Realtor' },
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Mortgage', label: 'Mortgage' },
    { value: 'My Account', label: 'My Account' },
    { value: 'News & Insights', label: 'News & Insights' },
    { value: 'Not For Sale Properties', label: 'Not For Sale Properties' },
    { value: 'Product', label: 'Product' },
    { value: 'Product Set Up', label: 'Product Set Up' },
    { value: 'Property', label: 'Property' },
    { value: 'Sales', label: 'Sales' },
    { value: 'Search', label: 'Search' },
    { value: 'Selling a Property', label: 'Selling a Property' },
    { value: 'Service', label: 'Service' },
    { value: 'Site', label: 'Site' },

];

const accountTypeList = [
    { value: 'Consumer' },
    { value: 'Customer' },
    { value: 'Prospect' },
];

const sentimentList = [
    'POSITIVE',
    'NEUTRAL',
    'NEGATIVE'
];

const subAreaList = [

    { value: 'Address All Issues (Appropriate Resolution)', label:'Address All Issues' },
    { value: 'Assistance request (Separate department)', label: 'Assistance request' },
    { value: 'Compliment (general/specific)', label: 'Compliment' },
    { value: 'Filter Results', label: 'Filter Results' },
    { value: 'Follow up/Follow through (Response time)', label: 'Follow up/Follow through' },
    { value: 'For Sale By Owner', label: 'For Sale By Owner' },
    { value: 'Hold Time (General hold time)', label: 'Hold Time' },
    { value: 'Home Value',label: 'Home Value' },
    { value: 'Home Value Estimate',label: 'Home Value Estimate' },
    { value: 'Maps', label: 'Maps' },
    { value: 'New Filter request', label: 'New Filter request' },
    { value: 'Notifications', label: 'Notifications' },
    { value: 'Photos', label: 'Photos' },
    { value: 'Professionalism (Communication/Attitude)', label: 'Professionalism' },
    { value: 'Property Detail', label: 'Property Detail' },
    { value: 'Property History', label: 'Property History' },
    { value: 'Provide Accurate Information (Provides Pertinent Information)', label: 'Provide Accurate Information' },
    { value: 'Quality/quantity', label: 'Quality/quantity' },
    { value: 'Rating/Review', label: 'Rating/Review' },
    { value: 'Recommended Homes', label: 'Recommended Homes' },
    { value: 'Replenishment Request', label: 'Replenishment Request' },
    { value: 'Saved Searches', label: 'Saved Searches' },
    { value: 'Technical Issue', label: 'Technical Issue' },
    { value: 'Other', label: 'Other' }

];

const browserList = [
    'Chrome',
    'Edge',
    'Firefox',
    'Native App',
    'Safari',
    'Web',
    'Other'
];

const operatingSystemList = [
    'Android',
    'Desktop - Mac',
    'Desktop - Other',
    'Desktop - Windows',
    'Mobile - Android',
    'Mobile - iOS',
    'iPhone',
    'iPad',
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      textAlign: 'left',
      padding: theme.spacing(1),
      fontFamily: 'Arial',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    searchbar: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '70%',
    },
    formControls: {
        margin: theme.spacing(1),
        width: '80%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button: {
        color: '#FFFFFF',
        backgroundColor: '#d92228',
        '&:hover': {
            backgroundColor: '#a51d32',
          },
        fontFamily: 'Arial',
        fontWeight: 'bold'
    },
  }));

// const pageID = 'LOOPSEARCH'
const pageTitle = 'The Loop Trends'

function TheLoopTrendsPage()  {

    const [ chartData, setChartData ] = useState([])
    const [ barChartData, setBarChartData ] = useState([])
    const { state } = React.useContext(AuthContext);
    const [label, setLabel] = useState('')
    const [hasResults, setHasResults] = useState(false)
    const [searched, setSearched] = useState(false)
    const [err, setErr] = useState(false)
    const [errorMessage, setErrorMessage] = useState();
    const [sourceFilter, setSourceFilter] = useState("");
    const [searchFilter, setSearchFilter] = useState("");
    const [searchCriteria, setSearchCriteria] = useState("");
    const [areaFilter, setAreaFilter] = useState([]);
    const [sentimentFilter, setSentimentFilter] = useState("");
    const [accountTypeFilter, setAccountTypeFilter] = useState("");
    const [subAreaFilter, setSubAreaFilter] = useState([]);
    const [browserFilter, setBrowserFilter] = useState([]);
    const [osFilter, setOSFilter] = useState([]);
    const [searchName, setSearchName] = useState("");
    const [dateRangeFilter, setDateRangeFilter] = useState([
        {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [dateRange, setDateRange] = useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);

    const classes = useStyles();

    const showStartDate = moment(dateRangeFilter[0].startDate).format("MMM D, YYYY");
    const showEndDate = moment(dateRangeFilter[0].endDate).format("MMM D, YYYY");

    function capitalize(s) {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    }

    const getFeedbackFromSearch = async (e) => {
        
        try {

            var searchFilter = {}
            var searchCriteria = {}

            if (label !== "")  {
                searchFilter['or'] = [
                    { 'description': { match: label }},
                    { 'subject': { match: label }}
                ]
                searchCriteria['or'] = [
                    { 'description': { match: label }},
                    { 'subject': { match: label }}
                ]
            }
            
            if (sourceFilter !== "")   {
                searchFilter['source'] = { eq: sourceFilter }
                searchCriteria['source'] = { eq: sourceFilter }
            }
            if (areaFilter.length !== 0)   {
                searchFilter['area'] = { match: areaFilter }
                searchCriteria['area'] = { match: areaFilter }
            }
            if (accountTypeFilter !== "")   {
                searchFilter['account_type'] = { eq: accountTypeFilter }
                searchCriteria['account_type'] = { eq: accountTypeFilter }
            }
            if (sentimentFilter !== "")   {
                searchFilter['Sentiment'] = { eq: sentimentFilter }
                searchCriteria['Sentiment'] = { eq: sentimentFilter }
            }
            if (subAreaFilter.length !== 0)   {
                searchFilter['sub_area'] = { match: subAreaFilter }
                searchCriteria['sub_area'] = { match: subAreaFilter }
            }
            if (browserFilter.length !== 0)   {
                searchFilter['browser'] = { match: browserFilter }
                searchCriteria['browser'] = { match: browserFilter }
            }
            if (osFilter.length !== 0)   {
                searchFilter['operating_system'] = { match: osFilter }
                searchCriteria['operating_system'] = { match: osFilter }
            }

            const startDate = dateRangeFilter[0].startDate
            const endDate = addDays(dateRangeFilter[0].endDate, 1); // add a day then use less than to get full day.

            searchFilter['and'] = [
                { 'created_date_ut': { gte: startDate.getTime() / 1000 } },
                { 'created_date_ut': { lt: endDate.getTime() / 1000 } }
            ]

            let sort = {
                "direction": "asc",
                "field": "created_date_ut"
            }
            // let sort = {}

            // console.log('Search Filter:')
            // console.log(searchFilter,"filter.");
            // console.log(JSON.stringify(searchFilter),"filter results2");
            // const filterResults = JSON.stringify(searchFilter);

            const result = await API.graphql(graphqlOperation(queries.searchTheLoopFeedbacks, { filter: searchFilter, sort: sort, limit: 999 }));

            // console.log('result');
            console.log(result,"results");
            console.log('Returned', result.data.searchTheLoopFeedbacks.items.length, 'of Total # Records', result.data.searchTheLoopFeedbacks.total)
            setHasResults(result.data.searchTheLoopFeedbacks.items.length > 0)

            const dateList = [];
            const monthFeedback = [];

            for (let i = 0; i < result.data.searchTheLoopFeedbacks.items.length; i++) {
                const list = result.data.searchTheLoopFeedbacks.items[i]
                const dates = moment(list.created_date).format("MMM D, YYYY")
                const month = moment(list.created_date).format("MMM")
                const sentiment = list.Sentiment
                if (dates!=null){
                    dateList.push(dates);
                }
                if (month!=null){
                    monthFeedback.push(
                        {"month":month,"sentiment":sentiment}
                    );
                }
            }
 
            const datemap = dateList.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
            console.log( datemap, "datemap")
            console.log( monthFeedback, "monthFeedback")

            const monthChart = monthFeedback.reduce((a,{month,sentiment}) =>
            {
                let found_month = a.find( x => x.month === month)
                    if (found_month){
                        if (found_month.hasOwnProperty(sentiment)) {
                            found_month[sentiment]++
                        } else {
                            found_month[sentiment]=1
                        }
                    } else {
                        a.push({month})
                        let fm = a.find( x => x.month === month)
                        fm[sentiment]=1
                    }

                    return a 
            },[])

            const monthBarChart = (obj, searchKey, results = []) => {
                const r = results;
                obj.forEach(item => {
                if (item.hasOwnProperty(searchKey)) {
                    r.push(item[searchKey]);
                } else {
                    r.push('');
                }
                });
                return r;
            };
       
            console.log(monthChart, "monthChart")

            setChartData({
                labels: [...datemap.keys()],
                beginAtZero: true,
                datasets: [
                  {
                    label: 'Number of Feedback',
                    data : [...datemap.values()],
                    beginAtZero: true,
                    borderWidth:4,
                    backgroundColor: "rgba(54, 162, 235, 0.2)",
                    borderColor: "rgba(54, 162, 235, 1)"
                  }
                ]
              })
              setBarChartData({
                labels: monthBarChart(monthChart, 'month'),
                datasets: [
                  {
                    label: 'Positive Feedback',
                    data: monthBarChart(monthChart, 'POSITIVE'),
                    borderWidth: 4,
                    backgroundColor: 'rgb(0, 225, 0, 0.4)',
                    borderColor: 'rgb(0, 225, 0, 0.7)',
                  },
                  {
                    label: 'Neutral Feedback',
                    data: monthBarChart(monthChart, 'NEUTRAL'),
                    borderWidth: 4,
                    backgroundColor: 'rgb(255, 165, 0, 0.4)',
                    borderColor: 'rgb(255, 165, 0, 0.7)',
                  },
                  {
                    label: 'Negative Feedback',
                    data: monthBarChart(monthChart, 'NEGATIVE'),
                    borderWidth: 4,
                    backgroundColor: 'rgb(255, 0, 0, 0.4)',
                    borderColor: "rgba(255, 0, 0, 0.7)"
                  },
                  {
                    label: 'Other',
                    data : monthBarChart(monthChart, ''),
                    borderWidth: 4,
                    backgroundColor: "rgba(54, 162, 235, 0.2)",
                    borderColor: "rgba(54, 162, 235, 1)"
                  },
                ]
              })
            setSearchFilter(JSON.stringify(searchFilter))
            setSearchCriteria(JSON.stringify(searchCriteria))
            setSearched(true)
            setErr(false);
            console.log('finished search')
        }
        catch(err) {
            console.log('Caught Error');
            console.log(err);
            setErr(err.errors[0].message);
        }
    }

    const handleKeyDown = (event) => {
    
        if (event.key === 'Enter')  {
            getFeedbackFromSearch();
        }

    };

    const NoResults = () => {
        return !searched || label === ''
           ? 'No matching feedback found for selected filters'
           : <h4 color='grey'>No matching feedback found for '{label}'</h4>
    }

    const handleSourceChange = (event) => {
        setSourceFilter(event.target.value);
    };

    const handleAreaChange = (event) => {
        setAreaFilter(event.target.value);
    };

    const handleAccountTypeChange = (event) => {
        setAccountTypeFilter(event.target.value);
    };

    const handleSentimentChange = (event) => {
        setSentimentFilter(event.target.value);
    };

    const handleSubAreaChange = (event) => {
        setSubAreaFilter(event.target.value);
    };

    const handleBrowserChange = (event) => {
        setBrowserFilter(event.target.value);
    };

    const handleOSChange = (event) => {
        setOSFilter(event.target.value);
    };

    const handleResetClick = (event) => {
        setAreaFilter([]);
        setSentimentFilter("");
        setAccountTypeFilter("");
        setSubAreaFilter([]);
        setBrowserFilter([]);
        setOSFilter([]);
    };

    const handleObjectChange = (event) => {
        setSearchName(event.target.value);
    };

    const handleClickOpen = (event) => {
        setDateRange(true);
    };

    const handleClickClose = (event) => {
        setDateRange(false);
    };

    const handleOpenDialog = (event) => {
        setOpenDialog(true);
    };

    const handleCloseDialog = async(opt) => {
        if (opt === 1) {
            if (searchName === "") {
                setErrorMessage("Please enter the Search Name");
            }
            else {
                try {
                    await API.graphql(graphqlOperation(mutations.createSavedSearch, { input: { userName: state.user.attributes.email, searchNameTag: searchName, searchCriteria: searchCriteria }}))
                    .then((data) => {
                        var searchUser = {}
                        searchUser['userName'] = { eq: state.user.attributes.email }
                        API.graphql(graphqlOperation(queries.listUserSubscriptions, { filter: searchUser }))
                        .then((data) => {
                            if(data.data.listUserSubscriptions.items.length==0){
                                API.graphql(graphqlOperation(mutations.createUserSubscription, { input: { userName: state.user.attributes.email }}))
                                .then((data) => {
                                    console.log(data,"added user");
                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    setErrorMessage("");
                    setOpenDialog(false);
                } catch(err) {
                    setErrorMessage(err);
                }
            }
        }
        else {
            setOpenDialog(false);
        }
    };

    useEffect(() => {
        getFeedbackFromSearch();
    }, [ sourceFilter, areaFilter, accountTypeFilter, dateRangeFilter, sentimentFilter, subAreaFilter, browserFilter, osFilter ] );

    useEffect(() => {
        Analytics.record({
            name: 'The Loop Trends Page visit',
            attributes: {
                username: state.user.username
            }
        });
    }, []);

    return (
        <>
            {
                err
                ?   <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        { err }
                    </Alert>
                :  null 
            }

            <h2 className="page-title">{pageTitle}</h2>

            <div className={classes.root}>

                <Grid container spacing={1}>

                    <Grid item xs={2}>

                        <FormControl className={classes.formControl}>

                            <InputLabel htmlFor="source-native-simple">Source</InputLabel>
                            <Select
                                native
                                displayEmpty
                                className={classes.selectEmpty}
                                value={sourceFilter}
                                onChange={handleSourceChange}
                                inputProps={{
                                    name: 'source',
                                    id: 'source-native-simple',
                                }}
                            >
                                <option aria-label="None" value="" />
                                {sourceList.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Select>
                            
                        </FormControl>

                    </Grid>

                    <Grid item xs={2}>

                        <FormControl className={classes.formControl}>

                            <InputLabel htmlFor="account-type-native-simple">Account Type</InputLabel>
                            <Select
                                native
                                displayEmpty
                                className={classes.selectEmpty}
                                value={accountTypeFilter}
                                onChange={handleAccountTypeChange}
                                inputProps={{
                                    name: 'account_type',
                                    id: 'account-type-native-simple',
                                }}
                            >
                                <option aria-label="None" value="" />
                                {accountTypeList.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.value}
                                    </option>
                                ))}
                            </Select>

                        </FormControl>

                    </Grid>

                    <Grid item xs={2}>

                        <FormControl className={classes.formControl}>

                            <InputLabel htmlFor="sentiment-native-simple">Sentiment</InputLabel>
                            <Select
                                native
                                displayEmpty
                                className={classes.selectEmpty}
                                value={sentimentFilter}
                                onChange={handleSentimentChange}
                                inputProps={{
                                    name: 'sentiment',
                                    id: 'sentiment-native-simple',
                                }}
                            >
                                <option aria-label="None" value="" />
                                {sentimentList.map((option) => (
                                    <option key={option} value={option}>
                                        {capitalize(option)}
                                    </option>
                                ))}
                            </Select>

                        </FormControl>

                    </Grid>

                    <Grid item xs={2}>

                        <FormControl className={classes.formControl}>

                            <InputLabel id="area-mutiple-checkbox-label">Area</InputLabel>
                            <Select
                                labelId="area-mutiple-checkbox-label"
                                id="area-mutiple-checkbox"
                                multiple
                                value={areaFilter}
                                onChange={handleAreaChange}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {areaList.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    <Checkbox color="primary" checked={areaFilter.indexOf(option.value) > -1} />
                                    <ListItemText primary={option.label} />
                                </MenuItem>
                            ))}
                            </Select>
                            
                        </FormControl>

                    </Grid>

                    <Grid item xs={2}>

                        <FormControl className={classes.formControl}>

                            <InputLabel id="sub-area-mutiple-checkbox-label">Sub Area</InputLabel>
                            <Select
                                labelId="sub-area-mutiple-checkbox-label"
                                id="sub-area-mutiple-checkbox"
                                multiple
                                value={subAreaFilter}
                                onChange={handleSubAreaChange}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                            {subAreaList.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    <Checkbox color="primary" checked={subAreaFilter.indexOf(option.value) > -1} />
                                    <ListItemText primary={option.label} />
                                </MenuItem>
                            ))}
                            </Select>

                        </FormControl>

                    </Grid>

                    <Grid item xs={2}>

                        <FormControl className={classes.formControl}>

                            <InputLabel id="browser-mutiple-checkbox-label">Browser</InputLabel>
                            <Select
                                labelId="browser-mutiple-checkbox-label"
                                id="browser-mutiple-checkbox"
                                multiple
                                value={browserFilter}
                                onChange={handleBrowserChange}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                            {browserList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    <Checkbox color="primary" checked={browserFilter.indexOf(option) > -1} />
                                    <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                            </Select>

                        </FormControl>

                    </Grid>

                    <Grid item xs={2}>

                        <FormControl className={classes.formControls}>
                            <InputLabel id="os-mutiple-checkbox-label">Operating System</InputLabel>
                            <Select
                                labelId="os-mutiple-checkbox-label"
                                id="os-mutiple-checkbox"
                                multiple
                                value={osFilter}
                                onChange={handleOSChange}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                            {operatingSystemList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    <Checkbox color="primary" checked={osFilter.indexOf(option) > -1} />
                                    <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item xs={2}>
                        <p>Start Date: {showStartDate}</p>
                    </Grid>

                    <Grid item xs={2}>
                        <p>End Date: {showEndDate}</p>
                    </Grid>

                    <Grid item xs={2}>

                        <Button className={classes.button} variant="contained" color="default" onClick={handleClickOpen}>Edit Date Range</Button>
                        <Dialog classes={{ paper: classes.paper}} onClose={handleClickClose} open={dateRange}>
                            <DialogContent>
                                <Paper>
                                    <DateRangePicker
                                        onChange={item => setDateRangeFilter([item.selection])}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        ranges={dateRangeFilter}
                                        direction="horizontal"
                                        minDate={addYears(new Date(), -2)}
                                        maxDate={new Date()}
                                    />
                                </Paper>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={handleClickClose} color="primary">
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </Grid>

                    <Grid item xs={1}>
                        <Button className={classes.button} variant="contained" onClick={handleResetClick}>Reset</Button>
                    </Grid>

                    <Grid item xs={2}>

                        <Button className={classes.button} variant="contained" color="default" onClick={handleOpenDialog}>Save Search</Button>
                        <Dialog classes={{ paper: classes.paper}} onClose={handleCloseDialog} open={openDialog}>
                            <DialogContent>
                                <List>
                                <ListItem>
                                    <TextField
                                        id="SearchName"
                                        label="Enter Search Name"
                                        type="text"
                                        value={searchName}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleObjectChange}
                                    />   
                                </ListItem>
                                <DialogContentText style={{color: 'red'}}>
                                    {errorMessage}
                                </DialogContentText>
                                <ListItem>
                                    Keywords: {label}
                                </ListItem>
                                <ListItem>
                                    Source: {sourceFilter }
                                </ListItem>
                                <ListItem>
                                    Area: { areaFilter }
                                </ListItem>
                                <ListItem>
                                    Account Type: { accountTypeFilter }
                                </ListItem>
                                <ListItem>
                                    Sentiment: { sentimentFilter }
                                </ListItem>
                                <ListItem>
                                    Sub Area: {subAreaFilter }
                                </ListItem>
                                <ListItem>
                                    Browser: {browserFilter }
                                </ListItem>
                                <ListItem>
                                    Operating System: {osFilter }
                                </ListItem>
                            </List>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleCloseDialog(0)} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={() => handleCloseDialog(1)} color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>

                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}> 
                        <Input className={classes.searchbar}
                            type='text'
                            placeholder='Search for feedback'
                            icon='search'
                            iconposition='left'
                            name='label'
                            value={label}
                            onChange={(e) => { setLabel(e.target.value); setSearched(false); } }
                            onKeyDown={handleKeyDown}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                                }
                        />
                    </Paper>
                </Grid>

                <div className={classes.results}>                         
                {
                    hasResults
                    ? 
                    <Grid container spacing={3}>
                        <Grid item xs={7}>
                            {/* <Line data={chartData}/> */}
                        </Grid>
                        <Grid item xs={7}>
                            {/* <Bar data={barChartData}/> */}
                        </Grid>
                    </Grid>
                    : <NoResults />
                }
                </div>

            </div>


        </>
    );

}

export default TheLoopTrendsPage;