import React from "react";
import moment from "moment";
import "./ODPStatusCard.css"
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import AutorenewSharp from '@material-ui/icons/AutorenewSharp';
import WarningSharpIcon from '@material-ui/icons/WarningSharp';
import { ODP_STATUS_SETTINGS } from "../../../utils/constant";

function StatusCard(props)  {

    function truncate(str, n) {
        return (str.length > n) ? str.substr(0, n-1) + ' ...' : str;
    };

    const {cardDetails} = props;
    return (
        <>
            {cardDetails && cardDetails.key?(
                <>
                <ListItem alignItems="flex-start" className="list-item">
                    <ListItemAvatar>

                        {
                         cardDetails.messageType && cardDetails.messageType.toLowerCase() === 'error'?(<ErrorSharpIcon className={ODP_STATUS_SETTINGS.BAD} />):
                         cardDetails.messageType && cardDetails.messageType.toLowerCase() === 'warning'?(<WarningSharpIcon className={ODP_STATUS_SETTINGS.NETURAL} />):
                         cardDetails.status && cardDetails.status.toLowerCase() === 'completed'?(<CheckSharpIcon className={ODP_STATUS_SETTINGS.GOOD} />):
                         <AutorenewSharp className={ODP_STATUS_SETTINGS.NEUTRAL} />
                        }

                    </ListItemAvatar>
                    <ListItemText
                    primary={
                        <React.Fragment>
                            {cardDetails.statuskey}
                            <Typography
                                component="span"
                                variant="body2"
                                className="inline"
                                color="textPrimary"
                            >
                                {cardDetails.createdAt ? moment(new Date(cardDetails.createdAt)).format(ODP_STATUS_SETTINGS.DATE_FORMAT) : null}
                            </Typography>
                        </React.Fragment>
                    }
                    secondary={truncate(cardDetails.key.concat(' - ', cardDetails.message), 250) || null}
                    // secondary={
                    //     <React.Fragment>
                    //         <div style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: '80vw'}}>
                    //             {cardDetails.statuskey}
                    //             <Typography
                    //                 component="span"
                    //                 variant="body2"
                    //                 className="inline"
                    //                 color="textPrimary"
                    //             >
                    //                 {cardDetails.key.concat(' - ', cardDetails.message) || null}
                    //             </Typography>
                    //         </div>
                    //     </React.Fragment>
                    // }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
                </>
            ):""}
        </>
    )

}

export default StatusCard;