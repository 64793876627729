import React, { useState } from "react";
import moment from "moment";
import "./LoopFeedbackCard.css"
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import AutorenewSharp from '@material-ui/icons/AutorenewSharp';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded';
import ThumbsUpDownRoundedIcon from '@material-ui/icons/ThumbsUpDownRounded';
import WarningSharpIcon from '@material-ui/icons/WarningSharp';
import { ODP_STATUS_SETTINGS } from "../../../utils/constant";
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import EditIcon from '@material-ui/icons/Edit';
import ChatIcon from '@material-ui/icons/Chat';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import SalesforceLogo from '../../../img/salesforce-logo.png';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    }
  }));

function StatusCard(props)  {
    
    const classes = useStyles();
    const {cardDetails} = props;
    const caseID = cardDetails.recordid.substring(2);
    const [open, setOpen] = useState(props.expand);
    
    const handleClick = () => {
        setOpen(!open);
    };

    function truncate(str, n) {
        return (str.length > n) ? str.substr(0, n-1) + ' ...' : str;
    };

    function commentCount(c) {
        var numComments = c.comments.items.length;
        var showNumComments = numComments.toString();
        if (numComments > 10)   {
            showNumComments = "10+";
        }
        return(
            <>
                { numComments > 0 ? (
                    <Badge badgeContent={ showNumComments } color="primary">
                        <ChatIcon fontSize='small'/>
                    </Badge>
                ) : null }
            </>
        );
    }

    return (
        <>
            {cardDetails && cardDetails.recordid?(
                <>
                
                {/* <ListItem alignItems="flex-start" className="list-item" onClick={ () => props.handleOpen(cardDetails) }> */}
                <ListItem alignItems="flex-start" className="list-item">
                         
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >                                
                            <Grid item xs={12}>

                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <h4>{truncate((cardDetails.subject), 200)}</h4>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className="inline"
                                                color="textPrimary"
                                            >
                                                <p>{cardDetails.recordid}</p>
                                                <p>{cardDetails.created_date ? moment(cardDetails.created_date).tz('America/Los_Angeles').format(ODP_STATUS_SETTINGS.DATE_FORMAT) : null}</p>
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    secondary= {
                                        <React.Fragment>
                                            <div style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                                                <Typography>
                                                    <Grid item xs={11}>
                                                        {open ? 
                                                            <>
                                                                {truncate((cardDetails.description), 500)}
                                                                <List className={classes.commentList}>
                                                                {
                                                                    cardDetails.comments.items.map(comment=>{
                                                                        return <ListItem key={comment.id}><ListItemText>{moment(comment.createdAt).tz('America/Los_Angeles').format(ODP_STATUS_SETTINGS.DATE_FORMAT)}: {comment.owner} - {comment.content}</ListItemText></ListItem>
                                                                    })
                                                                }
                                                                </List>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={5}>
                                                                        {cardDetails.origin ?
                                                                            <Typography>
                                                                                <b>Source:</b> {/Email/.test(cardDetails.origin)?"Email":cardDetails.origin}
                                                                            </Typography>
                                                                        : ""}
                                                                        {cardDetails.account_type ?
                                                                            <Typography>
                                                                                <b>Account Type:</b> {cardDetails.account_type}
                                                                            </Typography>
                                                                        : ""}
                                                                        {cardDetails.label ?
                                                                            <Typography>
                                                                                <b>Label:</b> {cardDetails.label}
                                                                            </Typography>
                                                                        : ""}
                                                                        {cardDetails.star_rating ?
                                                                            <Typography>
                                                                                <b>Rating:</b> {cardDetails.star_rating}
                                                                            </Typography>
                                                                        : ""}
                                                                        {cardDetails.role ?
                                                                        <Typography>
                                                                            <b>Role:</b> {cardDetails.role}
                                                                        </Typography>
                                                                        :""}
                                                                        {cardDetails.area ?
                                                                            <Typography>
                                                                                <b>Area:</b> {cardDetails.area}
                                                                            </Typography>
                                                                        : ""}
                                                                        {cardDetails.sub_area ?
                                                                            <Typography>
                                                                                <b>Sub Area:</b> {cardDetails.sub_area}
                                                                            </Typography>
                                                                        : ""}
                                                                        {cardDetails.browser ?
                                                                        <Typography>
                                                                            <b>Browser:</b> {cardDetails.browser}
                                                                        </Typography>
                                                                        : ""}
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        {cardDetails.operating_system ?
                                                                            <Typography>
                                                                                <b>Operating System:</b> {cardDetails.operating_system}
                                                                            </Typography>
                                                                        : ""}
                                                                        {cardDetails.user_agent ?
                                                                            <Typography>
                                                                                <b>User Agent:</b> {truncate((cardDetails.user_agent), 100)}
                                                                            </Typography>
                                                                        : ""}
                                                                        {cardDetails.research_notes ?
                                                                            <Typography>
                                                                                <b>Research Notes:</b> {cardDetails.research_notes}
                                                                            </Typography>
                                                                        : ""}
                                                                        {cardDetails.current_url ?
                                                                            <Typography>
                                                                                <b>Current URL:</b> {/https/.test(cardDetails.current_url) ? (/access_token/.test(cardDetails.current_url) ? truncate((cardDetails.current_url), 50) : truncate((cardDetails.current_url), 100)) : ""}
                                                                            </Typography>
                                                                        : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                            : truncate(cardDetails.description, 500)
                                                        }
                                                    </Grid>
                                                </Typography>
                                            </div>
                                        </React.Fragment>
                                    }
                                />
                            </Grid>

                            <Grid item xs={2}>
                                
                                    <ButtonGroup
                                        orientation="horizontal"
                                        color="primary"
                                        aria-label="vertical contained primary button group"
                                        variant="text"
                                        size="small"
                                    >
                                        <Button disabled style={{color: 'black'}}>
                                            { commentCount(cardDetails) }
                                        </Button>

                                        <Button disabled>
                                            {
                                                cardDetails.Sentiment && cardDetails.Sentiment.toLowerCase() === 'positive'?(<ThumbUpRoundedIcon className={ODP_STATUS_SETTINGS.GOOD} />):
                                                cardDetails.Sentiment && cardDetails.Sentiment.toLowerCase() === 'negative'?(<ThumbDownRoundedIcon className={ODP_STATUS_SETTINGS.BAD} />):
                                                <ThumbsUpDownRoundedIcon className={ODP_STATUS_SETTINGS.NEUTRAL} />
                                            }
                                        </Button>

                                        <Tooltip title="Edit Feedback">
                                            <Button>
                                                <Link
                                                    to={{
                                                        pathname: `search/${cardDetails.recordid}`,
                                                        state: { feedback: props.feedback, criteria: props.criteria, path: props.path }
                                                    }}
                                                    style={{color: 'black',marginTop: '2px'}}
                                                >
                                                    <EditIcon fontSize="small"/>
                                                </Link>
                                            </Button>
                                        </Tooltip>

                                        <Tooltip title="Copy Feedback URL">
                                            <Button onClick={() => {navigator.clipboard.writeText(window.location.protocol + "//" + window.location.host + "/theloop/feedback/" + cardDetails.recordid)}}>📋</Button>
                                        </Tooltip>

                                        <Tooltip title="Open case in salesforce">
                                            <Button onClick={()=>{ window.open(`https://moveinc--dev.lightning.force.com/lightning/r/Case/${caseID}/view`)}}>
                                                <img src={SalesforceLogo} alt="Logo" width="30" height="25"/>
                                            </Button>
                                        </Tooltip>

                                        <Tooltip title="Expand Feedback">
                                            <Button onClick={ () => handleClick() }>
                                                {open ? <ExpandLess /> : <ExpandMore />}
                                            </Button>
                                        </Tooltip>

                                    </ButtonGroup>

                            </Grid>
                        </Grid>

                </ListItem>


                {/* <Divider variant="inset" component="li" /> */}
                </>
            ):""}
        </>
    )

}

export default StatusCard;