import React, { useEffect, useState, useRef } from 'react'
import { API, Analytics } from 'aws-amplify';
import { useTable, useExpanded } from 'react-table'
import { ExpandTable, TableStyle } from '../components/TableUtility'
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
      fontFamily: 'Arial',
  },
}));

function ODPStatusPage()  {

    const classes = useStyles();
    const [jobStatus, setJobStatus] = useState([]);
    const [jobAsOf, setJobAsOf] = useState()
    const [selectedRow, setSelectedRow] = React.useState({})
    const [verbose, setVerbose] = useState(false);
    
    useEffect(() => {
        Analytics.record({ name: 'ODP Status Page visit' });
        getData();
    }, []);

    async function getData(verbose=null) {
      var path = '/jobstatus'
      if (verbose !== null) {
        path = path+`?verbose=${verbose}`
      }
      // console.log(path);
      const jobStatus = await API.get('esdatahubhomeapi', path);
      // console.log(jobStatus.status);
      setJobAsOf(jobStatus.status.AsOf)
      setJobStatus(jobStatus.status.Results);
    }

    var moment = require('moment'); 

    const columns = React.useMemo(
        () => [
          {
            // Make an expander cell
            Header: () => null, // No header
            id: 'expander', // It needs an ID
            Cell: ({ row }) => (
              // Use Cell to render an expander for each row.
              // We can use the getToggleRowExpandedProps prop-getter
              // to build the expander.
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? '👇' : '👉'}
              </span>
            ),
          },
          {
            Header: 'Job',
            columns: [
              {
                Header: 'Job',
                accessor: 'jobDesc',
              },
              {
                Header: 'Last Run',
                accessor: d => {
                  if (d.lastScheduledRunStart === '') {
                    return ''; 
                  } else {
                    return moment(d.lastScheduledRunStart).local().format("YYYY-M-DD hh:mm");
                  }
                }
              }, 
              {
                Header: 'Last Run Status',
                accessor: 'lastRunStatus',
              },            
              {
                Header: 'Next Run',
                accessor: d => {
                  if (d.nextScheduledRunStart === '')  {
                    return '';
                  } else {
                    return moment(d.nextScheduledRunStart).local().format("YYYY-M-DD hh:mm");
                  }
                }
              },
            ]
          },
          { 
              Header: 'Object',
              columns: [
              {
                Header: 'Source',
                accessor: 'Source',
              },
              {
                Header: 'Object',
                accessor: 'Object',
              },              {
                Header: 'Target',
                accessor: 'TargetDesc',
              },              
              {
                Header: 'Target DB',
                accessor: 'DestinationDatabase',
              }, 
              {
                Header: 'Table',
                accessor: 'DestinationTable',
              }
            //   ,    
            //   {
            //     Header: 'Last Partition',
            //     accessor: 'LastPartition',
            //   },
            //   {
            //     Header: 'Status',
            //     accessor: 'Status',
            //   }
            ],
          },
        ],
        []
      )

      // Create a function that will render our row sub components
      const renderRowSubComponent = React.useCallback(
        ({ row }) => (
          <>
            <pre
              style={{
                fontSize: '10px',
              }}
            >
              <code>{JSON.stringify({ values: row.original }, null, 2)}</code>

              {/* <br />  
              Category: { row.values.category }<br />
              Description: { row.values.description }<br />

              <br />
              Comments: <br /><br />

              { row.original.comments.items.sort(function(a,b) {
                  if (a.createdAt < b.createdAt) {
                    return 1;
                  }
                  else if (a.createdAt > b.createdAt) {
                    return -1;
                  }
                  else if (a.id < b.id) {
                    return 1;
                  }
                  else {
                    return -1;
                  }
                }
                ).map(c => ( 
                  <span key={c.id}>{c.createdAt} { c.owner }: {c.message}<br /></span> 
                )) 
              } */}

            </pre>
          </>
        ),
        []
      )

      // const initialState = {
      //   hiddenColumns: ['source']
      // }

      // const defaultSorted = 
      //   [
      //     { id: 'nextScheduledRunStart', desc: false },
      //     { id: 'jobName', desc: false },
      //   ]

      function handleVerboseCheck(event) {
        console.log('handleVerbose');
        console.log(event.target.checked);
        setVerbose(event.target.checked);
        setJobStatus([]);
        getData(event.target.checked);
      }

    return(
        <div className={classes.root}>
            <h2>System Status</h2>

            <h4>Show Details:
               <Checkbox checked={verbose} onChange={checked => handleVerboseCheck(checked) } />
            </h4>
            <br />

            <p>As Of: {jobAsOf} </p>
            <TableStyle>
                <ExpandTable 
                  columns={columns} 
                  data={jobStatus} 
                  renderRowSubComponent={renderRowSubComponent} 
                  // defaultSorted={defaultSorted}
                  // initialState={initialState}
                />
            </TableStyle>

            <div>
            {jobStatus.length > 0 ? 
              <p></p>
            : <p>Loading ...</p>
            }
            </div>



        </div>
    )
}

export default ODPStatusPage