import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext, GApageView } from "../App";
import Logo from "../img/TheLoop.png";
import Play from "../img/Play.png";

const useStyles = makeStyles({
    centertext: {
        textAlign: 'center',
        fontFamily: 'Arial'
    },
    text: {
        fontFamily: 'Arial',
        textAlign: 'justify'
    },
  });

function TrainingAndFeatures()  {

    const { state } = React.useContext(AuthContext);
    const classes = useStyles();

    useEffect(() => {
        GApageView('Training and features', window.location.protocol + "//" + window.location.host + "/theloop/training", state.user.username)
    }, []);

    return (
        <>
            <Grid item xs={12}>
                <img src={Logo} alt="The Loop" width="350" height="70"/>
            </Grid>

            <Grid container justify="center" spacing={10} className={classes.centertext}>

                <Grid item xs={5} className={classes.text}>
                    <h2>What’s New?</h2>
                    <p>Star Ratings are now searchable when selecting App Stores as source</p>
                    <p>Internal Comments have been added to feedback details so you can see actions taken by customer care</p>
                </Grid>

                <Grid item xs={5}className={classes.text}>
                    <h2>Coming Soon</h2>
                    <p>Trend Reporting - basic reporting will be available to easily identify trends in feedback received</p>
                    <p>Sentiment and Data AI for auto tagging and categorizing feedback</p>
                    <p>Google App Version will be displayed on app store feedback details</p>
                </Grid>

            </Grid>

            <Grid container justify="center" spacing={10} className={classes.centertext}>

                <Grid item xs={5} className={classes.text}>
                    <h2>Training </h2>
                    <p><img src={Play} alt="Play" width="30" height="30" align="center"/><a href="https://vimeo.com/646225565" target="_blank">The Loop - Tutorial</a> - Learn how to search, save, and subscribe to feedback reports all within the Loop!</p>
                    <p><img src={Play} alt="Play" width="30" height="30" align="center"/><a href="https://vimeo.com/693697026" target="_blank">The Loop Product Feedback Submission Tutorial</a> - Learn how to submit product feedback directly in the Loop!</p>
                    <p>Request Training - Would you like to hold a training session for your team? <a href="https://docs.google.com/forms/d/e/1FAIpQLSd-wWuOp--3eKBvJmaeV91F932mqUq9gH86OFN8HvOf8e1S2g/viewform" target="_blank">Request one here!</a></p>
                </Grid>

                <Grid item xs={5} className={classes.text}>
                    <h2>Resources</h2>
                    <p>The Loop Slack Channel - Have feedback or questions? You can ask the experts in #Theloop</p>
                    <p>Email Feedback to <a href="mailto:loopfeedback@realtor.com" target="_blank">loopfeedback@realtor.com</a></p>
                    <p><a href="https://realtor.workplace.com/groups/465001835570923" target="_blank">Visit The Loop on Workplace</a> for updates on new features and learn about how users have utilized feedback from the Loop to help shape our products and address user concerns!</p>
                </Grid>
                  
            </Grid>
        </>
    )

}

export default TrainingAndFeatures;