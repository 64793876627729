import React, { useEffect, useState } from "react"; 
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { AuthContext, GApageView } from "../App";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme)=>({
    table: {
      minWidth: 650,
    },
    paper: { 
       minWidth: "65%" 
    },
    form: {
        minWidth: '25%',
    },
    form2: {
        minWidth: '100%',
    },
    button: {
        align: 'right',
        color: '#FFFFFF',
        backgroundColor: '#d92228',
        '&:hover': {
            backgroundColor: '#a51d32',
        },
        fontFamily: 'Arial',
        fontWeight: 'bold'
    },
    formControl: {
        margin: theme.spacing(1),
        width: '15%',
    },
    formControls: {
        margin: theme.spacing(1),
        width: '20%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    listStyle: {
        listStyleType: "none", 
        padding: "0",
        margin: "0" 
    },
    formWidth: {
        margin: theme.spacing(1),
        width: "80px",
    },
  }));


const status = [
    { value: 'true', label: 'Subscribe' },
    { value: 'false', label: 'Unsubscribe' },
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth:"auto"
      },
    },
};

function MySearchPage()  {

    const { state } = React.useContext(AuthContext);
    const [initialized, setInitialized] = useState(0);
    const [pages, setPages] = useState([])
    const [open, setOpen] = React.useState(false);
    const [editPage, setEditPage] = useState("");
    const [searchNameTag, setsearchNameTag] = useState("");
    const [subscribe, setSubscribe] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [sourceFilter, setSourceFilter] = useState("");
    const [areaFilter, setAreaFilter] = useState([]);
    const [sentimentFilter, setSentimentFilter] = useState("");
    const [accountTypeFilter, setAccountTypeFilter] = useState("");
    const [subAreaFilter, setSubAreaFilter] = useState([]);
    const [browserFilter, setBrowserFilter] = useState([]);
    const [osFilter, setOSFilter] = useState([]);
    const [roleFilter, setRoleFilter] = useState([]);
    const [ratingFilter, setRatingFilter] = useState([]);
    const [labelFilter, setLabelFilter] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [label, setLabel] = useState('')
    const [sourceList, setSourceList] = useState("");
    const [newArea, setNewArea] = useState({});
    const [areaList, setAreaList] = useState([]);
    const [sentimentList, setSentimentList] = useState("");
    const [accountTypeList, setAccountTypeList] = useState("");
    const [roleList, setRoleList] = useState([]);
    const [subAreaList, setSubAreaList] = useState([]);
    const [browserList, setBrowserList] = useState([]);
    const [operatingSystemList, setOSList] = useState([]);
    const [ratingList, setRatingList] = useState([]);
    const [labelList, setLabelList] = useState([]);

    const classes = useStyles();

    useEffect(() => {
        GApageView('My Search', window.location.protocol + "//" + window.location.host + "/theloop/mysearch", state.user.username)
        Analytics.record({
            name: 'The Loop - My Search Page visit',
            attributes: {
                username: state.user.username
            }
        });
        getdata();
        API.graphql(graphqlOperation(queries.listFilterValuess))
        .then((data) => {
            // const areaData = JSON.parse(data.data.listFilterValuess.items[0].area);
            // const areaArray = [];
            // for (var key in areaData) {
            //     areaArray.push(key)
            // }
            try {
                setSourceList(data.data.listFilterValuess.items[0].source)
                setAccountTypeList(data.data.listFilterValuess.items[0].account_type)
                setRoleList(data.data.listFilterValuess.items[0].role)
                setSentimentList(data.data.listFilterValuess.items[0].Sentiment)
                // setAreaList(areaArray)
                setBrowserList(data.data.listFilterValuess.items[0].browser)
                setOSList(data.data.listFilterValuess.items[0].operating_system)   
                setRatingList(data.data.listFilterValuess.items[0].star_rating)
                setLabelList(data.data.listFilterValuess.items[0].label)  
                // setNewArea(areaData) 
            } catch(err) {
                console.log(err);
            }
        })
        .catch((error) => {
            console.log(error);
        })

        API.graphql(graphqlOperation(queries.listAreas))
        .then((data) => {
            const areaData = data.data.listAreas.items;
            const areaArray = [];
            const areaSubArray = {};
            for (var i in areaData) {
                areaArray.push(areaData[i].area)
                areaSubArray[areaData[i].area]=[];
                for (var j in areaData[i].sub_area.items) {
                    areaSubArray[areaData[i].area].push(areaData[i].sub_area.items[j].sub_area)
                }
            }
            try {
                setAreaList(areaArray) 
                setNewArea(areaSubArray)
                
            } catch(err) {
                console.log(err);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }, []);

    function getdata() {
        var searchUser = {}
        searchUser['userName'] = { eq: state.user.attributes.email }
        API.graphql(graphqlOperation( queries.listSavedSearchs, {filter: searchUser}))
        .then((data) => {
            if(data.data.listSavedSearchs.items.length === 0){
                console.log(data.data.listSavedSearchs.items,"required data")
                API.graphql(graphqlOperation(queries.listUserSubscriptions, {filter: searchUser }))
                .then((data) => {
                    if(data.data.listUserSubscriptions.items.length !== 0){
                        console.log(data.data.listUserSubscriptions.items,"required item")
                        console.log(data.data.listUserSubscriptions.items[0].id,"required id")
                        API.graphql(graphqlOperation(mutations.deleteUserSubscription, {input: { id: data.data.listUserSubscriptions.items[0].id }}))
                        .then((data) => {
                            console.log(data,"user deleted");
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
            }
            setPages(data.data.listSavedSearchs.items);
            setInitialized(1);
        })
        .catch((error) => {
            console.log(error);
            setInitialized(1);
        })
    }

    // function truncate(str, n) {
    //     return (str.length > n) ? str.substr(0, n-1) + ' ...' : str;
    // };

    const handleOpen = (page) => {
        setOpen(true);
        if (page.hasOwnProperty('id')) { 
            setEditPage(page.id);
            setsearchNameTag(page.searchNameTag);
            setSubscribe(page.subscribe);
            setSourceFilter(page.source);
            setAccountTypeFilter(page.account_type);
            setSentimentFilter(page.Sentiment);
            if (page.area !== "" && page.area !== null) {
                setAreaFilter(page.area.split(','))
            }
            if (page.sub_area !== "" && page.sub_area !== null) {
                setSubAreaFilter(page.sub_area.split(','))
            }
            if (page.browser !== "" && page.browser !== null) {
                setBrowserFilter(page.browser.split(','))
            }
            if (page.operating_system !== "" && page.operating_system !== null) {
                setOSFilter(page.operating_system.split(','))
            }
            if (page.role !== "" && page.role !== null) {
                setRoleFilter(page.role.split(','))
            }
            if (page.star_rating !== "" && page.star_rating !== null) {
                setRatingFilter(page.star_rating.split(','))
            }
            if (page.label !== "" && page.label !== null) {
                setLabelFilter(page.label.split(','))
            }
            setLabel(page.description)
        }
    };

    const handleClose = async(opt) => {
        if (opt === 1) {
            setDisabled(true);
            try {
                await API.graphql(graphqlOperation(mutations.updateSavedSearch, { input: { id: editPage, searchNameTag: searchNameTag, subscribe: subscribe, source: sourceFilter, account_type: accountTypeFilter, Sentiment: sentimentFilter, area: areaFilter.toString(), sub_area: subAreaFilter.toString(), browser: browserFilter.toString(), operating_system: osFilter.toString(), role: roleFilter.toString(), description: label, subject: label, origin: sourceFilter, star_rating: ratingFilter.toString(), label: labelFilter.toString() }}))
                setErrorMessage("");
                setDisabled(false);
                setOpen(false);
                getdata();
            } catch(err) {
                setErrorMessage(err.errors[0].message)
            }
        }
        else {
            setOpen(false);
            setErrorMessage("");
        }
    };

    const handleDelete = (page) => {
        setOpenDeleteModal(true);
        if (page.hasOwnProperty('id')) { 
            setEditPage(page.id);
            setsearchNameTag(page.searchNameTag);
        }
    };

    const handleConfirmDelete = async(opt) => {
        if (opt === 1) {
            setDisabled(true);
            try {
                await API.graphql(graphqlOperation(mutations.deleteSavedSearch, { input: { id: editPage } }));
                setDisabled(false);
                setOpenDeleteModal(false);
                getdata();
            } catch(err) {
                setErrorMessage(err.errors[0].message);
            }
        }
        else {
            setOpenDeleteModal(false);
            setErrorMessage("");
        }
    };

    const handleSearchNameTagChange = (event) => {
        setsearchNameTag(event.target.value);
    };

    const handleSubscriptionChange = (event) => {
        setSubscribe(event.target.value);
    };

    const handleSourceChange = (event) => {
        setSourceFilter(event.target.value);
    };

    const handleAreaChange = (event) => {
        const value = event.target.value;
        setAreaFilter(value)
        var newarray = [];
        for (const data of value) {
            newarray.push(newArea[data])
        }
        setSubAreaList(newarray.flat())
        setSubAreaFilter([])
    };

    const handleAccountTypeChange = (event) => {
        setAccountTypeFilter(event.target.value);
    };

    const handleLabelChange = (event) => {
        setLabelFilter(event.target.value);
    };

    const handleSentimentChange = (event) => {
        setSentimentFilter(event.target.value);
    };

    const handleSubAreaChange = (event) => {
        setSubAreaFilter(event.target.value);
    };

    const handleBrowserChange = (event) => {
        setBrowserFilter(event.target.value);
    };

    const handleOSChange = (event) => {
        setOSFilter(event.target.value);
    };

    const handleRoleChange = (event) => {
        setRoleFilter(event.target.value);
    };

    const handleRatingChange = (event) => {
        setRatingFilter(event.target.value);
    };

    const handleKeywordChange = (event) => {
        setLabel(event.target.value);
    };

    function capitalize(s) {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    }

    return (
        <>
            <h2 className="page-title">Saved Searches</h2>

            {initialized === 0 ? <CircularProgress /> : 
        
                <div className="HomePage">

                    <Grid align="right">
                        <Button className={classes.button} component={Link} to={`/theloop/search`}>Add New Searches</Button>
                    </Grid>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Search Name</TableCell>
                                    <TableCell>Search Criteria</TableCell>
                                    <TableCell>Subscription Status</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pages.map((page) => (
                                    <TableRow key={page.id}>
                                        <TableCell>
                                            {page.searchNameTag}
                                        </TableCell>
                                        <TableCell>
                                            <ul className={classes.listStyle}>
                                                <li>
                                                    Source: {page.source}
                                                </li>
                                                <li>
                                                    Account Type: {page.account_type}
                                                </li>
                                                <li>
                                                    Label: {page.label}
                                                </li>
                                                <li>
                                                    Role: {page.role}
                                                </li>
                                                <li>
                                                    Sentiment: {page.Sentiment}
                                                </li>
                                                <li>
                                                    Area: {page.area}
                                                </li>
                                                <li>
                                                    Sub Area: {page.sub_area}
                                                </li>
                                                <li>
                                                    Browser: {page.browser}
                                                </li>
                                                <li>
                                                    Operating System: {page.operating_system}
                                                </li>
                                                <li>
                                                    Rating: {page.star_rating}
                                                </li>
                                                <li>
                                                    Keywords: {page.description}
                                                </li>
                                            </ul>
                                        </TableCell>
                                        <TableCell>
                                            {page.subscribe ? "Subscribed" : "Not Subscribed"}
                                        </TableCell>
                                        <TableCell>     
                                            <Link
                                                to={{
                                                    pathname: `/theloop/search`,
                                                    state: { savedSource: page.source, savedAccountType: page.account_type, savedSentiment: page.Sentiment, savedArea: page.area, savedSubArea: page.sub_area, savedBrowser: page.browser, savedOperatingSystem: page.operating_system, savedDescription: page.description, savedSubject: page.subject, savedRole: page.role, savedLabel: page.label }
                                                }}
                                            >
                                                <IconButton>
                                                    <SearchIcon/> 
                                                </IconButton>
                                            </Link>
                                        </TableCell>
                                        <TableCell>          
                                            <IconButton onClick={() => handleOpen(page)}>
                                                <EditIcon /> 
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>          
                                            <IconButton onClick={() => handleDelete(page)}>
                                                <DeleteIcon /> 
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Dialog classes={{ paper: classes.paper}} open={open} onClose={handleClose}>
                        <DialogTitle>Edit Search Criteria</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{color: 'red'}}>
                                {errorMessage}
                            </DialogContentText>
                            <List>
                                <ListItem>
                                    <TextField
                                        label="Name Tag"
                                        value={searchNameTag}
                                        className={classes.form}
                                        onChange={handleSearchNameTagChange}
                                    >
                                    </TextField>
                                </ListItem>
                                <ListItem></ListItem>
                                <ListItem>
                                    Search Criteria:
                                </ListItem>
                                <ListItem>

                                { sourceList ?
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Source</InputLabel>
                                        <Select
                                            native
                                            displayEmpty
                                            className={classes.selectEmpty}
                                            value={sourceFilter}
                                            onChange={handleSourceChange}
                                        >
                                            <option aria-label="None" value="" />
                                            {sourceList.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                : "" }

                                { accountTypeList ?
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Account Type</InputLabel>
                                        <Select
                                            native
                                            displayEmpty
                                            className={classes.selectEmpty}
                                            value={accountTypeFilter}
                                            onChange={handleAccountTypeChange}
                                        >
                                            <option aria-label="None" value="" />
                                            {accountTypeList.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                : "" }

                                { labelList ?
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Label</InputLabel>
                                        <Select
                                            multiple
                                            value={labelFilter}
                                            onChange={handleLabelChange}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {labelList.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    <Checkbox color="primary" checked={labelFilter.indexOf(option) > -1} />
                                                    <ListItemText primary={option} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                : "" }

                                { roleList ?
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Role</InputLabel>
                                        <Select
                                            multiple
                                            value={roleFilter}
                                            onChange={handleRoleChange}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {roleList.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    <Checkbox color="primary" checked={roleFilter.indexOf(option) > -1} />
                                                    <ListItemText primary={option} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                : "" }

                                { sentimentList ?
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Sentiment</InputLabel>
                                        <Select
                                            native
                                            displayEmpty
                                            className={classes.selectEmpty}
                                            value={sentimentFilter}
                                            onChange={handleSentimentChange}
                                        >
                                            <option aria-label="None" value="" />
                                            {sentimentList.map((option) => (
                                                <option key={option} value={option}>
                                                    {capitalize(option)}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                : "" }

                                </ListItem>
                                <ListItem>

                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Area</InputLabel>
                                        <Select
                                            multiple
                                            value={areaFilter}
                                            onChange={handleAreaChange}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {areaList.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    <Checkbox color="primary" checked={areaFilter.indexOf(option) > -1} />
                                                    <ListItemText primary={option} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Sub Area</InputLabel>
                                        <Select
                                            multiple
                                            value={subAreaFilter}
                                            onChange={handleSubAreaChange}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {subAreaList.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    <Checkbox color="primary" checked={subAreaFilter.indexOf(option) > -1} />
                                                    <ListItemText primary={option} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                { browserList ? 
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Browser</InputLabel>
                                        <Select
                                            multiple
                                            value={browserFilter}
                                            onChange={handleBrowserChange}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {browserList.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    <Checkbox color="primary" checked={browserFilter.indexOf(option) > -1} />
                                                    <ListItemText primary={option} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                : "" }

                                { operatingSystemList ?
                                    <FormControl className={classes.formControls}>
                                        <InputLabel>Operating System</InputLabel>
                                        <Select
                                            multiple
                                            value={osFilter}
                                            onChange={handleOSChange}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {operatingSystemList.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    <Checkbox color="primary" checked={osFilter.indexOf(option) > -1} />
                                                    <ListItemText primary={option} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                : "" }

                                { ratingList && (sourceFilter === "Google Playstore" || sourceFilter === "ios Appstore" ) ?
                                    <FormControl className={classes.formWidth}>
                                        <InputLabel>Rating</InputLabel>
                                        <Select
                                            multiple
                                            value={ratingFilter}
                                            onChange={handleRatingChange}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {ratingList.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    <Checkbox color="primary" checked={ratingFilter.indexOf(option) > -1} />
                                                    <ListItemText primary={option} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                : "" }
                                
                                </ListItem>
                                <ListItem></ListItem>
                                <ListItem>
                                <TextField
                                        label="Keywords"
                                        value={label}
                                        className={classes.form2}
                                        onChange={handleKeywordChange}
                                    >
                                    </TextField>
                                </ListItem>
                                <ListItem></ListItem>
                                <ListItem></ListItem>
                                <ListItem>
                                    Subscription Status:
                                </ListItem>
                                <ListItem>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Subscription</InputLabel>
                                        <Select
                                            native
                                            displayEmpty
                                            value={subscribe}
                                            onChange={handleSubscriptionChange}
                                        >
                                            {status.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </ListItem>
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleClose(0)} color="primary">
                                Cancel
                            </Button>
                            <Button disabled={disabled} onClick={() => handleClose(1)} color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog classes={{ paper: classes.paper}} open={openDeleteModal} onClose={handleConfirmDelete}>
                        <DialogTitle>Are you sure you want to delete this search criteria: {searchNameTag}?</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{color: 'red'}}>
                                {errorMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleConfirmDelete(0)} color="primary">
                                Cancel
                            </Button>
                            <Button disabled={disabled} onClick={() => handleConfirmDelete(1)} color="primary">
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                    
                </div>          
            
            }
        </>
    )

}

export default MySearchPage;