import React, { useEffect, useState } from "react";
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    paper: { 
       minWidth: "65%" 
    },
  });

function AdminPage()  {

    const [initialized, setInitialized] = useState(0);
    const [pages, setPages] = useState([])
    const [open, setOpen] = React.useState(false);
    const [editMode, setEditMode] = useState({readOnly: false});
    const [editPage, setEditPage] = useState("");
    const [editConfig, setEditConfig] = useState("");
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        Analytics.record({ name: 'Admin Page visit' });
        getdata();
    }, []);

    function getdata() {
        API.graphql({ query: queries.listEsDatahubPageConfigs })
        .then((data) => {
            // console.log(data);
            setPages(data.data.listESDatahubPageConfigs.items);
            setInitialized(1);
        })
        .catch((error) => {
            console.log(error);
            setInitialized(1);
        })
    }

    const classes = useStyles();

    const handleOpen = (page) => {
        setOpen(true);
        if (page.hasOwnProperty('id')) { 
            setEditPage(page.id);
            setEditConfig(page.config);
            setEditMode({readOnly: true});
        } 
        else {
            setEditPage("");
            setEditConfig("{\"key\": \"value\"}");
            setEditMode({readOnly: false});
        }
    };

    const handleClose = async(opt) => {
        if (opt === 1) {
            if (editMode.readOnly === true)  {
                try {
                    await API.graphql(graphqlOperation(mutations.updateEsDatahubPageConfig, { input: {id: editPage, config: editConfig} }));
                    setOpen(false);
                    getdata();
                } catch(err) {
                    setErrorMessage(err.errors[0].message)
                }  
            }
            else {
                if (editPage === "")  {
                    setErrorMessage("Please enter the page name.")
                }
                else  {
                    try {
                        await API.graphql(graphqlOperation(mutations.createEsDatahubPageConfig, { input: {id: editPage, config: editConfig} }));
                        setOpen(false);
                        getdata();
                    } catch(err) {
                        setErrorMessage(err.errors[0].message)
                    }   
                }
            }
        }
        else {
            setOpen(false);
        }
    };

    const handlePageChange = (event) => {
        setEditPage(event.target.value);
    };

    const handleConfigChange = (event) => {
        setEditConfig(event.target.value);
    };

    return (
        <>
            <h2 className="page-title">Page Admin</h2>

            {initialized === 0 ? <CircularProgress /> : 

                <div className="HomePage">

                    <Dialog classes={{ paper: classes.paper}} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Edit Configuration</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Enter configuration JSON String
                            </DialogContentText>
                            <DialogContentText style={{color: 'red'}}>
                                {errorMessage}
                            </DialogContentText>
                            <TextField
                                defaultValue={editPage}
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Page"
                                type="text"
                                fullWidth
                                onChange={handlePageChange}
                                InputProps={editMode}
                            />
                            <TextField
                                id="filled-multiline-static"
                                label="Configuration"
                                multiline
                                rows={8}
                                defaultValue={editConfig}
                                variant="filled"
                                fullWidth
                                onChange={handleConfigChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleClose(0)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => handleClose(1)} color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <IconButton onClick={() => handleOpen({})}>
                        <AddIcon /> 
                    </IconButton>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Page</TableCell>
                                    <TableCell>Configuration</TableCell>
                                    <TableCell>Last Updated</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pages.map((page) => (
                                    <TableRow key={page.id}>
                                        <TableCell component="th" scope="row">
                                            {page.id}
                                        </TableCell>
                                        <TableCell>{page.config}</TableCell>
                                        <TableCell>{page.updatedAt}</TableCell>
                                        <TableCell>          
                                            <IconButton onClick={() => handleOpen(page)}>
                                                <EditIcon /> 
                                            </IconButton></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                </div>

                /* <div><pre>{JSON.stringify(config, null, 2) }</pre></div>   */            
            
            }
        </>
    )

}

export default AdminPage;