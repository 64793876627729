import React from "react";
import "./ODPStatusTable.css"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import moment from "moment";
import moment from "moment-timezone"
import { ODP_STATUS_SETTINGS } from "../../utils/constant";
// import { NavLink } from "react-router-dom";
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';

function ODPStatusTable(props)  {
    const status_list = props.statusTable || [];
    const status_sort = props.sort || [];

    function truncate(str, n) {
        return (str.length > n) ? str.substr(0, n-1) + ' ...' : str;
    };

    function formatDateTime(str) {

        // https://dockyard.com/blog/2020/02/14/you-probably-don-t-need-moment-js-anymore
        // let tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // let date = Intl.DateTimeFormat('en', { hour: 'numeric' }).format(new Date())
        // `${date} ${tzName}` // 2 PM America/Los_Angelas

        var dt = moment.tz(new Date(str), "GMT");
        str = moment(dt).format(ODP_STATUS_SETTINGS.DATE_FORMAT)
        return (str);
    }


    // lastMessageType = info, warning, or error
    // currentStatus should be starting, or completed 
    function getClassName(statusRecord) {
        const messageType = statusRecord['lastMessageType'];
        const status = statusRecord['currentStatus']
        if (messageType === 'error')  {
            return('badmessage');
        } else if (messageType === 'warning')  {
            return('neutralmessage');
        } else if (messageType === 'info')  {
            if (status === 'completed')   {
                return('goodmessage');
            } else {
                return('neutralmessage');
            }
        }
        return('neutralmessage');
    }

    function pad(n) {
        return n<10 ? '0'+n : n
    }

    function getRunTime(start, end) {
        var s = new Date(start);
        var e = new Date(end);
        if (s > e) {
            return('-');
        } else {
            var msec = e - s;
            var hh = Math.floor(msec / 1000 / 60 / 60);
            msec -= hh * 1000 * 60 * 60;
            var mm = Math.floor(msec / 1000 / 60);
            msec -= mm * 1000 * 60;
            var ss = Math.floor(msec / 1000);
            msec -= ss * 1000;
            return(pad(hh) + ':' + pad(mm) + ':' + pad(ss));
        }
    }


    return (
        <>
            <TableContainer component={Paper}>
                <Table className={`status-table`} size="small" aria-label="dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Object</TableCell>
                        <TableCell align="left">Current Status</TableCell>
                        <TableCell align="left">Last Start (UTC)</TableCell>
                        <TableCell align="left">Last Complete (UTC)</TableCell>
                        <TableCell align="left">Run Time (H:M:S)</TableCell>
                        <TableCell align="left">Last Message</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {status_sort.map((key) => (
                        <TableRow key={key}>
                            <TableCell className={getClassName(status_list[key])} component="th" scope="row">
                                <Button component={Link} to={`livestatus/${key}`}>
                                    <VisibilityIcon />
                                </Button>
                            </TableCell>
                            <TableCell className={getClassName(status_list[key])} component="th" scope="row">
                                {key}
                            </TableCell>
                            <TableCell className={getClassName(status_list[key])} align="left">{status_list[key]['currentStatus']}</TableCell>
                            <TableCell className={getClassName(status_list[key])} align="left">{formatDateTime(status_list[key]['lastStarted'])}</TableCell>
                            <TableCell className={getClassName(status_list[key])} align="left">{formatDateTime(status_list[key]['lastCompleted'])}</TableCell>
                            <TableCell className={getClassName(status_list[key])} align="left">{getRunTime(status_list[key]['lastStarted'], status_list[key]['lastCompleted'])}</TableCell>
                            <TableCell className={getClassName(status_list[key])} align="left">{truncate(status_list[key]['lastMessage'], 25)}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )

}

export default ODPStatusTable;