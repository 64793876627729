import { DashboardPage } from '../components/DashboardPage'

const pageID = 'LOOPPRODUCT'
const pageTitle = 'The Loop - Products'

function TheLoopProductPage()  {

    return (
        DashboardPage(pageID, pageTitle)
    );

}

export default TheLoopProductPage;