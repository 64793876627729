import React, { useEffect, useState } from "react"; 
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme)=>({
    table: {
      minWidth: 650,
    },
    paper: { 
       minWidth: "65%" 
    },
    form: {
        minWidth: '25%',
    },
    button: {
        align: 'right',
        color: '#FFFFFF',
        backgroundColor: '#d92228',
        '&:hover': {
            backgroundColor: '#a51d32',
        },
        fontFamily: 'Arial',
        fontWeight: 'bold'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        width: "150px",
    },
  }));

const filterList = [
    'Area',
    'Sub Area',
    'Source',
    'Account Type',
    'Sentiment',
    'Role',
    'Browser',
    'Operating System'
]

function LoopFilterPage()  {

    const [editMode, setEditMode] = useState({readOnly: false});
    const [editID, setEditID] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [areaList, setAreaList] = useState([])
    const [subList, setSubList] = useState([])
    const [areaID, setAreaID] = useState("")
    const [subareaID, setSubAreaID] = useState("")
    const [areaname, setEditArea] = useState("");
    const [sub_areaname, setEditSubArea] = useState("");
    const [areaEditModal, setAreaEditModal] = React.useState(false);
    const [subEditModal, setSubEditModal] = React.useState(false);
    const [areaDeleteModal, setAreaDeleteModal] = React.useState(false);
    const [subDeleteModal, setSubDeleteModal] = React.useState(false);
    const [disable, setDisable] = React.useState(false);
    const [filterType, setFilterType] = useState("Area");

    const classes = useStyles();

    useEffect(() => {
        Analytics.record({ name: 'Add Filter Page visit' });
        getdata();
    }, []);

    function getdata() {
        API.graphql({ query: queries.listAreas })
        .then((data) => {
            setAreaList(data.data.listAreas.items);
            setAreaID(data.data.listAreas.items.length+1)
        })
        .catch((error) => {
            console.log(error);
        })
        
        API.graphql({ query: queries.listSubareas })
        .then((data) => {
            setSubList(data.data.listSubareas.items);
            setSubAreaID(data.data.listSubareas.items.length+1)
        })
        .catch((error) => {
            console.log(error);
        })
    }

    function truncate(str, n) {
        return (str.length > n) ? str.substr(0, n-1) + ' ...' : str;
    };

    const handleArea = (page) => {
        setAreaEditModal(true);
        if (page.hasOwnProperty('id')) { 
            setEditID(page.id);
            setEditArea(page.area);
            setEditSubArea(page.sub_area);
            setEditMode({readOnly: true});
        } 
        else {
            setEditArea("");
            setEditMode({readOnly: false});
        }
    };

    const handleSub = (page) => {
        setSubEditModal(true);
        if (page.hasOwnProperty('subarea_id')) { 
            setEditID(page.id);
            setEditSubArea(page.sub_area);
            setEditMode({readOnly: true});
        } 
        else {
            setEditID(page.area_id);
            setEditSubArea("");
            setEditMode({readOnly: false});
        }
    };

    const handleEditArea = async(opt) => {
        if (opt === 1) {
            if (editMode.readOnly === true)  {
                if (areaname === "") {
                    setErrorMessage("Please edit the Area Name");
                }
                else {
                    try {
                        await API.graphql(graphqlOperation(mutations.updateArea, { input: { id: editID, area: areaname } }));
                        setErrorMessage("");
                        setAreaEditModal(false);
                        getdata();
                    } catch(err) {
                        setErrorMessage(err.errors[0].message)
                    }
                }
            }
            else {
                if (areaname === "") {
                    setErrorMessage("Please enter the Area Name");
                }
                else {
                    try {
                        await API.graphql(graphqlOperation(mutations.createArea, { input: { area_id: areaID, area: areaname } }));
                        setErrorMessage("");
                        setAreaEditModal(false);
                        getdata();
                    } catch(err) {
                        setErrorMessage(err.errors[0].message);
                    }
                }
            }
        }
        else {
            setAreaEditModal(false);
            setErrorMessage("");
        }
    };

    const handleEditSub = async(opt) => {
        if (opt === 1) {
            if (editMode.readOnly === true)  {
                if (sub_areaname === "") {
                    setErrorMessage("Please edit the Sub Area Name");
                }
                else {
                    try {
                        await API.graphql(graphqlOperation(mutations.updateSubarea, { input: { id: editID, sub_area:sub_areaname } }));
                        setErrorMessage("");
                        setSubEditModal(false);
                        getdata();
                    } catch(err) {
                        setErrorMessage(err.errors[0].message)
                    }
                }
            }
            else {
                if (sub_areaname === "") {
                    setErrorMessage("Please enter the Sub Area Name");
                }
                else {
                    try {
                        await API.graphql(graphqlOperation(mutations.createSubarea, { input: { subarea_id: subareaID, area_id: editID, sub_area: sub_areaname } }));
                        setErrorMessage("");
                        setSubEditModal(false);
                        getdata();
                    } catch(err) {
                        setErrorMessage(err.errors[0].message);
                    }
                }
            }
        }
        else {
            setSubEditModal(false);
            setErrorMessage("");
        }
    };

    const handleDelete = (page) => {
        if(filterType === "Area"){
            setAreaDeleteModal(true);
            if (page.hasOwnProperty('id')) { 
                setEditID(page.id);
                setEditArea(page.area);
                setEditSubArea(page.sub_area);
            } 
        }
        if(filterType === "Sub Area"){
            setSubDeleteModal(true);
            if (page.hasOwnProperty('id')) { 
                setEditID(page.id);
                setEditSubArea(page.sub_area);
            } 
        }
    };

    const handleDeleteArea = async(opt) => {
        if (opt === 1) {
            if(sub_areaname.items.length === 0){
                setDisable(true)
                try {
                    await API.graphql(graphqlOperation(mutations.deleteArea, {input:{id: editID }} ));
                    setAreaDeleteModal(false);
                    setDisable(false)
                    getdata();
                } catch(err) {
                    setErrorMessage(err.errors[0].message);
                    setDisable(false)
                }
            }
            if(sub_areaname.items.length > 0){
                setDisable(true)
                setErrorMessage("This Area contains sub area, Please delete all the sub area under this area to delete this area.");
            }
        }
        else {
            setAreaDeleteModal(false);
            setErrorMessage("");
            setDisable(false);
        }
    };

    const handleDeleteSub = async(opt) => {
        if (opt === 1) {
            setDisable(true);
            try {
                await API.graphql(graphqlOperation(mutations.deleteSubarea, { input: {id: editID }} ));
                setSubDeleteModal(false);
                setDisable(false);
                getdata();
            } catch(err) {
                setErrorMessage(err.errors[0].message);
                setDisable(false);
            }
        }
        else {
            setSubDeleteModal(false);
            setErrorMessage("");
            setDisable(false);
        }
    };

    const handleAreaChange = (event) => {
        setEditArea(event.target.value);
    };

    const handleSubAreaChange = (event) => {
        setEditSubArea(event.target.value);
    };

    const handleList = (subarea_list) => {
        const subareaArray = [];
        for (let i=0;i<subarea_list.items.length;i=i+1){
            subareaArray.push(subarea_list.items[i].sub_area+", ")
        }
        return subareaArray;
    };

    const handleFilterType = (event) => {
        setFilterType(event.target.value);
    };

    return (
        <>
            <h2 className="page-title">Loop Filters</h2>
        
                <div className="HomePage">
                <Grid container>
                    <Grid item xs={12} align="right">

                        <FormControl className={classes.formControl}>

                            <InputLabel>Select Filter Type</InputLabel>
                            <Select
                                native
                                displayEmpty
                                className={classes.selectEmpty}
                                value={filterType}
                                onChange={handleFilterType}
                            >
                                {filterList.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Select>

                        </FormControl>

                    </Grid>

                    <Grid item xs={10} align="center">
                        <h2>{filterType}</h2>
                    </Grid>

                </Grid>

                    {filterType === "Area" ? <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Area ID</TableCell>
                                    <TableCell>Area Name
                                        <Tooltip title="Add new area">
                                            <IconButton onClick={() => handleArea({})}>
                                                <AddIcon /> 
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>Sub Area</TableCell>
                                    <TableCell>Add Sub Area</TableCell>
                                    <TableCell>Edit Area</TableCell>
                                    <TableCell>Delete Area</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {areaList.map((page) => (
                                    <TableRow key={page.id}>
                                        <TableCell>
                                            {(page.area_id)}
                                        </TableCell>
                                        <TableCell>
                                            {truncate((page.area), 25)}
                                        </TableCell>
                                        <TableCell>
                                            {handleList(page.sub_area)}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleSub(page)}>
                                                <AddIcon /> 
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>          
                                            <IconButton onClick={() => handleArea(page)}>
                                                <EditIcon /> 
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>          
                                            <IconButton onClick={() => handleDelete(page)}>
                                                <DeleteIcon /> 
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> : ""}

                    {filterType==="Sub Area"?
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Area ID</TableCell>
                                    <TableCell>Sub Area ID</TableCell>
                                    <TableCell>Sub Area</TableCell>
                                    <TableCell>Edit Sub Area</TableCell>
                                    <TableCell>Delete Sub Area</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subList.map((page) => (
                                    <TableRow key={page.id}>
                                        <TableCell>
                                            {page.area_id}
                                        </TableCell>
                                        <TableCell>
                                            {page.subarea_id}
                                        </TableCell>
                                        <TableCell>
                                            {truncate((page.sub_area), 25)}
                                        </TableCell>
                                        <TableCell>          
                                            <IconButton onClick={() => handleSub(page)}>
                                                <EditIcon /> 
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>          
                                            <IconButton onClick={() => handleDelete(page)}>
                                                <DeleteIcon /> 
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :""}

                    <Dialog classes={{ paper: classes.paper}} open={areaEditModal} onClose={handleEditArea}>
                        <DialogTitle>Edit Area</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{color: 'red'}}>
                                {errorMessage}
                            </DialogContentText>
                            <List>
                                <ListItem>
                                    <TextField
                                        id="Objects"
                                        label="Area Name"
                                        type="text"
                                        value={areaname}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleAreaChange}
                                    />
                                </ListItem>
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleEditArea(0)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => handleEditArea(1)} color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog classes={{ paper: classes.paper}} open={subEditModal} onClose={handleEditSub}>
                        <DialogTitle>Edit Sub Area</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{color: 'red'}}>
                                {errorMessage}
                            </DialogContentText>
                            <List>
                                <ListItem>
                                    <TextField
                                        id="Objects"
                                        label="Sub Area Name"
                                        type="text"
                                        value={sub_areaname}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleSubAreaChange}
                                    />    
                                </ListItem>
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleEditSub(0)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => handleEditSub(1)} color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog classes={{ paper: classes.paper}} open={areaDeleteModal} onClose={handleDeleteArea}>
                        <DialogTitle>Are you sure you want to delete this Area?</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{color: 'red'}}>
                                {errorMessage}
                            </DialogContentText>
                            <DialogContentText style={{color: 'black'}}>
                                <h3>Area Name: {areaname}</h3>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleDeleteArea(0)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => handleDeleteArea(1)} color="primary" disabled={disable}>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog classes={{ paper: classes.paper}} open={subDeleteModal} onClose={handleDeleteSub}>
                        <DialogTitle>Are you sure you want to delete this Sub Area?</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{color: 'red'}}>
                                {errorMessage}
                            </DialogContentText>
                            <DialogContentText style={{color: 'black'}}>
                                <h3>Sub Area Name: {sub_areaname}</h3>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleDeleteSub(0)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => handleDeleteSub(1)} color="primary" disabled={disable}>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                    
                </div>          
        
        </>
    )

}

export default LoopFilterPage;