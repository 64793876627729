import React from "react";
import "./ODPStatusList.css"
import List from '@material-ui/core/List';
import ODPStatusCard from "./ODPStatusCard/ODPStatusCard";
import { ODP_STATUS_SETTINGS } from "../../utils/constant";

function ODPStatusList(props)  {
    const lists = props.statusList || [];
    return (
        <>
            <List className={`survey-list`}>
                {
                    lists.slice(ODP_STATUS_SETTINGS.LIST_START, ODP_STATUS_SETTINGS.LIST_ITEMS).map(list=>{
                        return <ODPStatusCard cardDetails={list || null} key={Math.random()} />
                    })
                }
            </List>
        </>
    )

}

export default ODPStatusList;