import React, { useEffect, useState } from "react";
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { useParams } from "react-router-dom";
import "../components/ODPStatusTable/ODPStatusTable.css"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper'; 
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TablePagination from '@material-ui/core/TablePagination';
import moment from "moment";
import { ODP_STATUS_SETTINGS } from "../utils/constant";

function ODPObjectPage()  {

    let { odpObject } = useParams();
    const [entry, setEntryList] = useState([]);
    const pages = [5, 10, 25];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pages[page]);

    let searchFilter = { key: {eq: odpObject }};

    useEffect(() => {

        Analytics.record({ name: 'ODP Object Page visit' });
        API.graphql(graphqlOperation(queries.listOdpAppMonitorEntrys, { 
            filter: searchFilter,
            limit: 500
        }))
        .then((data) => {
            setEntryList(data.data.listOdpAppMonitorEntrys.items);
        })
        .catch((error) => {
            console.log(error);
        });
        
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value,10))
        setPage(0);
    }

    const afterPaging = () => {
        return entry.slice(page*rowsPerPage,(page+1)*rowsPerPage)
    }

    function truncate(str, n) {
        return (str.length > n) ? str.substr(0, n-1) + ' ...' : str;
    };

    return (
        <>
            <h1 className="page-title">
                <Button component={Link} to={`/odp/livestatus`}>
                    <ArrowBackIcon/>
                </Button>
                <p align="center">{odpObject}</p>
            </h1>
            <TableContainer component={Paper}>
                <Table className={`status-table`} size="small" aria-label="dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="left">Object</TableCell>
                        <TableCell align="left">Class</TableCell>
                        <TableCell align="left">Created At (UTC)</TableCell>
                        <TableCell align="left">Function</TableCell>
                        <TableCell align="left">Message</TableCell>
                        <TableCell align="left">Message Type</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Updated At (UTC)</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {afterPaging().map((row) => (
                            <TableRow key={row.id}>
                                <TableCell align="left">{row.key}</TableCell>
                                <TableCell align="left">{row.class}</TableCell>
                                <TableCell align="left">{moment(row.createdAt).tz('UTC').format(ODP_STATUS_SETTINGS.DATE_FORMAT)}</TableCell>
                                <TableCell align="left">{row.function}</TableCell>
                                <TableCell align="left">{truncate((row.message), 25)}</TableCell>
                                <TableCell align="left">{row.messageType}</TableCell>
                                <TableCell align="left">{row.status}</TableCell>
                                <TableCell align="left">{moment(row.updatedAt).tz('UTC').format(ODP_STATUS_SETTINGS.DATE_FORMAT)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component = "div"
                count = {entry.length}
                page = {page}
                rowsPerPageOptions = {pages}
                onChangePage = {handleChangePage}
                rowsPerPage = {rowsPerPage}
                onChangeRowsPerPage = {handleChangeRowsPerPage}
            />    
        </>
    );
}

export default ODPObjectPage;
