import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded';
import ThumbsUpDownRoundedIcon from '@material-ui/icons/ThumbsUpDownRounded';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ODP_STATUS_SETTINGS } from "../utils/constant";
import { AuthContext } from "../App";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import moment from "moment-timezone";
import Tooltip from '@material-ui/core/Tooltip';
import SalesforceLogo from "../../src/img/salesforce-logo.png"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        fontFamily: 'Arial',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    form: {
        padding: theme.spacing(2),
    },
    formControl: {
        minWidth: '200px',
    }
  }));

const sentimentList = [
    'POSITIVE',
    'NEUTRAL',
    'NEGATIVE'
];

function TheLoopFeedbackPage()  {

    const classes = useStyles();
    let { recordid } = useParams();

    const { state } = React.useContext(AuthContext);
    const [initialized, setInitialized] = useState(0);
    const [record, setRecord] = useState({})
    const [newComment, setNewComment] = useState("");
    const [commentList, setCommentList] = useState([]);
    const [sentimentOverride, setSentimentOverride] = useState("");
    const [following, setFollowing] = useState(false);

    useEffect(() => {
        Analytics.record({
            name: 'The Loop - Feedback URL visit',
            attributes: {
                username: state.user.username
            }
        });
        getdata();
    }, []);

    useEffect(() => {
        const subscription = API.graphql(
          graphqlOperation(subscriptions.onCreateTheLoopFeedbackComment)
        ).subscribe({
            next: data => {
                const { value: { data: { onCreateTheLoopFeedbackComment } }} = data
                const comments = [...commentList, onCreateTheLoopFeedbackComment]
                setCommentList(comments)
            }
        })
    
        return () => subscription.unsubscribe()
    }, [commentList])

    function capitalize(s) {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    }

    function truncate(str, n) {
        return (str.length > n) ? str.substr(0, n-1) + ' ...' : str;
    };

    const handleSentimentOverride = (event) => {
        setSentimentOverride(event.target.value);
        if (event.target.value !== record.Sentiment)  {
            setNewComment(`Sentiment overridden from ${record.Sentiment} to ${event.target.value}.`)
        }  else {
            setNewComment("");
        }
    };

    function getdata() {
        API.graphql({query: queries.getTheLoopFeedback, variables: { recordid: recordid }})
        .then((data) => {
            console.log(data);
            setRecord(data.data.getTheLoopFeedback);
            setCommentList(data.data.getTheLoopFeedback.comments.items);
            setSentimentOverride(data.data.getTheLoopFeedback.Sentiment);
            // setFollowing ...
            setInitialized(1);
        })
        .catch((error) => {
            console.log(error);
            setInitialized(1);
        })
    }

    const handleSave = async(event) => {

        // If sentiment has been overridden, update the main record.
        // If originalSentiment is empty then update it; otherwise, do not.  
        // This allows us to keep the original value from the sentiment analyzer.

        if (newComment !== "")   {
            API.graphql(graphqlOperation(mutations.createTheLoopFeedbackComment, { input: { recordid: recordid, owner: state.user.username, content: newComment } }))
            .then((data) => {
                setNewComment("");
            })
            .catch((error) => {
                console.log(error);
            })

            // Mark thread as being followed.
            API.graphql(graphqlOperation(mutations.createUserFollows, { input: { recordid: recordid, owner: state.user.username, username: state.user.username, unfollow: false } }))
            .then((data) => {
                setFollowing(true);
            })
            .catch((error) => {
                console.log(error);
            })

        }
    }

    return (
        <>
 
            {/* <h2 className="page-title">{record.subject}</h2> */}

            {initialized === 0 ? <CircularProgress /> : 

            <>
                <div className="HomePage">
                    <div className={classes.root}>
                        <Grid container>

                            <Grid item xs={1}>
                                <Button component={Link} to={`/theloop/search`}>
                                    <ArrowBackIcon/>
                                </Button>
                            </Grid>

                            <Grid item xs={9}>
                                <h2 align="center">{record.subject}</h2>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography>
                                    Date: {moment(record.created_date).format("MM/DD/YY")}
                                </Typography>
                                <Typography align='left'>
                                    Time: {moment(record.created_date).tz('America/Los_Angeles').format("HH:mm:ss")}
                                </Typography>
                            </Grid>

                        </Grid>

                        <Grid container>

                            <Grid item xs={1}>
                                <Box style={{padding: 30}}>
                                    {
                                        record.Sentiment && record.Sentiment.toLowerCase() === 'positive'?(<ThumbUpRoundedIcon style={{ width: 40,height: 40 }} className={ODP_STATUS_SETTINGS.GOOD} />):
                                        record.Sentiment && record.Sentiment.toLowerCase() === 'negative'?(<ThumbDownRoundedIcon style={{ width: 40,height: 40 }} className={ODP_STATUS_SETTINGS.BAD} />):
                                        <ThumbsUpDownRoundedIcon style={{ width: 40,height: 40 }} className={ODP_STATUS_SETTINGS.NEUTRAL} />
                                    }
                                </Box>

                                <Box style={{padding: 20}}>
                                    <Tooltip title="Open case in salesforce">
                                        <Button onClick={()=>{ window.open(`https://moveinc--dev.lightning.force.com/lightning/r/Case/${record.recordid.substring(2)}/view`)}}>
                                            <img src={SalesforceLogo} alt="Logo" width="45" height="35"/>
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Grid>

                            <Grid item xs={5}>
                                <List dense={true}>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Acct Type:</b> {record.account_type}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Label:</b> {record.label}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Area:</b> {record.area}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Subarea:</b> {record.sub_area}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Source:</b> {/Email/.test(record.origin)?"Email":record.origin}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography>
                                                <b>Current URL:</b> {/https/.test(record.current_url) ? (/access_token/.test(record.current_url) ? truncate((record.current_url), 50) : truncate((record.current_url), 100)) : ""}
                                                {/https/.test(record.current_url) ? (/access_token/.test(record.current_url) ? <Button onClick={() => {navigator.clipboard.writeText(truncate((record.current_url), 50))}}>📋</Button> : <Button onClick={() => {navigator.clipboard.writeText(record.current_url)}}>📋</Button>) : ''}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography>
                                                <b>User Agent:</b> {truncate((record.user_agent), 100)}
                                                {record.user_agent?<Button onClick={() => {navigator.clipboard.writeText(record.user_agent)}}>📋</Button>:''}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Research Notes:</b> {record.research_notes}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography>
                                                <b>URL:</b> {window.location.protocol + "//" + window.location.host + "/theloop/feedback/" + record.recordid}
                                                <Button onClick={() => {navigator.clipboard.writeText(window.location.protocol + "//" + window.location.host + "/theloop/feedback/" + record.recordid)}}>📋</Button>
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Grid>

                            <Grid item xs={5}>
                                <List dense={true}>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Name:</b> {record.account_name}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Role:</b> {record.role}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>PID:</b> {record.party_id}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Browser:</b> {record.browser}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Operating System:</b> {record.operating_system}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Jira #:</b> {record.jiracasenumber__c ? <Link onClick={ ()=>{ window.open('https://moveinc.atlassian.net/browse/'+ record.jiracasenumber__c)}}>{"https://moveinc.atlassian.net/browse/" + record.jiracasenumber__c}</Link> : ' '}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Rating:</b> {record.star_rating}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Grid>

                        </Grid>

                        <Grid container spacing={3}>

                            <Grid item xs={6}>
                                <h3>Feedback:</h3>
                                <Box border={1} style={{padding: 10, minHeight:200, maxHeight: 200, overflow: 'auto'}}>{record.description}</Box>
                            </Grid>

                            <Grid item xs={6}>
                                <h3>Internal Comments:</h3>
                                <Box border={1} style={{padding: 10, minHeight:200, maxHeight: 200, overflow: 'auto'}}>
                                    <List className={`comment-list`}>
                                        {record.case_comments}
                                        {
                                        commentList.map(comment=>{
                                            return <ListItem key={comment.id}><ListItemText>{moment(comment.createdAt).tz('America/Los_Angeles').format(ODP_STATUS_SETTINGS.DATE_FORMAT)}: {comment.owner} - {comment.content}</ListItemText></ListItem>
                                        })
                                        }
                                    </List>
                                </Box>
                            </Grid>

                        </Grid>
                    </div>
      
                    <div className={classes.form}>

                        <div className={classes.form}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="sentiment-native-simple">IMPLEMENT! Sentiment Override and TAGS</InputLabel>
                                <Select
                                    native
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    value={sentimentOverride}
                                    onChange={handleSentimentOverride}
                                    inputProps={{
                                        name: 'sentiment',
                                        id: 'sentiment-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {sentimentList.map((option) => (
                                        <option key={option} value={option}>
                                            {capitalize(option)}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.form}>
                            <TextField 
                                id="new_comment" 
                                label="Comment" 
                                multiline
                                rows={4}
                                fullWidth
                                variant="outlined" 
                                value={newComment} 
                                onChange={(event) => setNewComment(event.target.value)}
                            />
                        </div>

                        <Button variant="contained" color="primary" onClick={() => handleSave()}>Save</Button>
                    </div>

                </div>

            </>
            }
        </>
    )

}

export default TheLoopFeedbackPage;