import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { AuthContext, GApageView } from "../App";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link } from 'react-router-dom';
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        fontFamily: 'Arial',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    form: {
        padding: theme.spacing(2),
    },
    formControl: {
        minWidth: '200px',
    }
  }));

function TheLoopStoreReviewPage(props)  {

    const classes = useStyles();
    let { id } = useParams();
    const { state } = React.useContext(AuthContext);
    const [initialized, setInitialized] = useState(0);
    const [record, setRecord] = useState({})
    const [newComment, setNewComment] = useState("");
    const allRecords = props.location.state.feedback || [];
    const recordList = allRecords.map((list) => list.id);
    const [index, setIndex] = useState(recordList.indexOf(id));
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        GApageView('Loop Store Review Page', '', state.user.username)
        Analytics.record({
            name: 'The Loop Store Review Page visit',
            attributes: {
                username: state.user.username
            }
        });
        getdata();
    }, []);

    useEffect(() => {
        getdata();
    }, [index]);

    function getdata() {
        API.graphql({query: queries.getLoopStoreReview, variables: { id: recordList[index] }})
        .then((data) => {
            console.log(data);
            setRecord(data.data.getLoopStoreReview);
            setInitialized(1);
        })
        .catch((error) => {
            console.log(error);
            setInitialized(1);
        })
    }

    const handleSave = async(event) => {
        if (newComment !== "")   {
            setDisabled(true);
            API.graphql(graphqlOperation(mutations.updateLoopStoreReview, { input: { id: recordList[index], comment: newComment } }))
            .then((data) => {
                setDisabled(false);
                setNewComment("");
                getdata();
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }

    function prevRecord(){
        if (index > 0){
            setIndex(index => index - 1 );
        }
    }

    function nextRecord(){
        if (index < recordList.length - 1){
            setIndex(index => index + 1 );
        }
    }

    return (
        <>

            {initialized === 0 ? <CircularProgress /> : 

            <>
                <div className="HomePage">
                    <div className={classes.root}>
                        <Grid container>

                            <Grid item xs={10}>
                                <Button component={Link} to={`/theloop/store`}>
                                    <ArrowBackIcon/>
                                </Button>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography>
                                    Date: {moment(record.date).format("YYYY-MM-DD")}
                                </Typography>
                                <Typography align='left'>
                                    Time: {moment(record.date).format("HH:mm:ss")}
                                </Typography>
                            </Grid>

                        </Grid>

                        <Grid container>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={5}>
                                <List dense={true}>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Title:</b> {record.title}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Author:</b> {record.author}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Rating:</b> {record.rating}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Review:</b> {record.review}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Source: </b> {record.source}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Version:</b> {record.version}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Grid>
                    
                            <Grid item xs={5}>
                                <List dense={true}>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Reply:</b> {record.reply}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Comment:</b> {record.comment}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Date:</b> {moment(record.date).format("YYYY-MM-DD")}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Grid>

                        </Grid>

                        <Grid container spacing={1}>

                            <Grid item xs={6}>
                                <Button onClick={prevRecord}>
                                    <ArrowBackIosIcon/>
                                </Button>
                            </Grid>

                            <Grid item xs={6} align='right'>
                                <Button onClick={nextRecord}>
                                    <ArrowForwardIosIcon/>
                                </Button>
                            </Grid>

                        </Grid>

                    </div>
      
                    <div className={classes.form}>
                        <h4>Add Comments:</h4>
                        <TextField 
                            id="new_comment" 
                            label="Comment" 
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined" 
                            value={newComment} 
                            onChange={(event) => setNewComment(event.target.value)}
                        />
                        <Grid item xs={12} align="right">
                            <Button disabled={disabled} variant="contained" color="primary" align="right" onClick={() => handleSave()}>Save</Button>
                        </Grid>
                    </div>

                </div>

            </>
            }
        </>
    )

}

export default TheLoopStoreReviewPage;