import React from "react";

function NotFoundPage()  {

    return (
        <div>
            <h1 className="notfound-title">Page not found :(</h1>
            <p>Maybe the page you are looking for has been removed, or you typed in the wrong URL</p>
        </div>
    )

}

export default NotFoundPage;