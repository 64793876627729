import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import LoopFeedbackList from "../components/LoopFeedbackList/LoopFeedbackList";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Pagination from '@material-ui/lab/Pagination';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import moment from "moment-timezone";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays, addYears } from 'date-fns';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AuthContext } from "../App";
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import gtag from "ga-gtag";

const resultsPerPageList = [
    10,
    25,
    50,
    100,
    250
]

const sortList = [
    'asc',
    'desc'
]

const directionList = [
    'Inbound',
    'Outbound'
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth:"auto"
    },
  },
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      textAlign: 'left',
      padding: theme.spacing(1),
      fontFamily: 'Arial',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    searchbar: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        width: "150px",
    },
    formControls: {
        margin: theme.spacing(1),
        width: '150px',
    },
    formWidth: {
        margin: theme.spacing(1),
        width: "70px",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button: {
        color: '#FFFFFF',
        backgroundColor: '#d92228',
        '&:hover': {
            backgroundColor: '#a51d32',
          },
        fontFamily: 'Arial',
        fontWeight: 'bold'
    },
    button2: {
        color: '#FFFFFF',
        backgroundColor: '#0062cc',
        '&:hover': {
            backgroundColor: '#003d80',
          },
        fontFamily: 'Arial',
        fontWeight: 'bold'
    },
  }));

// const pageID = 'LOOPSEARCH'
const pageTitle = 'Vonage Call Info'

function VonageCallinfoPage(props)  {
    const { state } = React.useContext(AuthContext);
    const [feedback, setFeedback] = useState([])
    const [label, setLabel] = useState('')
    const [hasResults, setHasResults] = useState(false)
    const [searched, setSearched] = useState(false)
    const [err, setErr] = useState(false)

    const [open, setOpen] = React.useState(false);
    const [dialogData, setDialogData] = React.useState({});
    const [errorMessage, setErrorMessage] = useState();
    const [directionFilter, setDirectionFilter] = useState("");
    const [searchFilter, setSearchFilter] = useState("");
    const [searchCriteria, setSearchCriteria] = useState("");
    const [sendCriteria, setSendCriteria] = useState("");
    const [searchName, setSearchName] = useState("");
    const [dateRangeFilter, setDateRangeFilter] = useState([
        {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [sortValue, setSortValue] = useState('desc');
    const [numPages, setNumPages] = useState(1);
    const [dateRange, setDateRange] = useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [disabled, setDisabled] = useState(false)
    const [subModal, setSubModal] = useState(false)
    const [exportFilter, setExportFilter] = useState("");
    const [openExportDialog, setOpenExportDialog] = React.useState(false);
    const [expand, setExpand] = useState(false);
    const [buttonText, setButtonText] = useState("Expand All");
    const classes = useStyles();

    const showStartDate = moment(dateRangeFilter[0].startDate).format("MMM D, YYYY");
    const showEndDate = moment(dateRangeFilter[0].endDate).format("MMM D, YYYY");

    const path = 0;

    function capitalize(s) {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    }

    const getFeedbackFromSearch = async (e) => {
        
        try {
            setFeedback([]);

            var searchFilter = {}
            var searchCriteria = {}
            let sendCriteria = {}
            const startDate = dateRangeFilter[0].startDate
            const endDate = addDays(dateRangeFilter[0].endDate, 1); // add a day then use less than to get full day.
            
            searchFilter['and'] = [
                { 'start': { gte: startDate.getTime() / 1000 } },
                { 'start': { lt: endDate.getTime() / 1000 } },
            ]
            searchCriteria['and'] = [];

            // if (label !== "")  {
            //     if (/"/.test(label)) {
            //         searchFilter['or'] = [
            //             { 'description': { matchPhrasePrefix: label }},
            //             { 'subject': { matchPhrasePrefix: label }},
            //             { 'case_number': { matchPhrasePrefix: label }}
            //         ]
            //         searchCriteria['or'] = [
            //             { 'description': { matchPhrasePrefix: label }},
            //             { 'subject': { matchPhrasePrefix: label }},
            //             { 'case_number': { matchPhrasePrefix: label }}
            //         ]
            //         sendCriteria['description'] = label
            //         console.log("with quote")
            //     }
            //     else {
            //         searchFilter['or'] = [
            //             { 'description': { match: label }},
            //             { 'subject': { match: label }},
            //             { 'case_number': { match: label }}
            //         ]
            //         searchCriteria['or'] = [
            //             { 'description': { match: label }},
            //             { 'subject': { match: label }},
            //             { 'case_number': { match: label }}
            //         ]
            //         sendCriteria['description'] = label
            //         console.log("without quote")
            //     }
            // }
            
            if (directionFilter !== "")   {
                searchFilter['origin'] = { match: directionFilter }
                searchCriteria['origin'] = { match: directionFilter }
                sendCriteria['origin'] = directionFilter
            }

            console.log(searchFilter,"searchFilter")

            let sort = {
                "direction": sortValue,
                "field": "created_date_ut"
            }

            // const result = await API.graphql(graphqlOperation(queries.searchTheLoopFeedbacks, { filter: searchFilter, sort: sort, limit: limit, from: (page-1)*limit }));

            // console.log(result,"results new");
            // console.log('Returned', result.data.searchTheLoopFeedbacks.items.length, 'of Total # Records', result.data.searchTheLoopFeedbacks.total)

            // setNumPages(Math.ceil(result.data.searchTheLoopFeedbacks.total/limit))
            // setHasResults(result.data.searchTheLoopFeedbacks.items.length > 0)
            // setFeedback(result.data.searchTheLoopFeedbacks.items)
            // setSearchFilter(JSON.stringify(searchFilter))
            // setSearchCriteria(JSON.stringify(searchCriteria))
            // setSendCriteria(JSON.stringify(sendCriteria))
            // setSearched(true)
            // setErr(false);
            // setSavedFilters([])
            // setExportFilter(searchFilter)
            // console.log('finished search')
        }
        catch(err) {
            console.log('Caught Error');
            console.log(err);
            setErr(err.errors[0].message);
        }
    }

    // const handleClick = (event) => {
    //     event.preventDefault();
    
    //     // let the app manage the persistence & state
    //     getFeedbackFromSearch();
    //   };

    const handleKeyDown = (event) => {
    
        if (event.key === 'Enter')  {
            getFeedbackFromSearch();
        }

    };

    const NoResults = () => {
        return !searched || label === ''
           ? <h4>No matching call info found for selected filters</h4>
           : <h4>No matching call info found for '{label}'</h4>
    }

    const handleClose = async(opt) => {
        setOpen(false);
    };

    const handleDialogOpen = (card) => {
        console.log('Open');
        console.log(card);
        setOpen(true);
        if (card.hasOwnProperty('recordid')) { 
            setDialogData(card);
        } 
        else {
            setDialogData({});
        }
    };

    const handleResultsPerPageChange = (event) => {
        setLimit(event.target.value);
    };

    const handleDirectionChange = (event) => {
        setDirectionFilter(event.target.value);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    function handleExpand() {
        expand ? setExpand(false) : setExpand(true)
        expand ? setButtonText("Expand More") : setButtonText("Expand Less")
    }

    const handleResetClick = (event) => {
        setDirectionFilter("");
    };

    const handleObjectChange = (event) => {
        setSearchName(event.target.value);
    };

    const handleSortResults = (event) => {
        setSortValue(event.target.value);
    };

    const handleExportClick = async (event) => {
        setDisabled(true);
        let finalResult = []
        let result = []
        let sort = {
            "direction": sortValue,
            "field": "created_date_ut"
        }
        let results = await API.graphql(graphqlOperation(queries.searchTheLoopFeedbacks, { filter: exportFilter, sort: sort, limit:1000 }));
        
        if(results.data.searchTheLoopFeedbacks.total!=results.data.searchTheLoopFeedbacks.items.length){
    
            for (let i=0;i<results.data.searchTheLoopFeedbacks.total;i=i+result.data.searchTheLoopFeedbacks.items.length){
                result = await API.graphql(graphqlOperation(queries.searchTheLoopFeedbacks, { filter: exportFilter, sort: sort, from:i, limit:1000}));
                console.log('Starting from ', i ,'Returned', result.data.searchTheLoopFeedbacks.items.length, 'records out of Total ', result.data.searchTheLoopFeedbacks.total, 'records')
                console.log(result.data.searchTheLoopFeedbacks.items, "results")
                finalResult.push(result.data.searchTheLoopFeedbacks.items)
            }

        }
        else{
            console.log(results.data.searchTheLoopFeedbacks.items.length, results.data.searchTheLoopFeedbacks.total,"results are less than thousand #")
            finalResult.push(results.data.searchTheLoopFeedbacks.items)
        }

        console.log(finalResult.flat(),'export');
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const csvData = finalResult.flat();
        const fileName = 'feedback '+moment(dateRangeFilter[0].startDate).format("YYYY-MM-DD")+' to '+moment(dateRangeFilter[0].endDate).format("YYYY-MM-DD"); 
    
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
        setDisabled(false);
        setOpenExportDialog(true)
        gtag('event', 'report_download', {
            user_name: state.user.username
        });
    };

    const handleClickOpen = (event) => {
        setDateRange(true);
    };

    const handleClickClose = (event) => {
        setDateRange(false);
    };

    const handleOpenDialog = (event) => {
        setOpenDialog(true);
    };

    const handleCloseExportDialog = (event) => {
        setOpenExportDialog(false);
    };

    const handleCloseDialog = async(opt) => {
        if (opt === 1) {
            if (searchName === "") {
                setErrorMessage("Please enter the Search Name");
            }
            else {
                setSubModal(true);
            }
        }
        else {
            setOpenDialog(false);
        }
    };

    useEffect(() => {
        getFeedbackFromSearch();
    }, [ directionFilter,dateRangeFilter, page, limit, label, sortValue] );

    return (
        <>
            {
                err
                ?   <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        { err }
                    </Alert>
                :  null 
            }

            <h2 className="page-title">{pageTitle}</h2>

            <Dialog classes={{ paper: classes.paper}} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{dialogData.subject}</DialogTitle>
                <DialogContent>
                    <Typography>
                        <b>Area:</b> {dialogData.area}
                    </Typography>
                    <Typography>
                        <b>Sub-Area:</b> {dialogData.sub_area}
                    </Typography>
                    <Typography gutterBottom>
                        <b>Status:</b> {dialogData.status}
                    </Typography>
                    <DialogContentText>
                        {dialogData.description}
                    </DialogContentText>
                    <DialogContentText style={{color: 'red'}}>
                        {errorMessage}
                    </DialogContentText>
                    {/* <TextField
                        defaultValue={editPage}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Page"
                        type="text"
                        fullWidth
                        onChange={handlePageChange}
                        InputProps={editMode}
                    />
                    <TextField
                        id="filled-multiline-static"
                        label="Configuration"
                        multiline
                        rows={8}
                        defaultValue={editConfig}
                        variant="filled"
                        fullWidth
                        onChange={handleConfigChange}
                    /> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(0)} color="primary">
                        Close
                    </Button>
                    {/* <Button onClick={() => handleClose(1)} color="primary">
                        Submit
                    </Button> */}
                </DialogActions>
            </Dialog>

            <div className={classes.root}>

                <Grid container spacing={1}>

                    <Grid item xs={2}>

                        <FormControl className={classes.formControl}>

                            <InputLabel>Direction</InputLabel>
                            <Select
                                native
                                displayEmpty
                                className={classes.selectEmpty}
                                value={directionFilter}
                                onChange={handleDirectionChange}
                            >
                                <option aria-label="None" value="" />
                                {directionList.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Select>
                            
                        </FormControl>
                    </Grid>

                    <Grid item>

                        <FormControl className={classes.formControl}>

                            <InputLabel>Results Per Page</InputLabel>
                            <Select
                                native
                                displayEmpty
                                className={classes.selectEmpty}
                                value={limit}
                                onChange={handleResultsPerPageChange}
                            >
                                {resultsPerPageList.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Select>
                            
                        </FormControl>

                    </Grid>

                    <Grid item>

                    <FormControl className={classes.formControl}>

                        <InputLabel>Sort by Date</InputLabel>
                        <Select
                            native
                            displayEmpty
                            className={classes.selectEmpty}
                            value={sortValue}
                            onChange={handleSortResults}
                        >
                            {sortList.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Select>
                        
                    </FormControl>

                    </Grid>

                </Grid>

                <Grid container spacing={1} align="right">
                    <Grid item xs={4}>

                        { hasResults ?
                            <Tooltip title="Expand Feedback Results">
                                <Button className={classes.button2} variant="contained" color="default" onClick={handleExpand}>{buttonText}</Button>
                            </Tooltip>
                        : " " }
                    </Grid>

                    <Grid item xs={2}>
                        <p>Start Date: {showStartDate}</p>
                        <p>End Date: {showEndDate}</p>
                    </Grid>

                    <Grid item xs={2}>

                        <Button className={classes.button} variant="contained" color="default" onClick={handleClickOpen}>Edit Date Range</Button>
                        <Dialog classes={{ paper: classes.paper}} onClose={handleClickClose} open={dateRange}>
                            <DialogContent>
                                <Paper>
                                    <DateRangePicker
                                        onChange={item => setDateRangeFilter([item.selection])}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        ranges={dateRangeFilter}
                                        direction="horizontal"
                                        minDate={addYears(new Date(), -2)}
                                        maxDate={new Date()}
                                    />
                                </Paper>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClickClose} color="primary">
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </Grid>

                    <Grid item xs={2}>
                        <Button className={classes.button2} onClick={handleOpenDialog}>Save & Subscribe</Button>
                    </Grid>

                    <Grid item xs={1}>
                        <Button className={classes.button2} onClick={handleResetClick}>Reset</Button>
                    </Grid>

                    <Dialog classes={{ paper: classes.paper}} onClose={handleCloseDialog} open={openDialog}>
                        <DialogContent>
                            <List>
                            <ListItem>
                                <TextField
                                    id="SearchName"
                                    label="Enter Search Name"
                                    type="text"
                                    value={searchName}
                                    fullWidth
                                    inputProps={{ maxLength: 100 }}
                                    onChange={handleObjectChange}
                                />   
                            </ListItem>
                            <DialogContentText style={{color: 'red'}}>
                                {errorMessage}
                            </DialogContentText>
                            <ListItem>
                                Direction: {directionFilter }
                            </ListItem>

                        </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog(0)} color="primary">
                                Cancel
                            </Button>
                            <Button disabled={subModal} onClick={() => handleCloseDialog(1)} color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Grid item xs={1}>
                        <Tooltip title="Download Feedback Results">
                            <Button onClick={handleExportClick} disabled={disabled}><GetAppIcon/></Button>
                        </Tooltip>
                    </Grid>

                    <Dialog open={disabled} >
                        <DialogTitle>
                            Downloading feedback report
                        </DialogTitle>
                        <center><CircularProgress/></center>
                    </Dialog>

                    <Dialog onClose={handleCloseExportDialog} open={openExportDialog}>
                        <DialogTitle>
                            Feedback report is downloaded successfully! 
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleCloseExportDialog} variant="contained" color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}> 
                        <Input className={classes.searchbar}
                            type='text'
                            placeholder='Search for call info'
                            icon='search'
                            iconposition='left'
                            name='label'
                            value={label}
                            onChange={(e) => { setLabel(e.target.value); setSearched(false); } }
                            onKeyDown={handleKeyDown}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                                }
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <div className={classes.results}>                         
                    {
                        hasResults
                        ? 
                            <>
                                <LoopFeedbackList feedback={feedback} criteria={sendCriteria} path={path} expand={expand} handleOpen={handleDialogOpen}/>
                                { numPages > 1 ? (
                                    <Pagination count={numPages} onChange={handlePageChange}/> 
                                ) : null
                                }
                            </>
                        : <NoResults />
                    }
                    </div>
                </Grid>


            </div>


        </>
    );

}

export default VonageCallinfoPage;