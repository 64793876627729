/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateEsDatahubPageConfig = /* GraphQL */ `
  subscription OnCreateEsDatahubPageConfig {
    onCreateESDatahubPageConfig {
      id
      config
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEsDatahubPageConfig = /* GraphQL */ `
  subscription OnUpdateEsDatahubPageConfig {
    onUpdateESDatahubPageConfig {
      id
      config
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEsDatahubPageConfig = /* GraphQL */ `
  subscription OnDeleteEsDatahubPageConfig {
    onDeleteESDatahubPageConfig {
      id
      config
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTheloopSurvey = /* GraphQL */ `
  subscription OnCreateTheloopSurvey {
    onCreateTheloopSurvey {
      id
      surveyName
      isActive
      creationDate
      lastModified
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTheloopSurvey = /* GraphQL */ `
  subscription OnUpdateTheloopSurvey {
    onUpdateTheloopSurvey {
      id
      surveyName
      isActive
      creationDate
      lastModified
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTheloopSurvey = /* GraphQL */ `
  subscription OnDeleteTheloopSurvey {
    onDeleteTheloopSurvey {
      id
      surveyName
      isActive
      creationDate
      lastModified
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOdpAppMonitorEntry = /* GraphQL */ `
  subscription OnCreateOdpAppMonitorEntry {
    onCreateOdpAppMonitorEntry {
      class
      id
      key
      status
      message
      messageType
      function
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOdpAppMonitorEntry = /* GraphQL */ `
  subscription OnUpdateOdpAppMonitorEntry {
    onUpdateOdpAppMonitorEntry {
      class
      id
      key
      status
      message
      messageType
      function
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOdpAppMonitorEntry = /* GraphQL */ `
  subscription OnDeleteOdpAppMonitorEntry {
    onDeleteOdpAppMonitorEntry {
      class
      id
      key
      status
      message
      messageType
      function
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOdpAppMonitorEntrySummary = /* GraphQL */ `
  subscription OnCreateOdpAppMonitorEntrySummary {
    onCreateOdpAppMonitorEntrySummary {
      key
      keyType
      updateFrequency
      frequencyArg
      tags
      active
      currentStatus
      lastMessage
      lastMessageType
      lastFunction
      lastStarted
      lastCompleted
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOdpAppMonitorEntrySummary = /* GraphQL */ `
  subscription OnUpdateOdpAppMonitorEntrySummary {
    onUpdateOdpAppMonitorEntrySummary {
      key
      keyType
      updateFrequency
      frequencyArg
      tags
      active
      currentStatus
      lastMessage
      lastMessageType
      lastFunction
      lastStarted
      lastCompleted
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOdpAppMonitorEntrySummary = /* GraphQL */ `
  subscription OnDeleteOdpAppMonitorEntrySummary {
    onDeleteOdpAppMonitorEntrySummary {
      key
      keyType
      updateFrequency
      frequencyArg
      tags
      active
      currentStatus
      lastMessage
      lastMessageType
      lastFunction
      lastStarted
      lastCompleted
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTheLoopFeedback = /* GraphQL */ `
  subscription OnCreateTheLoopFeedback {
    onCreateTheLoopFeedback {
      recordid
      record_source
      source_id
      case_number
      origin
      origin_description
      account_name
      subject
      description
      priority
      type
      area
      sub_area
      datetime_openened
      status
      owner_name
      browser
      operating_system
      current_url
      user_agent
      is_closed
      Sentiment
      OriginalSentiment
      SentimentScore
      created_date
      last_modified_date
      closed_date
      updatedAt
      createdAt
      account_type
      sub_account_type
      partyid
      jiracasenumber__c
      source
      created_date_ut
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          id
          owner
          username
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          recordid
          theLoopFeedback {
            recordid
            record_source
            source_id
            case_number
            origin
            origin_description
            account_name
            subject
            description
            priority
            type
            area
            sub_area
            datetime_openened
            status
            owner_name
            browser
            operating_system
            current_url
            user_agent
            is_closed
            Sentiment
            OriginalSentiment
            SentimentScore
            created_date
            last_modified_date
            closed_date
            updatedAt
            createdAt
            account_type
            sub_account_type
            partyid
            jiracasenumber__c
            source
            created_date_ut
            role
            case_comments
            research_notes
          }
          unfollow
          createdAt
          updatedAt
        }
        nextToken
      }
      role
      case_comments
      research_notes
    }
  }
`;
export const onUpdateTheLoopFeedback = /* GraphQL */ `
  subscription OnUpdateTheLoopFeedback {
    onUpdateTheLoopFeedback {
      recordid
      record_source
      source_id
      case_number
      origin
      origin_description
      account_name
      subject
      description
      priority
      type
      area
      sub_area
      datetime_openened
      status
      owner_name
      browser
      operating_system
      current_url
      user_agent
      is_closed
      Sentiment
      OriginalSentiment
      SentimentScore
      created_date
      last_modified_date
      closed_date
      updatedAt
      createdAt
      account_type
      sub_account_type
      partyid
      jiracasenumber__c
      source
      created_date_ut
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          id
          owner
          username
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          recordid
          theLoopFeedback {
            recordid
            record_source
            source_id
            case_number
            origin
            origin_description
            account_name
            subject
            description
            priority
            type
            area
            sub_area
            datetime_openened
            status
            owner_name
            browser
            operating_system
            current_url
            user_agent
            is_closed
            Sentiment
            OriginalSentiment
            SentimentScore
            created_date
            last_modified_date
            closed_date
            updatedAt
            createdAt
            account_type
            sub_account_type
            partyid
            jiracasenumber__c
            source
            created_date_ut
            role
            case_comments
            research_notes
          }
          unfollow
          createdAt
          updatedAt
        }
        nextToken
      }
      role
      case_comments
      research_notes
    }
  }
`;
export const onDeleteTheLoopFeedback = /* GraphQL */ `
  subscription OnDeleteTheLoopFeedback {
    onDeleteTheLoopFeedback {
      recordid
      record_source
      source_id
      case_number
      origin
      origin_description
      account_name
      subject
      description
      priority
      type
      area
      sub_area
      datetime_openened
      status
      owner_name
      browser
      operating_system
      current_url
      user_agent
      is_closed
      Sentiment
      OriginalSentiment
      SentimentScore
      created_date
      last_modified_date
      closed_date
      updatedAt
      createdAt
      account_type
      sub_account_type
      partyid
      jiracasenumber__c
      source
      created_date_ut
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          id
          owner
          username
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          recordid
          theLoopFeedback {
            recordid
            record_source
            source_id
            case_number
            origin
            origin_description
            account_name
            subject
            description
            priority
            type
            area
            sub_area
            datetime_openened
            status
            owner_name
            browser
            operating_system
            current_url
            user_agent
            is_closed
            Sentiment
            OriginalSentiment
            SentimentScore
            created_date
            last_modified_date
            closed_date
            updatedAt
            createdAt
            account_type
            sub_account_type
            partyid
            jiracasenumber__c
            source
            created_date_ut
            role
            case_comments
            research_notes
          }
          unfollow
          createdAt
          updatedAt
        }
        nextToken
      }
      role
      case_comments
      research_notes
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      username
      given_name
      last_name
      email
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      username
      given_name
      last_name
      email
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      username
      given_name
      last_name
      email
      comments {
        items {
          id
          recordid
          content
          owner
          user {
            username
            given_name
            last_name
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTheLoopFeedbackComment = /* GraphQL */ `
  subscription OnCreateTheLoopFeedbackComment {
    onCreateTheLoopFeedbackComment {
      id
      recordid
      content
      owner
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTheLoopFeedbackComment = /* GraphQL */ `
  subscription OnUpdateTheLoopFeedbackComment {
    onUpdateTheLoopFeedbackComment {
      id
      recordid
      content
      owner
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTheLoopFeedbackComment = /* GraphQL */ `
  subscription OnDeleteTheLoopFeedbackComment {
    onDeleteTheLoopFeedbackComment {
      id
      recordid
      content
      owner
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserFollows = /* GraphQL */ `
  subscription OnCreateUserFollows {
    onCreateUserFollows {
      id
      owner
      username
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      recordid
      theLoopFeedback {
        recordid
        record_source
        source_id
        case_number
        origin
        origin_description
        account_name
        subject
        description
        priority
        type
        area
        sub_area
        datetime_openened
        status
        owner_name
        browser
        operating_system
        current_url
        user_agent
        is_closed
        Sentiment
        OriginalSentiment
        SentimentScore
        created_date
        last_modified_date
        closed_date
        updatedAt
        createdAt
        account_type
        sub_account_type
        partyid
        jiracasenumber__c
        source
        created_date_ut
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        followers {
          items {
            id
            owner
            username
            recordid
            unfollow
            createdAt
            updatedAt
          }
          nextToken
        }
        role
      }
      unfollow
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserFollows = /* GraphQL */ `
  subscription OnUpdateUserFollows {
    onUpdateUserFollows {
      id
      owner
      username
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      recordid
      theLoopFeedback {
        recordid
        record_source
        source_id
        case_number
        origin
        origin_description
        account_name
        subject
        description
        priority
        type
        area
        sub_area
        datetime_openened
        status
        owner_name
        browser
        operating_system
        current_url
        user_agent
        is_closed
        Sentiment
        OriginalSentiment
        SentimentScore
        created_date
        last_modified_date
        closed_date
        updatedAt
        createdAt
        account_type
        sub_account_type
        partyid
        jiracasenumber__c
        source
        created_date_ut
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        followers {
          items {
            id
            owner
            username
            recordid
            unfollow
            createdAt
            updatedAt
          }
          nextToken
        }
        role
      }
      unfollow
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserFollows = /* GraphQL */ `
  subscription OnDeleteUserFollows {
    onDeleteUserFollows {
      id
      owner
      username
      user {
        username
        given_name
        last_name
        email
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      recordid
      theLoopFeedback {
        recordid
        record_source
        source_id
        case_number
        origin
        origin_description
        account_name
        subject
        description
        priority
        type
        area
        sub_area
        datetime_openened
        status
        owner_name
        browser
        operating_system
        current_url
        user_agent
        is_closed
        Sentiment
        OriginalSentiment
        SentimentScore
        created_date
        last_modified_date
        closed_date
        updatedAt
        createdAt
        account_type
        sub_account_type
        partyid
        jiracasenumber__c
        source
        created_date_ut
        comments {
          items {
            id
            recordid
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        followers {
          items {
            id
            owner
            username
            recordid
            unfollow
            createdAt
            updatedAt
          }
          nextToken
        }
        role
      }
      unfollow
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAnnouncementBoardEntry = /* GraphQL */ `
  subscription OnCreateAnnouncementBoardEntry {
    onCreateAnnouncementBoardEntry {
      id
      objectName
      status
      description
      detail
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAnnouncementBoardEntry = /* GraphQL */ `
  subscription OnUpdateAnnouncementBoardEntry {
    onUpdateAnnouncementBoardEntry {
      id
      objectName
      status
      description
      detail
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAnnouncementBoardEntry = /* GraphQL */ `
  subscription OnDeleteAnnouncementBoardEntry {
    onDeleteAnnouncementBoardEntry {
      id
      objectName
      status
      description
      detail
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSavedSearch = /* GraphQL */ `
  subscription OnCreateSavedSearch {
    onCreateSavedSearch {
      id
      userName
      searchNameTag
      searchCriteria
      subscribe
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      description
      subject
      role
      origin
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSavedSearch = /* GraphQL */ `
  subscription OnUpdateSavedSearch {
    onUpdateSavedSearch {
      id
      userName
      searchNameTag
      searchCriteria
      subscribe
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      description
      subject
      role
      origin
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSavedSearch = /* GraphQL */ `
  subscription OnDeleteSavedSearch {
    onDeleteSavedSearch {
      id
      userName
      searchNameTag
      searchCriteria
      subscribe
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      description
      subject
      role
      origin
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserSubscription = /* GraphQL */ `
  subscription OnCreateUserSubscription {
    onCreateUserSubscription {
      id
      userName
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserSubscription = /* GraphQL */ `
  subscription OnUpdateUserSubscription {
    onUpdateUserSubscription {
      id
      userName
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserSubscription = /* GraphQL */ `
  subscription OnDeleteUserSubscription {
    onDeleteUserSubscription {
      id
      userName
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFilterValues = /* GraphQL */ `
  subscription OnCreateFilterValues {
    onCreateFilterValues {
      id
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      role
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFilterValues = /* GraphQL */ `
  subscription OnUpdateFilterValues {
    onUpdateFilterValues {
      id
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      role
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFilterValues = /* GraphQL */ `
  subscription OnDeleteFilterValues {
    onDeleteFilterValues {
      id
      source
      account_type
      Sentiment
      area
      sub_area
      browser
      operating_system
      role
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLoopStoreReview = /* GraphQL */ `
  subscription OnCreateLoopStoreReview {
    onCreateLoopStoreReview {
      id
      author
      version
      review
      rating
      title
      comment
      reply
      date
      source
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLoopStoreReview = /* GraphQL */ `
  subscription OnUpdateLoopStoreReview {
    onUpdateLoopStoreReview {
      id
      author
      version
      review
      rating
      title
      comment
      reply
      date
      source
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLoopStoreReview = /* GraphQL */ `
  subscription OnDeleteLoopStoreReview {
    onDeleteLoopStoreReview {
      id
      author
      version
      review
      rating
      title
      comment
      reply
      date
      source
      createdAt
      updatedAt
    }
  }
`;
