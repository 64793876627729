import React, { useEffect, useState } from 'react'
import { API, Analytics } from 'aws-amplify';
// import { Skeleton } from '@material-ui/lab';

// Action Types
// const QUERY = 'QUERY';
// const SUBSCRIPTION = 'SUBSCRIPTION';

// const initialState = {
//   rtlStatus: [],
// };

// const reducer = (state, action) => {
//   switch (action.type) {
//     case QUERY:
//       return {...state, rtlStatus: action.jobStats};
//     case SUBSCRIPTION:
//       return {...state, rtlStatus:[...state.jobStats, action.jobStats]};
//     default:
//       return state;
//   }
// };
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        fontFamily: 'Arial',
    },
  }));

function ODPRealTimeLeadPage()  {

    const classes = useStyles();
    // const [state, dispatch] = useReducer(reducer, initialState);
    const [hourlyDetails, setHourlyDetails] = useState([]);
    const [summaryLine, setSummaryLine] = useState({});
    const [cwImage, setCwImage] = useState('')
    
    useEffect(() => {
        async function getData() {
            const rtpleadStatus = await API.get('esdatahubhomeapi', '/rtplead');
            // console.log(rtpleadStatus.status);
            setHourlyDetails(rtpleadStatus.status.Details);
            setSummaryLine(rtpleadStatus.status.Summary);
            const leadImg = await API.get('esdatahubhomeapi', '/cloudwatch');
            // console.log(leadImg.status)
            setCwImage("data:image/png;base64,"+leadImg.status);
        }
        Analytics.record({ name: 'ODP Real Time Lead Page visit' });
        getData();
    }, []);
    
    
    return (
        <div className={classes.root}>
          <h2>Real Time Lead Pipeline Status</h2>
          <div>
            {hourlyDetails.length > 0 ? 
            <div>
                <img src={cwImage}></img>
                <table>
                    <thead><tr>
                        <th>Time</th>
                        <th>LCS</th>
                        <th>RTP</th>
                        <th>RTP-LCS</th>
                        <th>RTP Flag</th>
                        <th>URTP</th>
                        <th>DM</th>
                        <th>DM-URTP</th>
                        <th>DM Flag</th>
                    </tr></thead>
                    <tbody>
                    {hourlyDetails.map((d) => 
                            <tr key={d.ts}>
                                <td>{d.ts}</td>
                                <td align="right">{d.lcs}</td>
                                <td align="right">{d.rtp}</td>
                                <td align="right">{d.rtp_diff}</td>
                                <td align="right" style={{ color:"red" }}>{d.rtp_flag}</td>
                                <td align="right">{d.rtp2}</td>
                                <td align="right">{d.dm}</td>
                                <td align="right">{d.rtp2_diff}</td>
                                <td align="right" style={{ color:"red" }}>{d.dm_flag}</td>
                            </tr>
                        )
                    }
                    <tr key={summaryLine.ts}>
                        <td>{summaryLine.ts}</td>
                        <td align="right">{summaryLine.lcs}</td>
                        <td align="right">{summaryLine.rtp}</td>
                        <td align="right">{summaryLine.rtp_diff}</td>
                        <td align="right" style={{ color:"red" }}>{summaryLine.rtp_flag}</td>
                        <td align="right">{summaryLine.rtp2}</td>
                        <td align="right">{summaryLine.dm}</td>
                        <td align="right">{summaryLine.rtp2_diff}</td>
                        <td align="right" style={{ color:"red" }}>{summaryLine.dm_flag}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
                : <p>Loading ...</p>
                // : <Skeleton variant="rect" width={210} height={118} />
            } 
          </div>
        </div>
    );

}

export default ODPRealTimeLeadPage