import React, { useState, useEffect } from "react";
import { API, Analytics } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'date-fns';
import * as queries from '../graphql/queries';

import appconfig from "../app-exports";
import { Logger } from 'aws-amplify';

import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import {AuthContext} from "../App"

// https://docs.aws.amazon.com/quicksight/latest/user/embedded-dashboards-example-html.html

export function DashboardPage(pageID, pageTitle)  {

    const logger = new Logger('DashboardPage');
    const defaultConfig = {
        'DashboardID': ""
    }

    const [initialized, setInitialized] = useState(0);
    const [errMessage, setErrMessage] = useState("");
    const [config, setConfig] = useState(defaultConfig);
    const { state } = React.useContext(AuthContext);
   
    useEffect(() => {

        API.graphql({ query: queries.getEsDatahubPageConfig, variables: { id: pageID} })
        .then((data) => {
            const d = JSON.parse(data.data.getESDatahubPageConfig.config)
            setConfig(d);
            getDashboard(d.DashboardID);
        })
        .catch((error) => {
            logger.error(error);
            setInitialized(-1);
        })
       
        async function getDashboard(dashboardId) {

            var jwtToken;
            var payloadSub;
            var email;

            logger.info(`getDashboard: ${dashboardId}`);
            
            try {
                if (initialized === 0)   {

                    logger.info(Auth);
                    await Auth.currentSession()
                    .then(data => { 
                        console.log('auth');
                        console.log(data);
                        jwtToken = data.idToken.jwtToken; 
                        payloadSub = data.idToken.payload.sub; 
                        email = data.idToken.payload.email; 
                    } )
                    .catch(err => {
                        logger.error(err);
                        setInitialized(-1);
                    } );

                    let myInit = { 
                        headers: {}, 
                        response: true, 
                        queryStringParameters: { 
                            jwtToken: jwtToken,
                            payloadSub: payloadSub,
                            email: email,
                            roleArn: appconfig.roleArn,
                            identityPoolId: appconfig.identityPoolId,
                            cognitoLogin: appconfig.cognitoLogin,
                            dashboardRegion: appconfig.dashboardRegion,
                            quickSightAuthRegion: appconfig.quickSightAuthRegion,
                            dashboardId : dashboardId,
                            awsAccount: appconfig.awsAccount
                        }
                    }

                    const data = await API.get('esdatahubhomeapi', '/getQuickSightDashboardEmbedURL', myInit);
                    var containerDiv = document.getElementById("dashboardContainer");
                    // var dashboard;
                    var options = {
                        url: data.data.data.EmbedUrl,
                        container: containerDiv,
                        parameters: {
                            Product: "",
                            AsOf: "",
                            VsDate: ""
                        },
                        scrolling: "no",
                        // height: "700px",
                        // width: "1000px",
                        height: "1000px",
                        width: "100%",
                        locale: "en-US",
                        footerPaddingEnabled: true,
                        UndoRedoDisabled: false,
                        ResetDisabled: false,
                    };
                    
                    embedDashboard(options);
                    setInitialized(1);
                }
            }    
            catch(err) {
                setErrMessage(err.message);
                setInitialized(-1);
            }

        }
    }, [initialized]);

    useEffect(() => {
        // Tracking Page vists
        Analytics.record({
            name: pageTitle + ' Page visit',
            attributes: {
                username: state.user.username
            }
        });
    }, []);

    return (
        <>
            <h2 className="page-title">{pageTitle}</h2>
                
            {initialized === -1 ? (
                <p>Error occurred.  Dashboard could not be loaded.  {errMessage}</p>
            ) : (
                initialized === 0 ? <CircularProgress /> : ""
            )
            }
            <div id="dashboardContainer"> </div>
           
        </>
    );
}


// export default DashboardPage;
