import { DashboardPage } from '../components/DashboardPage'

const pageID = 'LOOPCUSTOMERSERVICEMGR'
const pageTitle = 'The Loop Customer Service - Manager'

function TheLoopCustomerServiceMgrPage()  {

    return (
        DashboardPage(pageID, pageTitle)
    );

}

export default TheLoopCustomerServiceMgrPage;