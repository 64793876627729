import React, { useEffect } from 'react';
import './App.css';

// import Amplify, { Auth, Hub } from 'aws-amplify';

import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import Analytics from '@aws-amplify/analytics';

import appconfig from "./app-exports";
// import awsconfig from './aws-exports';
import awsmobile from './aws-exports';

import { Switch, Route } from 'react-router-dom';
import Header from "./components/Header";

import HomePage from "./pages/HomePage";
// import AboutPage from "./pages/AboutPage"
import GuestPage from "./pages/GuestPage";
import ProductDashboardPage from "./pages/ProductDashboardPage";
import MarketingPage from "./pages/MarketingPage";
import TheLoopHomePage from "./pages/TheLoopHomePage";
import TheLoopFeedPage from "./pages/TheLoopFeedPage";
import TheLoopCustomerServicePage from "./pages/TheLoopCustomerServicePage";
import TheLoopCustomerServiceMgrPage from "./pages/TheLoopCustomerServiceMgrPage";
import TheLoopProductPage from "./pages/TheLoopProductPage";
import TheLoopResearchAndInsightsPage from "./pages/TheLoopResearchAndInsightsPage";
import TheLoopSearchPage from "./pages/TheLoopSearchPage";
import TheLoopRecordPage from "./pages/TheLoopRecordPage";
import TheLoopedInPage from "./pages/TheLoopedInPage";
import AdminPage from "./pages/AdminPage";
import NotFoundPage from "./pages/NotFoundPage";
import ODPHomePage from "./pages/ODPHomePage";
import ODPStatusPage from "./pages/ODPStatusPage";
import ODPStatusLivePage from "./pages/ODPStatusLivePage";
import ODPValidationPage from "./pages/ODPValidationPage";
import ODPRealTimeLeadPage from "./pages/ODPRealTimeLeadPage";
import ODPObjectPage from "./pages/ODPObjectPage";
import { Home, ShoppingCart, GroupAdd, Loop, Cast, AccessibilityNew, EmojiPeople, EmojiObjectsOutlined, Settings, Traffic, CheckCircle, AddIcCall, Storage, Search, Bookmark, TrendingUp, Loyalty, Assessment, MonetizationOn, RateReview, Dashboard, ContactSupport, Call } from '@material-ui/icons';
import TheLoopFeedbackPage from "./pages/TheLoopFeedbackPage";
import MySearchPage from "./pages/MySearchPage";
import SalesDashboardPage from "./pages/SalesDashboardPage";
import TheLoopTrendsPage from "./pages/TheLoopTrendsPage";
import ProductHomePage from "./pages/ProductHomePage";
import TheLoopStorePage from "./pages/TheLoopStorePage";
import TheLoopStoreReviewPage from "./pages/TheLoopStoreReviewPage";
import FeedbackEntryPage from "./pages/FeedbackEntryPage";
import gtag from "ga-gtag";
import LoopFilterPage from "./pages/LoopFilterPage";
import TrainingAndFeatures from './pages/TrainingAndFeatures';
import VonageCallinfoPage from './pages/VonageCallinfoPage'

export function GApageView(title, path, username){
    gtag('event', 'page_view', {
      page_title : title,
      page_location: path, // The full URL is required.
      user_name: username
  });
}

// import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';

// var env = process.env.NODE_ENV || 'development';
// if (env === 'development')   {
//   console.log(appconfig)
// }

// Groups:
//   Admin - Automatically accesses all pages.  No need to add to allowed groups.
//   EnterpriseSystems
//   Finance
//   Loop_Admin
//   Loop_CustomerService_Mgr
//   Loop_CustomerService_Rep
//   Loop_Product
//   Loop_Test
//   Loop_ResearchAndInsights
//   Marketing
//   ODP

// For submenu permissions:
//
//  -- The main record must contain all possible groups with permissions in the underlying submenus.

const navLinks = [
  {
    to: '/',
    name: 'Home',
    icon: Home,
    component: HomePage
  },
  {
    to: '/product',
    name: 'Product',
    icon: ShoppingCart,
    component: ProductHomePage,
    allowedGroups: ["ODP", "EnterpriseSystems", "Finance", "Sales_Analytics", "Sales", "Sales_Ops", "Product"],
    subMenu: [
      {
        to: '/product/rdcproduct',
        name: 'RDC Product',
        icon: Loyalty,
        component: ProductDashboardPage,
        allowedGroups: ["ODP", "EnterpriseSystems", "Finance", "Sales_Analytics", "Sales", "Sales_Ops", "Product"],
      },
      {
        to: '/product/localexpert',
        name: 'Local Expert',
        icon: Assessment,
      },
      {
        to: '/product/cplusfulfillment',
        name: 'CPlus Fulfillment',
        icon: Dashboard,
      },
    ]
  },
  {
    to: '/sales',
    name: 'Sales',
    icon: MonetizationOn,
    component: SalesDashboardPage,
    allowedGroups: ["ODP", "EnterpriseSystems"]
  },
  {
    to: '/marketing',
    name: 'Marketing',
    icon: GroupAdd,
    component: MarketingPage,
    allowedGroups: ["ODP", "EnterpriseSystems", "Marketing"]
  },
  {
    to: '/theloop',
    name: 'The Loop',
    icon: Loop,
    component: TheLoopHomePage,
    allowedGroups: ["Default", "ODP", "EnterpriseSystems", "Loop_Admin", "Loop_CustomerService_Mgr", "Loop_CustomerService_Rep", "Loop_Product", "Loop_ResearchAndInsights"],
    subMenu: [
      {
        to: '/theloop/cs',
        name: 'Cust Service',
        icon: AccessibilityNew,
        component: TheLoopCustomerServicePage,
        allowedGroups: ["ODP", "EnterpriseSystems", "Loop_Admin", "Loop_CustomerService_Mgr", "Loop_Product", "Loop_CustomerService_Rep"],
      },
      {
        to: '/theloop/cs/mgr',
        name: 'Cust Service Mgr',
        icon: EmojiPeople,
        component: TheLoopCustomerServiceMgrPage,
        allowedGroups: ["ODP", "EnterpriseSystems", "Loop_Admin", "Loop_CustomerService_Mgr"],
      },
      {
        to: '/theloop/product',
        name: 'Product',
        icon: ShoppingCart,
        component: TheLoopProductPage,
        allowedGroups: ["ODP", "EnterpriseSystems", "Loop_Admin", "Loop_Product", "Loop_CustomerService_Mgr"],
      },
      {
        to: '/theloop/research',
        name: 'User Experience',
        icon: EmojiObjectsOutlined,
        component: TheLoopResearchAndInsightsPage,
        allowedGroups: ["ODP", "Loop_Admin", "Loop_ResearchAndInsights"],
      },
      // {
      //   to: '/theloop/live',
      //   name: 'Live Feed',
      //   icon: Cast,
      //   component: TheLoopFeedPage,
      //   allowedGroups: ["ODP", "Loop_Admin", "Loop_Test"],
      // },
      {
        to: '/theloop/search',
        name: 'Search',
        icon: Search,
        component: TheLoopSearchPage,
        allowedGroups: ["Default", "ODP", "Loop_Admin", "Loop_Test", "Loop_CustomerService_Mgr", "Loop_Product"],
      },
      {
        to: '/theloop/loopedin',
        name: 'Looped In',
        icon: Bookmark,
        component: TheLoopedInPage,
        allowedGroups: ["Default", "ODP", "Loop_Admin", "Loop_Test", "Loop_CustomerService_Mgr", "Loop_Product"],
      },
      {
        to: '/theloop/mysearch',
        name: 'My Searches',
        icon: Bookmark,
        component: MySearchPage,
        allowedGroups: ["Default", "ODP", "Loop_Admin", "Loop_Test", "Loop_CustomerService_Mgr", "Loop_Product"],
      },
      // {
      //   to: '/theloop/trends',
      //   name: 'Trending Searches',
      //   icon: TrendingUp,
      //   component: TheLoopTrendsPage,
      //   allowedGroups: ["Default", "ODP", "Loop_Admin", "Loop_Test", "Loop_CustomerService_Mgr", "Loop_Product"],
      // },
      {
        to: '/theloop/store',
        name: 'Loop Store',
        icon: RateReview,
        component: TheLoopStorePage,
        allowedGroups: ["ODP", "Loop_Admin", "Loop_Test", "Loop_CustomerService_Mgr", "Loop_Product"],
      },
      {
        to: '/theloop/feedbackentry',
        name: 'Feedback Entry',
        icon: RateReview,
        component: FeedbackEntryPage,
        allowedGroups: ["ODP", "Loop_Admin", "Loop_Test", "Loop_CustomerService_Mgr", "Loop_Product"],
      },
      {
        to: '/theloop/filters',
        name: 'Loop Filters',
        icon: RateReview,
        component: LoopFilterPage,
        allowedGroups: ["Admin","Loop_Admin"],
      },
      {
        to: '/theloop/training',
        name: 'Training & Features',
        icon: ContactSupport,
        component: TrainingAndFeatures,
        allowedGroups: ["Default", "ODP", "EnterpriseSystems", "Loop_Admin", "Loop_CustomerService_Mgr", "Loop_CustomerService_Rep", "Loop_Product", "Loop_ResearchAndInsights"],
      },
    ]
  },
  {
    to: '/odp',
    name: 'ODP',
    icon: Storage,
    component: ODPHomePage,
    allowedGroups: ["ODP"],
    subMenu: [
      {
        to: '/odp/livestatus',
        name: 'Live Feed',
        icon: Cast,
        component: ODPStatusLivePage,
        allowedGroups: ["ODP"],
      },
      {
        to: '/odp/status',
        name: 'Status',
        icon: Traffic,
        component: ODPStatusPage,
        allowedGroups: ["ODP"],
      },
      {
        to: '/odp/validation',
        name: 'Validation',
        icon: CheckCircle,
        component: ODPValidationPage,
        allowedGroups: ["ODP"],
      },
      {
        to: '/odp/leads',
        name: 'Real Time Leads',
        icon: AddIcCall,
        component: ODPRealTimeLeadPage,
        allowedGroups: ["ODP"],
      }
    ]
  },
  {
    to: '/admin',
    name: 'Admin',
    icon: Settings,
    component: AdminPage,
    allowedGroups: ["Admin"],
  },
  {
    to: '/vonagecallinfo',
    name: 'Vonage Call Info',
    icon: Call,
    component: VonageCallinfoPage,
    allowedGroups: ["Default", "ODP", "EnterpriseSystems", "Loop_Admin", "Loop_CustomerService_Mgr", "Loop_CustomerService_Rep", "Loop_Product", "Loop_ResearchAndInsights","Admin"],
  }

];

// Need to override amplify redirectSignIn/Out if there are multiple values since the list will be invalid.
// console.log(window.location.origin+'/');
awsmobile.oauth.redirectSignIn = window.location.origin+'/'
awsmobile.oauth.redirectSignOut = window.location.origin+'/'
// console.log('awsmobile:');
// console.log(awsmobile);
// console.log('appconfig:');
// console.log(appconfig)
Amplify.configure(awsmobile);

// console.log(window.location.href);
// console.log(appconfig.site_url);

// Auth set in awsconfig
// Amplify.configure({ 
//   Auth: {
//     identityPoolId: appconfig.identityPoolId,
//     region: appconfig.auth_region,
//     identityPoolRegion: appconfig.auth_region,
//     userPoolId: appconfig.auth_userPoolId,
//     userPoolWebClientId: appconfig.auth_userPoolWebClientId,
//     mandatorySignIn: true,
//     oauth: {
//       domain: appconfig.auth_domain,
//       scopes: [
//         'email',
//         'openid',
//       ],
//       redirectSignIn: window.location.href,
//       redirectSignOut: window.location.href,
//       responseType: 'code',
//     }
//   }
// })

// Analytics.configure({
//   autoSessionRecord: true,
//   AWSPinPoint: {
//     appId: awsmobile.aws_mobile_analytics_app_id,
//     region: awsmobile.aws_mobile_analytics_app_region,
//     mandatorySignIn: false
//   }
// })
// Analytics.enable();

// Analytics.record({ name: 'View' });

// Analytics: {
//   disabled: false,
//   autoSessionRecord: true,
//   AWSPinpoint: {
//     appId: awsconfig.aws_mobile_analytics_app_id,
//     region: awsconfig.aws_mobile_analytics_app_region
//   }
// }

export const AuthContext = React.createContext(); 
const initialState = {
  isAuthenticated: false,
  user: null,
  attribs: null,
  roles: null,
  touch: 0
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SIGNIN":
      Auth.federatedSignIn({provider: appconfig.auth_provider});
      Analytics.record({ name: 'SignIn' });
      return state;
    case "SIGNOUT":
      Auth.signOut();
      Analytics.record({ name: 'SignOut' });
      return state;
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        attribs: action.payload.attribs,
        roles: action.payload.roles
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        attribs: null,
        roles: null
      };
    default:
      return state;
  }
};

function CheckPermission(userRoles, allowedGroups) {
  var common = []; // Initialize array to contain common items
  
  for (var i = 0; i < userRoles.length; i++) {
    for (var j = 0; j < allowedGroups.length; j++) {
      if (userRoles[i] === allowedGroups[j]) { // If item is present in both arrays
        common.push(userRoles[i]); // Push to common array
      }
    }
  }
  return common.length? true : false; // Return the common items
}

const updateEndpoint= async (userId) => {
  await fetch("https://loopstore.entsys-dev.moveaws.com/pinpoint",{
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "app_id": "9f0df617372d4827b0b5ae4b13776791",
      "user_id": userId
  }), 
  })
  .then(response => response.json())
  .then(
      (result) => {
          console.log(result,"Endpoint Updated Successfully")
      },
      (error) => {
          console.log(error,"Failed to Update Endpoint")
      }
  )
}

function App() {

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const userRole = state && state.roles?state.roles:[];
  useEffect(() => {

    const updateUser = async () => {
      try {
        const user =  await Auth.currentAuthenticatedUser();
        const { id, attributes } = await Auth.currentUserInfo();
        updateEndpoint(id);
        var groups = user.signInUserSession.accessToken.payload["cognito:groups"] 
        dispatch({type: 'LOGIN', payload: {user: user, attribs: attributes, roles: groups}} );
      } catch {
        dispatch({type: 'LOGOUT', payload: {}} );
      }
    }
    Hub.listen('auth', updateUser);
    updateUser();
    Hub.remove('auth', updateUser);
  }, []);

  return (

    <AuthContext.Provider value={{ state, dispatch }}>
     
        <div className="App">

          <Header navLinks={navLinks}>

            {state.isAuthenticated ? (  //We can use !state.isAuthenticated for avoiding authentication for temporary usages
              <Switch>
                {navLinks.map((link, index) => (
                  userRole.includes("Admin") || (!link.allowedGroups) || (link.allowedGroups && CheckPermission(userRole, link.allowedGroups))?(
                    link.name === 'divider' ? (
                      null
                    ) : link.subMenu ?
                          link.subMenu.map((subMenu, subIndex) => (
                            userRole.includes("Admin") || (!subMenu.allowedGroups) || (subMenu.allowedGroups && CheckPermission(userRole, subMenu.allowedGroups))?(
                              <Route exact path={subMenu.to} component={subMenu.component} key={subIndex} />
                            ) : null
                          ))
                          : (
                            <Route exact path={link.to} component={link.component} key={index} />
                          )
                  ):null
                ))}
                {navLinks.map((link, index) => (
                  userRole.includes("Admin") || (!link.allowedGroups) || (link.allowedGroups && CheckPermission(userRole, link.allowedGroups))?(
                    link.subMenu && link.to && link.component?
                      <Route exact path={link.to} component={link.component} key={index} />
                    :null
                  ):null
                ))}
              <Route path="/odp/livestatus/:odpObject" component={ODPObjectPage} />
              <Route path="/theloop/search/:recordid" component={TheLoopRecordPage} />
              <Route path="/theloop/feedback/:recordid" component={TheLoopFeedbackPage} />
              <Route path="/theloop/review/:id" component={TheLoopStoreReviewPage} />
              <Route component={NotFoundPage} />
            </Switch>
            ) : (
              <GuestPage />
            )}

          </Header>

        </div>

    </AuthContext.Provider>
  );
}


export default App;
