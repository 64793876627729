import React, { useState, useEffect } from "react";
import 'date-fns';
// import moment from "moment";
import moment from 'moment-timezone';
import { ODP_STATUS_SETTINGS } from "../utils/constant";

const listStyle = {
    listStyleType: "none", 
    padding: "0",
    margin: "0" 
};

export function Clock()  {

    const [ currentTime, setCurrentTime ] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => setCurrentTime(new Date()), 1000);
        return () => {
          clearInterval(interval);
        };
      }, []);

    return (
        <>
            <ul style={listStyle}>
                <li>{moment(currentTime).tz('America/Los_Angeles').format(ODP_STATUS_SETTINGS.DATE_FORMAT_TZ)}</li>
                <li>{moment(currentTime).tz('UTC').format(ODP_STATUS_SETTINGS.DATE_FORMAT_TZ)}</li>
            </ul>
        </>
    )
    

}

export default Clock;