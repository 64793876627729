import React from "react";
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    sentiment: {
        textAlign: 'center'
    },
    chatCount: {
        textAlign: 'center'
    }
  }));

function StoreCard(props)  {
    
    const {cardDetails} = props;

    return (
        <>
            {cardDetails && cardDetails.id?(
                <>
                
                <ListItem alignItems="flex-start" className="list-item">
                         
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >
                            <Grid item xs={1}>

                                    <ButtonGroup
                                        orientation="vertical"
                                        color="primary"
                                        aria-label="vertical contained primary button group"
                                        variant="text"
                                        size="small"
                                    >
                                        <Link
                                            to={{
                                                pathname: `review/${cardDetails.id}`,
                                                state: { feedback: props.feedback }
                                            }}
                                        >
                                            <Button>
                                                <EditIcon />
                                            </Button>
                                        </Link>

                                    </ButtonGroup>

                            </Grid>

                            <Grid item xs={11}>

                                <ListItemText>
                                    <React.Fragment>
                                        <Typography>
                                            <b>Title: </b>{cardDetails.title}
                                        </Typography>
                                        <Typography>
                                            <b>Author: </b>{cardDetails.author}
                                        </Typography>
                                        <Typography>
                                            <b>Rating: </b>{cardDetails.rating}
                                        </Typography>
                                        <Typography>
                                            <b>Review: {cardDetails.review}</b>
                                        </Typography>
                                        <Typography>
                                            <b>Reply: </b>{cardDetails.reply}
                                        </Typography>
                                        <Typography>
                                            <b>Date: </b>{moment(cardDetails.date).format("YYYY-MM-DD")}
                                        </Typography>
                                        <Typography>
                                            <b>Source: </b> {cardDetails.source}
                                        </Typography>
                                        <Typography>
                                            <b>Version: </b>{cardDetails.version}
                                        </Typography>
                                        
                                    </React.Fragment>
                                </ListItemText>
                            </Grid>
                        </Grid>

                </ListItem>
                <Divider/>
                </>
            ):""}
        </>
    )

}

export default StoreCard;