import React, { useEffect, useState } from "react";
import { API, Analytics } from 'aws-amplify';
import * as queries from '../graphql/queries';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext, GApageView } from "../App";
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Logo from "../img/TheLoop.png";
import Search from "../img/Search.png";
import Feedback from "../img/Feedback.png";
import Trends from "../img/Trends.png";

const useStyles = makeStyles({
    centertext: {
        textAlign: 'center',
        fontFamily: 'Arial'
    },
    text: {
        fontFamily: 'Arial',
        textAlign: 'justify'
    },
    button: {
        color: '#FFFFFF',
        backgroundColor: '#d92228',
        '&:hover': {
            backgroundColor: '#a51d32',
          },
        fontFamily: 'Arial',
        fontWeight: 'bold'
    },
    button2: {
        color: 'blue',
        fontFamily: 'Arial',
        fontWeight: 'bold'
    }
  });

const defaultConfig = {
    'WelcomeMessage': ""
}

function HomePage()  {

    const { state } = React.useContext(AuthContext);
    const [config, setConfig] = useState(defaultConfig);
    const [initialized, setInitialized] = useState(0);
    const classes = useStyles();

    useEffect(() => {
        // console.log(state.user,"user attributes")
        // console.log(state.user.username,"username")
        GApageView('Loop Home Page', window.location.protocol + "//" + window.location.host + "/theloop", state.user.username)
        Analytics.autoTrack('session', {
            enable: true,
            attributes: {
                username: state.user.username
            },
            provider: 'AWSPinpoint'
        });
        Analytics.record({
            name: 'The Loop - visit',
            attributes: {
                username: state.user.username
            }
        });
        API.graphql({ query: queries.getEsDatahubPageConfig, variables: { id: 'LOOPHOME'} })
        .then((data) => {
            setConfig(JSON.parse(data.data.getESDatahubPageConfig.config));
            setInitialized(1);
        })
        .catch((error) => {
            console.log(error);
            setInitialized(1);
        })

    }, []);

    return (
        <>
            {initialized === 0 ? <CircularProgress /> : 
        
                <div className="HomePage">
                    
                    {config.WelcomeMessage ? 
                    
                        <div>
                            <Grid container  direction="column" justify="center" alignItems="center" className={classes.centertext}>

                                <Grid item xs={5}>
                                    <img src={Logo} alt="The Loop" width="350" height="70"/>
                                    <h1>Your one stop shop for all customer and consumer feedback</h1>
                                    <p>Use our simple tool to submit feedback, uncover consumer and customer sentiment, insights and trends in one central location</p>
                                </Grid>

                            </Grid>

                            <Grid container spacing={10} className={classes.centertext}>

                                <Grid item xs={12}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}>
                                    <Button component={Link} to={`/theloop/search`}>
                                        <img src={Search} alt="Search" width="50" height="50"/>
                                    </Button>
                                        <p>Search, Save & Subscribe to receive daily feedback email notifications</p>
                                </Grid>

                                <Grid item xs={1}></Grid>
                                <Grid item xs={2}>
                                    <Button component={Link} to={`/theloop/feedbackentry`}>
                                        <img src={Feedback} alt="Feedback" width="70" height="60"/>
                                    </Button>
                                    <p>Submit Feedback on behalf of your customers or yourself</p>
                                </Grid>
                                <Grid item xs={1}></Grid>

                                <Grid item xs={2}>
                                    <Button disabled>
                                        <img src={Trends} alt="Trends" width="50" height="50"/>
                                    </Button>
                                    <p>Download feedback & analyze customer insights & trends*</p>
                                    <h6>*Coming Soon</h6>
                                </Grid>
                                <Grid item xs={2}></Grid>
                            </Grid>

                            <Grid container>

                                <Grid item xs={4}></Grid>
                                <Grid item xs={4} className={classes.centertext}>
                                    <p>To learn more about The Loop features and how to use The Loop,<Button variant="text" className={classes.button2} component={Link} to={`/theloop/training`}> click here!</Button></p>
                                </Grid>

                            </Grid>
                      
                        </div>

                    : "Error ... can not retrieve WelcomeMessage.  Please contact dl-es-data-support@move.com."}

                </div>
                
            }
        </>
    )

}

export default HomePage;