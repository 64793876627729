import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded';
import ThumbsUpDownRoundedIcon from '@material-ui/icons/ThumbsUpDownRounded';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ODP_STATUS_SETTINGS } from "../utils/constant";
import { AuthContext, GApageView } from "../App";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link } from 'react-router-dom';
import moment from "moment-timezone";
import Tooltip from '@material-ui/core/Tooltip';
import SalesforceLogo from "../../src/img/salesforce-logo.png"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        fontFamily: 'Arial',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    form: {
        padding: theme.spacing(2),
    },
    formControl: {
        minWidth: '200px',
    }
  }));

const sentimentList = [
    'POSITIVE',
    'NEUTRAL',
    'NEGATIVE'
];

function TheLoopRecordPage(props)  {

    const classes = useStyles();
    let { recordid } = useParams();

    const { state } = React.useContext(AuthContext);
    const [initialized, setInitialized] = useState(0);
    const [record, setRecord] = useState({})
    const [newComment, setNewComment] = useState("");
    const [commentList, setCommentList] = useState([]);
    const [sentimentOverride, setSentimentOverride] = useState("");
    const [following, setFollowing] = useState(false);
    const allRecords = props.location.state.feedback || [];
    const criteria = props.location.state.criteria || [];
    const path = props.location.state.path || "";
    const searchCriteria = (path==0)? JSON.parse(criteria)||[] : [];
    const recordList = allRecords.map((list) => list.recordid);
    const [index, setIndex] = useState(recordList.indexOf(recordid));
    const [multipleCommentList, setMultipleCommentList] = useState([]);

    useEffect(() => {
        GApageView('Loop Feedback Page', '', state.user.username)
        Analytics.record({
            name: 'The Loop - Feedback Page visit',
            attributes: {
                username: state.user.username
            }
        });
        getdata();
        console.log(searchCriteria,"searchCriteria")
    }, []);

    useEffect(() => {
        getdata();
        setMultipleCommentList([])
    }, [index]);

    useEffect(() => {
        const subscription = API.graphql(
          graphqlOperation(subscriptions.onCreateTheLoopFeedbackComment)
        ).subscribe({
            next: data => {
                const { value: { data: { onCreateTheLoopFeedbackComment } }} = data
                const comments = [...commentList, onCreateTheLoopFeedbackComment]
                setCommentList(comments)
            }
        })
    
        return () => subscription.unsubscribe()
    }, [commentList])

    function capitalize(s) {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    }

    function truncate(str, n) {
        return (str.length > n) ? str.substr(0, n-1) + ' ...' : str;
    };

    const handleSentimentOverride = (event) => {
        setSentimentOverride(event.target.value);
        if (event.target.value !== record.Sentiment)  {
            setNewComment(`Sentiment overridden from ${record.Sentiment} to ${event.target.value}.`)
        }  else {
            setNewComment("");
        }
    };

    function getdata() {
        API.graphql({query: queries.getTheLoopFeedback, variables: { recordid: recordList[index] }})
        .then((data) => {
            // console.log(data);
            setRecord(data.data.getTheLoopFeedback);
            setCommentList(data.data.getTheLoopFeedback.comments.items);
            setSentimentOverride(data.data.getTheLoopFeedback.Sentiment);
            // setFollowing ...
            setInitialized(1);
        })
        .catch((error) => {
            console.log(error);
            setInitialized(1);
        })
        API.graphql({query: queries.getMultipleCaseComments, variables: { id: recordList[index] }})
        .then((data) => {
            // console.log(data.data.getMultipleCaseComments.case_comments);
            setMultipleCommentList(data.data.getMultipleCaseComments.case_comments);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handleSave = async(event) => {

        // If sentiment has been overridden, update the main record.
        // If originalSentiment is empty then update it; otherwise, do not.  
        // This allows us to keep the original value from the sentiment analyzer.

        if (newComment !== "")   {
            API.graphql(graphqlOperation(mutations.createTheLoopFeedbackComment, { input: { recordid: recordid, owner: state.user.username, content: newComment } }))
            .then((data) => {
                setNewComment("");
            })
            .catch((error) => {
                console.log(error);
            })

            // Mark thread as being followed.
            API.graphql(graphqlOperation(mutations.createUserFollows, { input: { recordid: recordid, owner: state.user.username, username: state.user.username, unfollow: false } }))
            .then((data) => {
                setFollowing(true);
            })
            .catch((error) => {
                console.log(error);
            })

        }
    }

    function prevRecord(){
        if (index > 0){
            setIndex(index => index - 1 );
        }
    }

    function nextRecord(){
        if (index < recordList.length - 1){
            setIndex(index => index + 1 );
        }
    }

    return (
        <>

            {initialized === 0 ? <CircularProgress /> : 

            <>
                <div className="HomePage">
                    <div className={classes.root}>
                        <Grid container>

                            <Grid item xs={1}>
                                { path==1 ?
                                    <Link
                                        to={{
                                            pathname: `/theloop/loopedin`,
                                        }}
                                    >
                                        <Button>
                                            <ArrowBackIcon/> 
                                        </Button>
                                    </Link>
                                    :
                                    <Link
                                        to={{
                                            pathname: `/theloop/search`,
                                            state: { savedSource: searchCriteria.origin ? searchCriteria.origin : "", savedAccountType: searchCriteria.account_type ? searchCriteria.account_type : "", savedSentiment: searchCriteria.Sentiment ? searchCriteria.Sentiment : "", savedArea: searchCriteria.area ? searchCriteria.area.toString() : "", savedSubArea: searchCriteria.sub_area ? searchCriteria.sub_area.toString() : "", savedBrowser: searchCriteria.browser ? searchCriteria.browser.toString() : "", savedOperatingSystem: searchCriteria.operating_system ? searchCriteria.operating_system.toString() : "", savedDescription: searchCriteria.description ? searchCriteria.description : "", savedRole: searchCriteria.role ? searchCriteria.role.toString() : "", savedLabel: searchCriteria.label ? searchCriteria.label.toString() : ""}
                                        }}
                                    >
                                        <Button>
                                            <ArrowBackIcon/> 
                                        </Button>
                                    </Link>
                                }
                            </Grid>

                            <Grid item xs={9}>
                                <h2 align="center">{record.subject}</h2>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography>
                                    Date: {moment(record.created_date).format("MM/DD/YY")}
                                </Typography>
                                <Typography align='left'>
                                    Time: {moment(record.created_date).tz('America/Los_Angeles').format("HH:mm:ss")}
                                </Typography>
                            </Grid>

                        </Grid>

                        <Grid container>

                            <Grid item xs={1}>
                                <Box style={{padding: 30}}>
                                    {
                                        record.Sentiment && record.Sentiment.toLowerCase() === 'positive'?(<ThumbUpRoundedIcon style={{ width: 40,height: 40 }} className={ODP_STATUS_SETTINGS.GOOD} />):
                                        record.Sentiment && record.Sentiment.toLowerCase() === 'negative'?(<ThumbDownRoundedIcon style={{ width: 40,height: 40 }} className={ODP_STATUS_SETTINGS.BAD} />):
                                        <ThumbsUpDownRoundedIcon style={{ width: 40,height: 40 }} className={ODP_STATUS_SETTINGS.NEUTRAL} />
                                    }
                                </Box>

                                <Box style={{padding: 20}}>
                                    <Tooltip title="Open case in salesforce">
                                        <Button onClick={()=>{ window.open(`https://moveinc--dev.lightning.force.com/lightning/r/Case/${record.recordid.substring(2)}/view`)}}>
                                            <img src={SalesforceLogo} alt="Logo" width="45" height="35"/>
                                        </Button>
                                    </Tooltip>
                                </Box>

                            </Grid>

                            <Grid item xs={5}>
                                <List dense={true}>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Acct Type:</b> {record.account_type}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Label:</b> {record.label}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Area:</b> {record.area}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Subarea:</b> {record.sub_area}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Source:</b> {/Email/.test(record.origin)?"Email":record.origin}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography>
                                                <b>Current URL:</b> {/https/.test(record.current_url) ? (/access_token/.test(record.current_url) ? truncate((record.current_url), 50) : truncate((record.current_url), 100)) : ""}
                                                {/https/.test(record.current_url) ? (/access_token/.test(record.current_url) ? <Button onClick={() => {navigator.clipboard.writeText(truncate((record.current_url), 50))}}>📋</Button> : <Button onClick={() => {navigator.clipboard.writeText(record.current_url)}}>📋</Button>) : ''}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography>
                                                <b>User Agent:</b> {truncate((record.user_agent), 100)}
                                                {record.user_agent?<Button onClick={() => {navigator.clipboard.writeText(record.user_agent)}}>📋</Button>:''}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Research Notes:</b> {record.research_notes}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography>
                                                <b>URL:</b> {window.location.protocol + "//" + window.location.host + "/theloop/feedback/" + record.recordid}
                                                <Button onClick={() => {navigator.clipboard.writeText(window.location.protocol + "//" + window.location.host + "/theloop/feedback/" + record.recordid)}}>📋</Button>
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Grid>
                    
                            <Grid item xs={5}>
                                <List dense={true}>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Name:</b> {record.account_name}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Role:</b> {record.role}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>PID:</b> {record.party_id}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Browser:</b> {record.browser}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Operating System:</b> {record.operating_system}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography> {record.jiracasenumber__c ? <td onClick={ ()=>{ window.open('https://moveinc.atlassian.net/browse/'+ record.jiracasenumber__c)}}><b>Jira #: </b> {"https://moveinc.atlassian.net/browse/" + record.jiracasenumber__c}</td> : <b>Jira #: </b>}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography><b>Rating:</b> {record.star_rating}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Grid>

                        </Grid>

                        <Grid container spacing={1}>

                            <Grid item xs={6}>
                                <Button onClick={prevRecord}>
                                    <ArrowBackIosIcon/>
                                </Button>
                            </Grid>

                            <Grid item xs={6} align='right'>
                                <Button onClick={nextRecord}>
                                    <ArrowForwardIosIcon/>
                                </Button>
                            </Grid>

                        </Grid>

                        <Grid container spacing={3}>

                            <Grid item xs={6}>
                                <h3>Feedback:</h3>
                                <Box border={1} style={{padding: 10, minHeight:200, maxHeight: 200, overflow: 'auto'}}>{record.description}</Box>
                            </Grid>

                            <Grid item xs={6}>
                                <h3>Internal Comments:</h3>
                                <Box border={1} style={{padding: 10, minHeight:200, maxHeight: 200, overflow: 'auto'}}> 
                                    <List className={`comment-list`}>
                                        {record.case_comments}
                                        {
                                        commentList.map(comment=>{
                                            return <ListItem key={comment.id}><ListItemText>{moment(comment.createdAt).tz('America/Los_Angeles').format(ODP_STATUS_SETTINGS.DATE_FORMAT)}: {comment.owner} - {comment.content}</ListItemText></ListItem>
                                        })
                                        }
                                        {
                                        multipleCommentList.map(comment=>{
                                            return <ListItem>{comment}</ListItem>
                                        })
                                        }
                                    </List>
                                </Box>
                            </Grid>

                        </Grid>
                    </div>
      
                    <div className={classes.form}>

                        <div className={classes.form}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="sentiment-native-simple">IMPLEMENT! Sentiment Override and TAGS</InputLabel>
                                <Select
                                    native
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    value={sentimentOverride}
                                    onChange={handleSentimentOverride}
                                    inputProps={{
                                        name: 'sentiment',
                                        id: 'sentiment-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {sentimentList.map((option) => (
                                        <option key={option} value={option}>
                                            {capitalize(option)}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.form}>
                            <TextField 
                                id="new_comment" 
                                label="Comment" 
                                multiline
                                rows={4}
                                fullWidth
                                variant="outlined" 
                                value={newComment} 
                                onChange={(event) => setNewComment(event.target.value)}
                            />
                        </div>

                        <Button variant="contained" color="primary" onClick={() => handleSave()}>Save</Button>
                    </div>

                </div>

            </>
            }
        </>
    )

}

export default TheLoopRecordPage;